import SvgCrossIcon from '../../../svgs/common/CrossIcon';
import { IBaseOverlayProps } from './baseOverlay';

export function OverlayVariableHeight(props: IBaseOverlayProps) {
    function handleBackgroundClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        if (props.onBackgroundClicked) {
            props.onBackgroundClicked();
        }
    }
    
    function handleCrossIconClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        if (props.onCrossIconClicked) {
            props.onCrossIconClicked();
        }
    }
    return (
        // <div className={`bg-red-800 blur-lg min-h-[50vh] min-w-[70vw] mx-auto my-auto fixed top-1/2 left-1/2 ${props.show ? '' : 'hidden'}`}>
        //     {props.children}
        // </div>
        <div onTransitionEnd={(e) => e.currentTarget.classList.contains('opacity-0') ? e.currentTarget.classList.add('scale-0') : null} onClick={handleBackgroundClicked || handleCrossIconClicked} onScroll={(e) => e.stopPropagation()} className={`transition-opacity duration-300 fixed h-full w-full flex flex-col z-10 py-10 inset-0 bg-black bg-opacity-50 backdrop-blur-sm items-center justify-center ${props.show ? 'opacity-100': 'opacity-0 pointer-events-none'}`}>
            <div onClick={(e) => e.stopPropagation()} className={`duration-200 overflow-y-auto justify-center flex-col  p-2 rounded-xl md:min-w-[50vw] w-5/6 md:w-[50vw] ${props.bgClassNames || ''} ${props.show ? 'scale-100': 'scale-0 pointer-events-none'}`}>
                <div className="items-center my-auto mx-auto h-full py-4 w-full">
                    <div className='w-full flex justify-end pr-2'>
                        <div onClick={handleCrossIconClicked || handleBackgroundClicked} className="hover:shadow-lg cursor-pointer duration-200 w-8 h-8 rounded-full bg-gray-300 shadow-md flex-col right-0 justify-end self-end flex">
                            <SvgCrossIcon className="stroke-gray-500 my-auto mx-auto stroke-[2] w-3 h-3" />
                        </div>
                    </div>
                    {props.children}
                    {/* <h1 className="mt-6 font-semibold text-center text-xl text-gray-700">
                    Welcome back
                    </h1>
                    <p className="text-center text-gray-700 mb-5">Sign in</p>

                    <div className="flex flex-col">
                    <input
                        type="text"
                        className="border border-gray-700 p-2 rounded mb-5"
                        placeholder="email@example.com"
                    />
                    <input
                        type="text"
                        className="border border-gray-700 p-2 rounded mb-5"
                        placeholder="********"
                    />
                    </div>
                    <div className="text-center">
                        <button className="px-5 py-2 bg-gray-700 text-white rounded">
                            Sign in
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


