import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

export interface IFaqCellProps {
    question: string;
    answer: string;
}

export default function FaqsCell(props: IFaqCellProps) {
    const [isAnswerHidden, setIsAnswerHidden] = useState(true);

    return (
        <div className="dark:bg-gray-900 py-2 my-auto">
            <div onClick={() => { setIsAnswerHidden(!isAnswerHidden) }} className="hover:cursor-pointer overflow-hidden grid grid-cols-12 bg-gray-200 w-full py-4 pl-8 pr-4 rounded-md text-themedark font-medium text-md">
                <div className="text-md my-auto col-span-11">
                    {props.question}
                </div>
                <button onClick={() => { setIsAnswerHidden(!isAnswerHidden) }} className="flex-row-reverse col-span-1 my-auto ml-auto py-2 px-2 lg:w-3/4">
                    <FaChevronDown className={`text-right my-auto float-right justify-end duration-300 ${isAnswerHidden ? '' : 'transform rotate-180'}`} />
                </button>
            </div>
            <div className={`transition-all duration-500 bg-gray-50 w-full py-4 px-8 rounded-b-md text-themedark text-sm ${isAnswerHidden ? 'opacity-0 fixed' : ''}`}>
                {props.answer}
            </div>
        </div>
    );
}
