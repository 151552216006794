import SingleSelect from "../../../components/common/single_select_dropdown";
import Datepicker from 'react-datepicker';
import { IMentorAvailability, IMentorProfile } from "../types";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { FaClock, FaCalendar } from 'react-icons/fa';
import MultiSelect from "../../../components/common/multiselect_textfield";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { defaultErrorToast } from "../../../components/common/toasts";
import customHttpClient from "../../../utils/httpClient/httpClient";
import LoadingScreen from "../../../components/common/loading/LoadingScreen";
import { ISkillsListResult } from "../../../stores/types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { getSkillsList } from "../../../stores/reducers/commonDataSlice";
import { AppDispatch, RootState } from "../../../stores";

export interface IScheduleWithMentorFormProps {
    mentorInfo: IMentorProfile;
    shouldStartLoading?: () => void;
    shouldStopLoading?: () => void;
    onSuccess: () => void;
}

interface ISendRequestData {
    from: string | Date;
    skills: string[];
    hours: number;
    description: string;
}

export function ScheduleWithMentorForm(props: IScheduleWithMentorFormProps) {
    const mentorInfo = props.mentorInfo;


    const dispatch = useDispatch<AppDispatch>();

    const {skills: skillsPool, loading: skillsLoading, error: skillsError} = useSelector((state: RootState) => state.common);

    const [isLoading, setIsLoading] = useState(false);
    const [dateAvailability, setDateAvailability] = useState({} as IMentorAvailability);
    const [timeAvailability, setTimeAvailability] = useState({} as IMentorAvailability);

    const [selectedDate, setSelectedDate] = useState(null as Moment | null);
    const [selectedTime, setSelectedTime] = useState(null as string | null);
    
    const [selectedSkills, setSelectedSkills] = useState([] as string[]);
    const [sessionHours, setSessionHours] = useState(1);
    const [description, setDescription] = useState("");
    
    const [isTimepickerOpen, setIsTimepickerOpen] = useState(false);
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);

    // const [skillsPool, setSkillsPool] = useState([] as Array<string>);


    async function onScheduleButtonClicked() {
        const splittedSelectedTime = selectedTime?.split(":");
        if (!selectedDate || !splittedSelectedTime || splittedSelectedTime.length != 2) {
            defaultErrorToast("Please select a valid date and time");
            return;
        }
        const [hours, minutes] = [Number(splittedSelectedTime[0]), Number(splittedSelectedTime[1])];
        if (hours === undefined || minutes === undefined || hours > 23 || hours < 0 || minutes < 0 || minutes > 59) {
            defaultErrorToast("Please select a valid and time");
            return;
        }
        const data = {
            from: selectedDate?.hours(hours).minutes(minutes).format('YYYY-MM-DD HH:mm'), 
            skills: selectedSkills,
            hours: sessionHours,
            description,
        }

        const validationSchema = yup.object({
            from: yup.date().min(moment.utc().add(1, 'day').toDate()).max(moment.utc().add(14, 'days').toDate()).required(), // .transform(dateToStringConverter('YYYY-MM-DD HH:mm'))
            description: yup.string().trim().min(2).required(),
            skills: yup.array(yup.string().required()).required(),
            hours: yup.number().min(1).max(2).required(),
        });

        let castedData: ISendRequestData;
        try {
            castedData = await validationSchema.validate(data);
        }
        catch (error: any) {
            defaultErrorToast(`Error validating input data - ${error.message}`);
            return;
        }

        castedData['from'] = moment.utc(castedData['from']).format('YYYY-MM-DD[T]HH:mm:ssZ');

        setIsLoading(true);

        try {
            await customHttpClient.post(`/mentors/${mentorInfo.id}/requests`, undefined, castedData);
            setSelectedDate(null);
            setSelectedTime(null);
            setDescription("");
            setSessionHours(1);
            setSelectedSkills([]);
            props.onSuccess();
        }
        catch (error: any) {
            defaultErrorToast(error?.data?.message || "Unknown error occured");
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        dispatch(getSkillsList());
        // get skills pool
        // setSkillsPool([...new Set(['abc', 'bcd', 'cef', 'def', 'e', 'f', 'g', 'h', 'i', 'j'])]);
        // setSkillsPool(Array.from(Array(20)).map((it, ind) => { return uuidv4().slice(0, 10) }));
    }, []);

    useEffect(() => {
        setIsLoading(true);

        customHttpClient.get<IMentorAvailability>(`/mentors/${mentorInfo.id}/availability`, undefined, {
            params: {
                fromDate: moment.utc().add(1, 'day').format("YYYY-MM-DD"),
                toDate: moment.utc().add(14, 'days').format("YYYY-MM-DD"),
                type: 'dates',
                hours: 1,
            }
        })
        .then(result => {
            setDateAvailability(result.result);
        }).catch(error => {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        // alert("HERE 1");
        if (!selectedDate) return () => {};
        // alert("HERE 2");
        setIsLoading(true);

        customHttpClient.get<IMentorAvailability>(`/mentors/${mentorInfo.id}/availability`, undefined, {
            params: {
                fromDate: selectedDate.format("YYYY-MM-DD"),
                toDate: selectedDate.format("YYYY-MM-DD"),
                type: 'times',
                hours: 1,
            }
        })
        .then(result => {
            // alert("TIME AV " + JSON.stringify(result.result, null, 2))
            setTimeAvailability(result.result);
        }).catch(error => {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [selectedDate]);

    return (
        <div className="text-black mt-5 py-0 pb-2">
            {
                (isLoading || skillsLoading) && (
                    <div className="[&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen />
                    </div>
                )
            }

            <p className="w-full text-center text-lg font-poppins">
                Schedule with <span className="font-bold">{mentorInfo?.user.fullname}</span>
            </p>
            
            
            <div className="mt-6">
                <div className="w-full px-8 text-center">
                    Select your preferred date and time
                </div>
                <div className="mt-3 ml-6 grid grid-cols-2 mx-auto justify-center gap-x-4">
                    {/* <div className="[&>div]:fill-gray-400 w-3/4 [&_input]:w-full [&_.react-datepicker\_\_input-container]:bg-white [&_.react-datepicker\_\_input-container]:rounded-md [&_input]:ml-4 [&_input]:border-transparent [&_input]:focus:border-transparent [&_input]:focus:ring-0 [&_input]:outline-none"> */}
                    <div className="[&>div]:fill-gray-400 w-3/4 [&_input]:w-full [&_input]:text-center [&_input]:px-4">
                        {/* <DatePicker 
                            date
                            className={"fixed"}
                            minDate={moment.utc().toDate()}
                            maxDate={moment.utc().add(14, 'days').toDate()}
                            onChange={(e) => alert(e)}
                        /> */}
                        <Datepicker 
                            className="bg-white rounded-md"
                            showIcon
                            icon={<FaCalendar className="fill-gray-400 mt-[1px]" />}
                            // showFullMonthYearPicker
                            // onChange={(d) => {}}  
                            // showIcon
                            startDate={moment.utc().subtract(12, 'days').toDate()}
                            // showTimeSelect
                            timeIntervals={15}
                            scrollableYearDropdown
                            showYearDropdown
                            showMonthDropdown
                            includeDates={dateAvailability?.dates?.map((it) => moment.utc(it).toDate())}
                            minDate={moment.utc().toDate()}
                            maxDate={moment.utc().add(14, 'days').toDate()}
                            // excludeDates={[moment.utc().add(2, 'days').toDate()]}
                            // excludeTimes={[moment.utc().add(3, 'days').hours(10).minutes(15).seconds(0).toDate()]}
                            // excludeDateIntervals={[
                            //     {
                            //         start: moment.utc().add(3, 'days').hours(10).minutes(15).seconds(0).toDate(),
                            //         end: moment.utc().add(3, 'days').hours(11).minutes(15).seconds(0).toDate()
                            //     }
                            // ]}
                            selected={null}
                            open={isDatepickerOpen}
                            dateFormat={'dd/MM/yyyy'}
                            onClickOutside={() => setIsDatepickerOpen(false)}
                            onFocus={() => setIsDatepickerOpen(true)}
                            value={selectedDate?.format('DD MMM, yyyy')}
                            onChange={(date) => { setSelectedDate(moment(date)); setIsDatepickerOpen(false); }}
                        />
                        {/* <DatetimePicker isValidDate={(c) => c  > moment.utc().add(24, 'hours').toDate()}  /> */}
                    </div>
                    

                    <div className="w-3/4 [&_input]:w-full [&_input]:text-center">
                        <Datepicker 
                                // portalId="root-portal"
                                // popperClassName="date-picker-reports"
                                // popper
                                // popperPlacement="top-end"
                                className="bg-white rounded-md disabled:bg-sky-50"
                                showIcon
                                icon={<FaClock className="fill-gray-400 mt-[1.5px]" />}
                                showTimeInput
                                showTimeSelectOnly={true}
                                showTimeSelect
                                timeIntervals={30}
                                // selected={selectedTime || new Date()}
                                value={selectedTime || undefined}
                                timeFormat="HH:mm"
                                open={isTimepickerOpen}
                                disabled={!selectedDate}
                                includeTimes={timeAvailability?.dates?.map((it) => moment.utc(it).toDate())}
                                onClickOutside={() => setIsTimepickerOpen(false)}
                                onFocus={() => setIsTimepickerOpen(true)}
                                onChange={(time) => { setSelectedTime(moment(time).format("HH:mm")); setIsTimepickerOpen(false); }}
                            />
                    </div>
                </div>
            </div>


            {/* <div className="mt-6 text-left justify-start px-6 w-fit"> */}
            <div className="mt-6 text-left px-6 justify-center w-fit">
                <div className="w-full">
                    <div className="">
                        For how long?
                    </div>
                    <div className="mt-2 text-lg font-medium">
                        <SingleSelect
                            classNames={"w-full px-3 pr-10 justify-start text-lg font-medium text-sm"}
                            options={Array.from(Array(1)).map((it, ind) => ({ id: `${ind+1}`, value: `${ind+1} ${ind == 0 ? 'hour' : 'hours'}` }))}
                            onChange={(val) => { setSessionHours(Number(val)) }}
                            initialValue={`${sessionHours}`}
                            placeholder="Session hours"
                        />
                    </div>
                </div>
                {/* <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
                <select id="countries" style={{WebkitAppearance: 'none',}} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected></option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                </select> */}
            </div>

            <div className="mt-3 px-6 py-8">
                <div className="[&_.search-wrapper]:border-none [&_.optionContainer]:border-none">
                    <MultiSelect 
                        classNames="py-1 rounded-md bg-white text-black border-none"
                        selectionLimit={20} 
                        showSelectionLimit={20}
                        selectedItems={selectedSkills}
                        placeholder="Skills in this experience" 
                        onChange={(items) => { setSelectedSkills(items) }} 
                        options={skillsPool.map((it) => it.name)}/>
                </div>
            </div>

            <div className="px-6 mt-4">
                <textarea 
                    className="w-full rounded-md px-2 py-2 font-light text-sm" 
                    placeholder="Describe what you want to discuss in this session, in paragraphs or bullet points"
                    rows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>

            <div className="mt-8 mb-6 w-full flex justify-center">
                <button disabled={!dateAvailability.isAvailable} onClick={onScheduleButtonClicked} className="bg-theme text-white py-2 rounded-lg w-1/2 md:w-1/3 shadow-lg hover:shadow-none transition-all duration-200">
                    Schedule {dateAvailability.isAvailable !== undefined && !dateAvailability.isAvailable ? ' - Unavailable' : ''}
                </button>
            </div>

        </div>
    )
}

