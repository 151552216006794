import { Footer } from '../../components/common/footer';
import SvgListCheckmarkIcon from '../../svgs/common/ListCheckmarkIcon';
import LandingHeader from '../landing/Header';


export default function AboutUs() {
    return (
        <div className='dark:bg-gray-900 bg-gray-100 min-h-screen'>
            <div className='absolute py-4 px-4 lg:py-10 lg:px-10 z-50 w-full'>
                <LandingHeader selectedSection='about us' />
            </div>

            {/*  -------------- TOP SECTION ----------------  */}
            <div className='flex-row toplanding'>
                <div>
                    <div className="w-full h-full flex mx-auto relative">
                        <img
                            className=" w-full h-full justify-center"
                            src="aboutus/TopBgImage.png"
                            alt="landing top"
                        />
                        <div className='bg-black opacity-80 w-full h-full absolute'></div>
                        
                        {/* <div className='absolute top-1/3 left-1/3 transform -translate-x-1/3 -translate-y-1/3 mx-auto'> */}
                        <div className='text-white absolute w-1/2 text-center top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                            <p className='break-words text-3xl md:text-5xl text-over font-semibold px-4'>About <span className='text-theme'>Us</span></p>
                            <p className='mt-1 md:mt-4 text-xs md:text-base'>Discover the Power of Mentorship and Transform Your Journey</p>
                        </div>
                    </div>

                </div>
            </div>

            {/*  -------------- SECOND SECTION ----------------  */}
            <div className='grid grid-cols-1 md:grid-cols-2 py-20 space-x-8'>
                <div className='md:pl-16 mx-auto md:mx-0 md:float-right flex-col my-auto col-span-1'>
                    <img 
                        className='max-h-[16rem] float-right aspect-square'
                        src='aboutus/MiddleCharacterImage.png'
                        alt=''
                    />
                </div>

                <div className='col-span-1 pr-12 md:mt-0 mt-8'>
                    <div className="text-orange-600 font-bold text-sm">
                        <span className="font-extrabold mr-3">&#11834;</span> About Us
                    </div>
                    <p className='font-bold text-3xl text-themedark lg:w-3/4'>We’re Ready To Grow Your <span className='text-3xl font-light'>Profession With Us</span></p>
                    <div className='mt-4 space-y-4 text-md font-light text-theme'>
                        <p>At <span className='font-bold'>Ment</span>, we are passionate about empowering individuals to reach their full potential. We believe that mentorship is a powerful tool for personal and professional growth. </p>
                        <p>We strive to create a nurturing and inclusive community where individuals can thrive, learn, and achieve their goals. Join us on this transformative journey and embark on a path of growth, learning, and success. </p>
                    </div>
                    <button onClick={() => window.location.href = '/dashboard'} className='mt-4 border-2 border-theme text-theme py-2 px-8 text-sm rounded-md font-medium hover:bg-themedark hover:text-white'>Get Started</button>
                </div>
            </div>

            {/*  -------------- THIRD SECTION ----------------  */}
            <div className='bg-sky-100 py-20'>
                <div className="mx-auto justify-center text-center text-orange-600 font-bold text-sm">
                    <span className="font-extrabold mr-3">&#11834;</span> Top Features <span className="font-extrabold mr-3">&#11834;</span>
                </div>

                <div className="mt-4 w-2/3 lg:w-1/3 mx-auto justify-center text-center text-themedark font-bold text-3xl">
                    <p>We provide you with most exclusive services</p>
                </div>
                
                <div className="flex gap-y-3 gap-x-3 mx-auto mt-6 py-2 px-14 flex-wrap justify-center items-center">
                    <div className='w-44 text-center bg-gray-100 rounded-sm shadow-md px-4 py-6 space-y-2'>
                        <img 
                            className='max-w-[4rem] mx-auto aspect-square'
                            src='aboutus/PersonalizedMentorIcon.png'
                        />
                        <p className='text-themedark text-sm font-bold'>Personalized Mentor</p>
                    </div>
                    <div className='w-44 text-center bg-gray-100 rounded-sm shadow-md px-4 py-6 space-y-2'>
                        <img 
                            className='max-w-[4rem] mx-auto aspect-square'
                            src='aboutus/ProgressTrackingIcon.png'
                        />
                        <p className='text-themedark text-sm font-bold'>Progress Tracking</p>
                    </div>
                    <div className='w-44 text-center bg-gray-100 rounded-sm shadow-md px-4 py-6 space-y-2'>
                        <img 
                            className='max-w-[4rem] mx-auto aspect-square'
                            src='aboutus/ExpertGuidanceIcon.png'
                        />
                        <p className='text-themedark text-sm font-bold'>Expert Guidance</p>
                    </div>
                </div>
            </div>



            {/*  -------------- FOURTH SECTION ----------------  */}
            <div className='py-14'>
                <div className="mx-auto text-themedark font-bold text-center w-fit text-xl">
                    Company History
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mt-10 space-x-8'>
                    <div className='md:pl-16 md:pr-12 mx-auto md:mx-0 md:float-right flex-col my-auto col-span-1'>
                        <img 
                            className='max-h-[16rem] float-right aspect-square'
                            src='aboutus/CompanyHistoryImage.png'
                        />
                    </div>

                    <div className='col-span-1 pr-12 md:w-3/4 md:mt-0 mt-8'>
                        <p className='font-bold text-3xl text-themedark lg:w-3/4'>Our Journey as a Startup Empowering <span className='text-3xl font-light text-orange-600'>Success</span></p>
                        <div className='mt-4 space-y-4 text-sm font-light text-theme'>
                            <p>Our startup was born out of a shared vision to make a meaningful impact on individuals' lives by providing mentorship and guidance. </p>
                        </div>
                        <div className='block space-y-2 mt-6'>
                            { 
                                [
                                    'From building our team to developing our platform, every step was a learning experience.',
                                    'We constantly refine our platform, incorporating user feedback and industry best practices.',
                                    'While we may be a startup, we take immense pride in the success stories we have witnessed.',
                                ].map((it, val) => {
                                    return (
                                        <div className='flex text-theme gap-x-3'>
                                            {/* <img 
                                                className='h-6 my-auto aspect-square'
                                                src='aboutus/ListCheckmarkIcon.png'
                                                alt='checklist'
                                            /> */}
                                            <SvgListCheckmarkIcon
                                                className='h-8 my-auto aspect-square'
                                             />
                                            {it}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            
            {/*  -------------- FOURTH SECTION ----------------  */}
            <div className='py-14 bg-sky-100'>
                <div className="mx-auto text-themedark font-bold text-center w-fit text-xl">
                    Mission
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mt-10 space-x-8'>
                    <div className='md:pl-16 md:pr-12 mx-auto md:mx-0 md:float-right flex-col my-auto col-span-1'>
                        <img 
                            className='max-h-[16rem] float-right aspect-square'
                            src='aboutus/CompanyHistoryImage.png'
                        />
                    </div>

                    <div className='col-span-1 md:w-3/4 md:mt-0 mt-8'>
                        <p className='font-bold text-3xl text-themedark lg:w-3/4'>Accelerate your <span className='text-orange-600'>professional</span> career</p>
                        <div className='space-y-4 mt-6'>
                            { 
                                [
                                    'Accelerate your career by providing access to specialized mentors in the field you are interested in.',
                                    'Give you insights about previous experiences from our professional mentors.',
                                    'Give the opportunity for experts in any field to step in and mentor juniors.',
                                ].map((it, val) => {
                                    return (
                                        <div className='flex text-theme w-full space-x-2 -ml-2'>
                                            <div className='flex flex-col my-auto'>
                                                <SvgListCheckmarkIcon
                                                    className='h-8 my-auto'
                                                />
                                            </div>
                                            {/* <img
                                                className='w-16 bg-green-100 my-auto aspect-square'
                                             /> */}
                                             <p>
                                                {it}
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
