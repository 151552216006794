import { Navigate } from "react-router-dom";

export function FailureOAuthCallbackPage() {
    // const [shouldNavigateToLogin, setShouldNavigateToLogin] = useState(false);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShouldNavigateToLogin(true);
    //     }, 3000);
    // }, []);


    return (
        <Navigate to={"/login"} />
    );
    // if (sharedCookies.get(localStorageKeys.accessToken)) {
    //     return (
    //         <Navigate to={"/"} />
    //     )
    // }
    // else if (shouldNavigateToLogin) {
    //     return (
    //         <Navigate to={"/login"} />
    //     )
    // }
    // else {
    //     return (
    //         <div
    //             className={`flex items-center justify-center min-h-screen bg-white dark:bg-black`}
    //             >
    //             <div className="flex flex-col items-center">
    //                 <img src={'../../../public/failedIcon.svg'} alt="Failed" className="mb-4" />
    //                 <h2 className={`text-2xl font-bold text-center text-black dark:text-bg-white`}>
    //                     Authentication Failed
    //                 </h2>
    //             </div>
    //         </div>
    //     )
    // }
}


