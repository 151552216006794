import { useFormik } from "formik"
import moment, { Moment } from "moment"
import { useEffect, useState } from "react";
import * as yup from 'yup';
import { defaultErrorToast, defaultSuccessToast } from "../../../components/common/toasts";
import { InvisibleAnimation } from "../../../components/common/invisibleDisplayAnimation/InvisibleAnimation";
import SvgPlusIcon from "../../../svgs/common/PlusIcon";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useInputState from "../../../hooks/useInputState";
import DatePicker from "tailwind-datepicker-react";
import { OverlayVariableHeight } from "../../../components/common/overlays/overlayVariableHeight";
import { ExperienceCell, IExperience } from "./experiencesCell";
import MultiSelect from "../../../components/common/multiselect_textfield";
import customHttpClient from "../../../utils/httpClient/httpClient";
import LoadingScreen from "../../../components/common/loading/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../stores";
import { getSkillsList } from "../../../stores/reducers/commonDataSlice";
// import './index.css';

interface IMentorAttachment {
    name: string;
    url: string;
    type: string;
    description?: string;
    claimDate?: Date;
    expiryDate?: Date;
}

interface IMentorSkill {
    name: string;
    yearsExperience: number;
}

interface IMentorExperience {
    title: string;
    description: string;
    fromDate: Date;
    toDate: Date;
    skills: string[];
    attachments: Array<{
        name: string;
        url: string;
        description?: string;
    }>
}

interface IMentorReferralLink {
    url: string;
    platform: string;
    description: string;
}

interface IMentorWorkTiming {
    day: number;
    fromTime: string;
    toTime: string;
}

export interface IApplyAsMentorFormProps {
    onSuccess: () => void;
    // onLoadingChange: (isLoading: boolean) => void;
}

// TODO: Add headline to schema
// TODO: Add skill level to mentor skill (e.g: beginner, strong, expert)

export function ApplyAsMentorForm(props: IApplyAsMentorFormProps) {

    const {skills: skillsPool, loading: skillsLoading, error: skillsError} = useSelector((state: RootState) => state.common);
    // const AddExperienceSwal = withReactContent(Swal);
    const NUM_STEPS = 3;
    const [currentStep, setCurrentStep] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const [isAddExperienceShown, setIsAddExperienceShown] = useState(false);
    const [isStartDatepickerVisible, setIsStartDatepickerVisible] = useState(false);
    const [isEndDatepickerVisible, setIsEndDatepickerVisible] = useState(false);
    // const [currentExperience, setCurrentExperience, handleSetCurrentExperience] = useInputState({} as IExperience);
    const [currentExperience, setCurrentExperience] = useState({} as IExperience);
    const [allExperiences, setAllExperiences] = useState([] as Array<IExperience>);
    // const [skillsPool, setSkillsPool] = useState([] as Array<string>);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        
        // get skills pool
        // setSkillsPool([...new Set(['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'])]);
        dispatch(getSkillsList());
    }, []);

    function handleSetCurrentExperience(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setCurrentExperience((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })
    }

    function onSkillsChange(skills: string[]) {
        setCurrentExperience((prev) => {
            return {
                ...prev,
                skills,
            }
        });
    }

    async function addExperience(experience: IExperience) {
        const validationSchema = yup.object({
            title: yup.string().trim().min(2).required(),
            company: yup.string().trim().min(2).required(),
            description: yup.string().trim().min(5).required(),
            fromDate: yup.date().max(moment.utc().toDate()).label('Start date').required(),
            toDate: yup.date().max(moment.utc().toDate()).min(yup.ref('fromDate')).label('End date').optional(),
            skills: yup.array(yup.string().required()).optional(),
        });

        let castedExperience: IExperience;

        try {
            // const isValid = await validationSchema.isValid(experience);
            castedExperience = await validationSchema.validate(experience)
    
            // if (!isValid) {
            //     defaultErrorToast('Please enter all the information');
            //     return;
            // }
        }
        catch (error: any) {
            defaultErrorToast(`Error validating input data - ${error.message}`);
            return;
        }

        setAllExperiences((prev) => {
            return [...prev, experience];
        });

        clearCurrentExperience();

        setIsAddExperienceShown(false);
    }

    function clearCurrentExperience() {
        setCurrentExperience({} as IExperience);
    }

    function deleteExperience(ind: number) {
        setAllExperiences((prev) => {
            return prev.filter((val, ind) => ind !== ind);
        });
    }

    // const showAddExperiencePopup = () => {
    //     AddExperienceSwal.fire({
    //     //   title: <i>Input something</i>,
    //     //   input: 'text',
    //     //   inputValue,
    //     // backdrop: '#000',
    //     background: 'rgb(224, 242, 254)',
    //     // showCloseButton: true,
    //     // showCancelButton: true,
    //     showCloseButton: false,
    //     showCancelButton: false,
    //     showConfirmButton: false,
    //     confirmButtonText: 'Add',
    //     html: (
    //         AddExperienceForm
    //     ),
    //     //   preConfirm: () => {
    //     //     // setInputValue(Swal.getInput()?.value || '')
    //     //   },
    //     })
    // }

    function handleSubmit() {

    }

    // const [isShown, setIsShown] = useState(true);
    // <div onTransitionEnd={(e) => console.log("TRNSITION ENDED ", e.currentTarget.classList.contains('opacity-0'), Array.from(e.currentTarget.classList.values()))} onAnimationEnd={(e) => e.currentTarget.attributes} className={`duration-1000 h-32 w-32 bg-red-500 ${isShown ? 'opacity-100' : 'opacity-0'}`}>
    //     A
    // </div>

    async function handleNext() {
        // setIsShown(!isShown);
        // return;
        const isLastStep = currentStep === NUM_STEPS;
        if (isLastStep) {
            // TODO: Send API Call
            const data = {
                "title": formik.values.title,
                "currentCompany": formik.values.currentCompany,
                "pitch": formik.values.pitch,
                "yearsExperience": formik.values.yearsExperience,
                "hourlyPrice": formik.values.hourlyPrice,
                // "skills": formik.values.skills,
                "experiences": allExperiences,
                "workTimings": formik.values.workTimings,
            }

            // props.onLoadingChange(true);
            setIsLoading(true);
            try {
                // alert(JSON.stringify(data, null, 2));
                const result = await customHttpClient.post("/mentors/apply", undefined, data);
                props.onSuccess();
            }
            catch (error: any) {
                defaultErrorToast(`Error: ${error?.data?.message || 'Unknown'}`);
            }
            finally {
                setIsLoading(false);
            }
            return;
        }

        const result = await formik.validateForm();
        if (Object.keys(result).length > 0) {
            return;
        }
        setCurrentStep(currentStep + 1);
    }

    function handleBack() {
        if (currentStep <= 0) {
            return;
        }
        setCurrentStep(currentStep - 1);
    }

    const step1Validation = yup.object({
        title: yup.string().trim().min(1).label('Title').required(),
        pitch: yup.string().trim().min(4).label('Pitch').required(),
        currentCompany: yup.string().trim().min(1).label('Current company').required(),
        yearsExperience: yup.number().min(1).max(80).required(),
    });
    
    const step2Validation = yup.object({
        hourlyPrice: yup.number().min(5).max(800).required(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            pitch: '',
            currentCompany: '',
            yearsExperience: '',
            hourlyPrice: null as number | null,
            attachmens: [] as Array<IMentorAttachment>,
            skills: [] as Array<IMentorSkill>,
            experiences: [] as Array<IMentorExperience>,
            referenceLinks: [] as Array<IMentorReferralLink>,
            workTimings: [] as Array<IMentorWorkTiming>,
        },
        enableReinitialize: true,
        // validationSchema: yup.object({
        //     title: yup.string().trim().min(1).label('First name').required(),
        //     pitch: yup.string().trim().min(4).label('Last name').required(),
        //     currentCompany: yup.string().trim().min(4).label('Country').required(),
        //     yearsExperience: yup.number().min(1).max(80).required(),
        //     hourlyPrice: yup.number().min(5).max(800).required(),
        //     attachments: yup.array(
        //         yup.object(
        //             {
        //                 name: yup.string().trim().required(),
        //                 url: yup.string().trim().required(),
        //                 type: yup.string(),
        //                 description: yup.string().trim().optional(),
        //                 claimDate: yup.date().max(moment.utc()),
        //                 expiryDate: yup.date().min(moment.utc()).optional(),
        //             }
        //         )
        //     ),
        //     skills: yup.array(
        //         yup.object(
        //             {
        //                 name: yup.string().trim().required(),
        //                 yearsExperience: yup.number().min(1).max(80).required(),
        //             }
        //         )
        //     ),
        //     experiences: yup.array(
        //         yup.object(
        //             {
        //                 title: yup.string().trim().min(1).required(),
        //                 description: yup.string().trim().min(1).required(),
        //                 fromDate: yup.date().max(moment.utc()).required(),
        //                 toDate: yup.date().min(yup.ref("fromDate")).optional(),
        //                 skills: yup.array(yup.string().trim().min(1)),
        //                 attachments: yup.array(
        //                     yup.object(
        //                         {
        //                             name: yup.string().trim().required(),
        //                             url: yup.string().trim().required(),
        //                             description: yup.string().trim().optional(),
        //                         }
        //                     )
        //                 ),
        //             }
        //         )
        //     ),
        //     referenceLinks: yup.array(
        //         yup.object(
        //             {
        //                 url: yup.string().trim().required(),
        //                 platform: yup.string().required(),
        //                 description: yup.string().trim().required(),
        //             }
        //         )
        //     ),
        //     workTimings: yup.array(
        //         yup.object(
        //             {
        //                 day: yup.number().min(0).max(6).required(),
        //                 fromTime: yup.string().required(),
        //                 toTime: yup.string().required(),
        //             }
        //         )
        //     ),
        // }),
        validationSchema: currentStep == 1 ? step1Validation : step2Validation,
        onSubmit: handleSubmit,
    });




    const Step1Form = (
        <div className="space-y-6">
            <div>
                <label htmlFor="title" className="block mb-2 text-sm text-gray-700 dark:text-white">Title</label>
                <input 
                    type="text" 
                    name="title" 
                    id="title" 
                    className="disabled:bg-sky-50 w-full bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder={"Senior Software Engineer"} 
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    required={true} /> 
                
                {formik.touched.title && formik.errors.title ? (
                    <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.title}</div>
                ) : null}
            </div>

            <div>
                <label htmlFor="pitch" className="block mb-2 text-sm text-gray-700 dark:text-white">Pitch</label>
                <textarea 
                    name="pitch" 
                    id="pitch" 
                    rows={5}
                    className="disabled:bg-sky-50 w-full bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder={"Try to tell more about yourself, outlining your expertise in bullet points or paragraphs"} 
                    value={formik.values.pitch}
                    onChange={formik.handleChange}
                    required={true} /> 
                
                {formik.touched.pitch && formik.errors.pitch ? (
                    <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.pitch}</div>
                ) : null}
            </div>

            <div>
                <label htmlFor="currentCompany" className="block mb-2 text-sm text-gray-700 dark:text-white">Current company</label>
                <input 
                    type="text" 
                    name="currentCompany" 
                    id="currentCompany" 
                    className="disabled:bg-sky-50 w-full bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder={"The current company you are working for"} 
                    value={formik.values.currentCompany}
                    onChange={formik.handleChange}
                    required={true} /> 
                
                {formik.touched.currentCompany && formik.errors.currentCompany ? (
                    <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.currentCompany}</div>
                ) : null}
            </div>

            <div>
                <label htmlFor="yearsExperience" className="block mb-2 text-sm text-gray-700 dark:text-white">Years of experience</label>
                <input 
                    type="number" 
                    name="yearsExperience" 
                    id="yearsExperience" 
                    className="disabled:bg-sky-50 w-full bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder={"5"} 
                    value={formik.values.yearsExperience}
                    onChange={formik.handleChange}
                    required={true} /> 
                
                {formik.touched.yearsExperience && formik.errors.yearsExperience ? (
                    <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.yearsExperience}</div>
                ) : null}
            </div>
        </div>
    );

    const Step2Form = (
        <div className="inset-0 top-0 bottom-0">
            <div>
                <label htmlFor="hourlyPrice" className="text-lg block mb-2 text-gray-700 dark:text-white">Price/hour (USD $)</label>
                <input 
                    type="number" 
                    name="hourlyPrice" 
                    id="hourlyPrice" 
                    className="font-poppins text-lg disabled:bg-sky-50 w-full bg-white text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder={"20"} 
                    value={formik.values.hourlyPrice || ''}
                    onChange={formik.handleChange}
                    required={true} /> 
                
                {formik.touched.hourlyPrice && formik.errors.hourlyPrice ? (
                    <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.hourlyPrice}</div>
                ) : null}
            </div>
        </div>
    );

    const Step3Form = (
        <div className="space-y-6">
            <div className="space-y-4">
                {
                    allExperiences.map((val) => {
                        return (
                            <ExperienceCell experience={val} onDeleteButtonClicked={() => {}} />
                        )
                    })
                }
            </div>
            {/* <div className="w-full bg-white text-gray-500 rounded-md px-2 py-2 shadow-md">
                a
            </div> */}
            <div onClick={() => { setIsAddExperienceShown(true); }} className="group cursor-pointer hover:bg-theme hover:bg-opacity-95 hover:text-white text-theme flex space-x-2 border-theme border-solid border-2 w-fit px-4 py-2 rounded-md">
                <SvgPlusIcon className="group-hover:stroke-white stroke-theme stroke-[3] w-3 h-3 my-auto" />
                <div className="font-medium">
                    Add new experience
                </div>
            </div>
        </div>
    );


    const AddExperienceForm = (
        <div className="px-2 pb-6">
            <div className="text-left font-poppins text-gray-700">
                Add your experience
            </div>
            <div className="pb-8 mt-4 text-black space-y-4">
                <div>
                    <label className="text-sm text-left block">Job Title</label>
                    <input 
                        type="text" 
                        name="title" 
                        id="experience-title" 
                        className="disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder={"Software Engineer"} 
                        value={currentExperience.title || ''}
                        onChange={handleSetCurrentExperience}
                        required={true} /> 
                </div>
                <div>
                    <label className="text-sm text-left block">Company</label>
                    <input 
                        type="text" 
                        name="company" 
                        id="experience-company" 
                        className="disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder={"XYZ"} 
                        value={currentExperience.company || ''}
                        onChange={handleSetCurrentExperience}
                        required={true} /> 
                </div>
                <div>
                    <label className="text-sm text-left block">Description</label>
                    <textarea 
                        name="description" 
                        id="experience-description" 
                        className="disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder={"Write what have you done and achieved throughout this experience"} 
                        rows={5}
                        value={currentExperience.description || ''}
                        onChange={(e) => (setCurrentExperience as React.Dispatch<React.SetStateAction<any>>)((prev: any) => ({...prev, description: e.target.value}))}
                        required={true} /> 
                </div>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                        <div className='group [&_input]:bg-white'>
                            <label htmlFor="startDate" className="block mb-2 text-sm text-gray-700 dark:text-white">Start Date</label>
                            
                            <DatePicker 
                                onChange={(fromDate) => (setCurrentExperience as React.Dispatch<React.SetStateAction<any>>)((prev: any) => ({...prev, fromDate}))} 
                                value={currentExperience.fromDate || null} 
                                options={
                                    {
                                        defaultDate: null, 
                                        todayBtn: false, 
                                        clearBtn: false, 
                                        maxDate: moment.utc().toDate(),
                                    }
                                } 
                                classNames='date-picker' 
                                setShow={(isShow) => setIsStartDatepickerVisible(isShow)} 
                                show={isStartDatepickerVisible}
                                />
                            {/* {formik.touched.birthDate && formik.errors.birthDate ? (
                                <div className='bg-sky-100 mt-1 text-red-700 font-regular text-sm'>{formik.errors.birthDate}</div>
                            ) : null} */}
                        </div>

                        <div className='group [&_input]:bg-white'>
                            <label htmlFor="endDate" className="block mb-2 text-sm text-gray-700 dark:text-white">End Date</label>
                            
                            <DatePicker 
                                onChange={(toDate) => (setCurrentExperience as React.Dispatch<React.SetStateAction<any>>)((prev: any) => ({...prev, toDate}))} 
                                value={currentExperience.toDate || undefined} 
                                options={{
                                    defaultDate: null, 
                                    todayBtn: false, 
                                    clearBtn: false, 
                                    maxDate: moment.utc().toDate(),
                                }} 
                                classNames='date-picker' 
                                setShow={(isShow) => setIsEndDatepickerVisible(isShow)} 
                                show={isEndDatepickerVisible} />
                            {/* {formik.touched.birthDate && formik.errors.birthDate ? (
                                <div className='bg-sky-100 mt-1 text-red-700 font-regular text-sm'>{formik.errors.birthDate}</div>
                            ) : null} */}
                        </div>
                    </div>
                </div>

                <div className="mb-24 [&_.search-wrapper]:border-none [&_.optionContainer]:border-none">
                    <MultiSelect 
                        classNames="bg-white text-black border-none rounded-md py-1"
                        selectionLimit={20} 
                        selectedItems={currentExperience.skills || []}
                        showSelectionLimit={20}
                        placeholder="Skills in this experience" 
                        onChange={onSkillsChange} 
                        options={skillsPool.map(it => it.name)}/>
                </div>
            </div>


            <div className="flex justify-center mt-2">
                {/* <button onClick={() => AddExperienceSwal.close()} className="bg-theme px-8 py-2 text-white font-medium rounded-md">
                    Add
                </button> */}
                <button onClick={() => addExperience(currentExperience)} className="bg-theme px-8 py-2 text-white font-medium rounded-md">
                    Add
                </button>
            </div>
        </div>
    );


    return (
        <div className="min-h-screen">
            {
                isLoading && <LoadingScreen isTransparent />
            }
            <OverlayVariableHeight onBackgroundClicked={() => {setIsAddExperienceShown(false); clearCurrentExperience(); }} onCrossIconClicked={() => { setIsAddExperienceShown(false); clearCurrentExperience(); }} bgClassNames="bg-sky-50 px-4" show={isAddExperienceShown}>
                {
                    isAddExperienceShown ? AddExperienceForm : ''
                }
            </OverlayVariableHeight>
            <div className='md:grid-flow-col grid grid-cols-2 md:grid-cols-3 min-h-screen'>
                
                <div className="w-full min-h-screen flex items-center justify-center bg-theme col-span-1 px-6">
                    <div className="text-3xl text-center text-white my-auto font-poppins space-y-6 py-16">
                        <div className="text-5xl">
                            {
                                currentStep == 1 ? '🤷‍♂️' : (
                                    currentStep == 2 ? '🤑' : (
                                        currentStep == 3 ? '👨‍🎓' : ''
                                    )
                                )
                            }
                            
                        </div>
                        <p>
                            {
                                currentStep == 1 ? 'We would like to know more about you!' : (
                                    currentStep == 2 ? 'Lets discuss more about your expected hourly price' : (
                                        currentStep == 3 ? 'Add your experience' : ''
                                    )
                                )
                            }
                            
                        </p>
                    </div>
                </div>

                <div className="w-full min-h-screen flex items-center justify-center bg-sky-100 col-span-1 md:col-span-2 px-6">
                    <div className="w-full">
                        {/* <div className="bg-red-100 p-6">
                            {AddExperienceForm}
                        </div> */}
                        <div className="w-full md:w-2/3 mx-auto space-y-6 py-4">
                            {currentStep == 1 && Step1Form}
                            { currentStep == 2 && Step2Form }
                            { currentStep == 3 && Step3Form }
                        </div>
                        
                        <div className="w-full md:w-2/3 mx-auto">
                            <button onClick={handleNext} className="bg-theme text-white float-right px-8 py-2 rounded-md mt-6 mb-8">
                                { currentStep == NUM_STEPS ? 'Submit' : 'Next' } 
                            </button>
                            {/* <button onClick={() => alert("HERE " + JSON.stringify(currentExperience, null, 2))} className="bg-theme text-white ml-4 px-8 py-2 rounded-md mt-6 mb-8">
                                ss
                            </button> */}
                            <button onClick={handleBack} disabled={currentStep == 1} className="disabled:bg-gray-300 disabled:text-gray-400 bg-theme text-white float-left px-8 py-2 rounded-md mt-6 mb-8">
                                Back
                            </button>
                        </div>
                        {/* <InvisibleAnimation durationMs={1000} show={isTest}>
                            <div className={`bg-green-200 ${isTest ? '' : ''}`}>
                                AAA
                            </div>
                        </InvisibleAnimation> */}
                    </div>
                </div>
                
            </div>
        </div>
    )
}

