import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { defaultErrorToast } from "../../components/common/toasts";
import customHttpClient from "../../utils/httpClient/httpClient";
import useInterval from "use-interval";

export interface IPhoneUpdateFormProps {
    initialValue: string;
    onLoadingChange: (shouldLoad: boolean) => void;
    onSuccess: (phone: string) => void;
}

export interface ISendOtpResult {
    token: string;
    secondsToResend: number;
}

export interface IVerifyOtpResult {
    
}

export type PhoneVerificationState = 'none' | 'pending-verification' | 'verification-failed' | 'failed' | 'completed';

export function PhoneUpdateForm(props: IPhoneUpdateFormProps) {

    const [phone, setPhone] = useState(props.initialValue);
    const [secondsToResend, setSecondsToResend] = useState(null as number | null);
    const [otpCode, setOtpCode] = useState(null as string | null);
    const [verificationToken, setVerificationToken] = useState(null as string | null);
    const [fieldError, setFieldError] = useState(null as string | null);
    const [verificationState, setVerificationState] = useState('none' as PhoneVerificationState | null)

    useInterval(() => {
        setSecondsToResend((prev) => (prev || 1) - 1);
    }, 
    (secondsToResend === null || secondsToResend === 0) ? null : 1000,
    false);


    /**
     *  format seconds to readable format. e.g: 61 -> 1:01, 3670 -> 1:01:10
     * @param seconds 
     */
    function formatSeconds(seconds: number) {
        if (seconds < 60) {
            return `${seconds}`;
        }
        else if (seconds < 3600) {
            const minutesAdjusted = Math.floor(seconds / 60);
            const secondsAdjusted = seconds - minutesAdjusted * 60;
            return `${minutesAdjusted}:${secondsAdjusted.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
        }
        else {
            let remainingSeconds = seconds;
            const hoursAdjusted = Math.floor(remainingSeconds / 3600);
            remainingSeconds -= hoursAdjusted * 3600;
            const minutesAdjusted = Math.floor(remainingSeconds / 60);
            remainingSeconds -= minutesAdjusted * 60;
            return `${hoursAdjusted}:${minutesAdjusted.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${remainingSeconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
        }
    }

    async function handleSendOtp() {
        if ((secondsToResend !== 0 && secondsToResend !== null) || (secondsToResend !== null && !verificationToken)) {
            throw new Error("You cannot send otp at the moment, please try again later");
        }

        try {
            props.onLoadingChange(true);
            const result = await customHttpClient.post<ISendOtpResult>("/user/phone-verification/send", undefined, {
                phone,
            });
            props.onLoadingChange(false);
            setVerificationToken(result.result.token);
            setSecondsToResend(result.result.secondsToResend);
        }
        catch (error: any) {
            throw new Error(error?.data?.message || "Unknown error occured");
        }
    }

    async function handleSubmitOtp() {
        if ((otpCode?.length || 0) > 6 || (otpCode?.length || 0) < 5) {
            throw new Error("Please enter the correct otp");
        }
        props.onLoadingChange(true);
        const result = await customHttpClient.post<IVerifyOtpResult>("/user/phone-verification/verify", undefined, {
            code: otpCode,
        }, {
            headers: {
                Authorization: `Bearer ${verificationToken}`,
            },
        });
        props.onLoadingChange(false);
        props.onSuccess(phone);
        return;
    }

    async function onSaveClicked() {
        try {
            if (verificationState === 'none') {
                await handleSendOtp();
                setVerificationState('pending-verification');
            }
            else if (verificationState === 'pending-verification') {
                await handleSubmitOtp();
            }
        }
        catch (error: any) {
            props.onLoadingChange(false);
            if (error?.data?.code && error?.data?.code === 401) {
                defaultErrorToast("Incorrect OTP. Please enter the correct OTP code received.");
                return;
            }
            defaultErrorToast(error?.data?.message || error.message || "Unknown error occured");
        }
    }

    const Step1Form = (
        <div>
            <PhoneInput 
                country={'eg'}
                value={phone}
                excludeCountries={['is']}
                countryCodeEditable={true}
                dropdownClass='bg-gray-50 border border-gray-300 text-gray-500 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                inputProps={
                    {
                        // name: 'phone',
                        className: "disabled disabled:opacity-25 px-12 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    }
                }
                onChange={(phone, country, e, formattedValue) => {
                    setFieldError(null);
                    setPhone(`+${phone}`);
                }}
            />
        </div>
    );

    const Step2Form = (
        <div>
            <input 
                type="text" 
                name="otp-code" 
                id="otp-code" 
                className="w-full bg-white shadow-md text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-4 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder={"12345"} 
                value={`${otpCode || ''}`}
                onChange={(it) => {
                    const value = it.target.value;
                    if (value === '') {
                        setOtpCode(null);
                        return;
                    }
                    if (isNaN(Number(value))) {
                        return;
                    }
                    setOtpCode(value);
                }}
                required={true} />
            
            <div className='mt-8'>
                <button 
                    disabled={secondsToResend !== 0} 
                    onClick={async () => {
                        try {
                            await handleSendOtp();
                        }
                        catch (error: any) {
                            defaultErrorToast(error.message);
                        }
                    }} 
                    className='w-fit mx-auto disabled:text-gray-400 text-theme px-2 py-1.5 duration-200'>
                    Resend Code {secondsToResend !== null && secondsToResend > 0 && `in ${formatSeconds(secondsToResend)}`}
                </button>
            </div>
        </div>
    )
    
    return (
        <div className='mt-6'>
            <div className='px-12'>
                {verificationState === 'none' ? Step1Form : Step2Form}
            </div>
            <div className='flex justify-center mt-8'>
                <button onClick={onSaveClicked} className='text-white mx-auto bg-theme px-24 py-2.5 rounded-lg shadow-md hover:shadow-xl duration-200'>
                    {verificationState === 'none' ? "Next" : "Verify"}
                </button>
            </div>
        </div>
    )
}


