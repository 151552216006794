"use client"
import { FormEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../stores';
import { registerUser } from '../../stores/reducers/authSlice';
import LoadingScreen from '../common/loading/LoadingScreen';
import { Link, Navigate } from 'react-router-dom';
import { defaultErrorToast } from '../common/toasts';

export default function SignupCard() {
    const dispatch = useDispatch<AppDispatch>();
    const { loading: signupLoading, error: signupError, accessToken } = useSelector(
        (state: RootState) => state.auth
    );

    async function handleCreateAccount() {
        const { firstName, lastName, password, email, phone } = formik.values;
        try {
            const result = await formik.validateForm();
        }
        catch (error: any) {
            defaultErrorToast("Invalid data " + error.message);
            return;
        }
        dispatch(registerUser({
            userData: {
                firstName,
                lastName,
                phone,
                email,
                password,
            },
            // token: signupToken!,
        }));
    }

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        formik.handleSubmit(e);
    }

    /* *************************** STATES *************************** */
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            retypePassword: '',
            phone: '',
        },
        validationSchema: yup.object({
            firstName: yup.string().trim().min(1).label('First name').required(),
            lastName: yup.string().trim().min(4).label('Last name').required(),
            email: yup.string().trim().email().label('Email').required(),
            password: yup.string().min(6).label('Password').required(),
            retypePassword: yup.string().oneOf([yup.ref('password')], "Passwords don't match"),
            phone: yup.string().trim().min(5).required(),
        }),
        onSubmit: handleCreateAccount,
    })
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (signupError) {
            defaultErrorToast(signupError);
        }
    }, [signupError]);

    /* *************************** HTML *************************** */

    if (!!accessToken) {
        return (
          <Navigate to={"/"} replace={true} />
        )
    }

    return (
        <section className="min-h-screen bg-sky-50 dark:bg-gray-900">
            { (isLoading || signupLoading) && <LoadingScreen /> }
            <div className="min-h-screen flex flex-col items-center justify-center px-6 py-12 mx-auto md:px-8 lg:px-10 rounded-md">
                <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src="/ment-icon.png" alt="logo" width={200} height={200} />
                    Ment
                </Link>
                <div className="w-full bg-sky-100 rounded-lg shadow-md dark:border md:mt-0 max-w-md lg:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-8 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Sign up
                        </h1>
                        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6" action="#">
                            <div>
                                <div className='mt-6'>
                                    <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                    <input 
                                        type="text" 
                                        name="firstName" 
                                        id="firstName" 
                                        className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="Mo" 
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        required={true} /> 
                                    
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div className='mt-6'>
                                    <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                    <input 
                                        type="text" 
                                        name="lastName" 
                                        id="lastName" 
                                        className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="Doe" 
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        required={true} />
                                    
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                <div className='mt-6'>
                                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                                    <PhoneInput 
                                        country={'eg'}
                                        value={formik.values.phone}
                                        excludeCountries={['is']}
                                        countryCodeEditable={true}
                                        // buttonClass='bg-black dark:bg-black'
                                        dropdownClass='bg-gray-50 text-gray-500 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-700 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                        inputProps={
                                            {
                                                // name: 'phone',
                                                className: "disabled disabled:opacity-25 px-12 bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            }
                                        }
                                        onChange={(phone, country, e, formattedValue) => {
                                            formik.setFieldError('phone', undefined);
                                            formik.setFieldValue('phone', `+${phone}`, false);
                                        }}
                                        />
                                    
                                    {formik.errors.phone ? (
                                            <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                                <div className='mt-6'>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        id="email" 
                                        className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="name@example.com" 
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        required={true} />

                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className='mt-6'>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        id="password" 
                                        placeholder="••••••••" 
                                        className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        value={formik.values.password}
                                        onChange={formik.handleChange} 
                                        minLength={6}
                                        required={true} />
                                    
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.password}</div>
                                    ) : null}
                                </div>
                                <div className='mt-6'>
                                    <label htmlFor="retypePassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Retype Password</label>
                                    <input 
                                        type="password" 
                                        name="retypePassword" 
                                        id="retypePassword" 
                                        placeholder="••••••••" 
                                        className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        value={formik.values.retypePassword}
                                        onChange={formik.handleChange}
                                        minLength={6}
                                        required={true} />
                                    
                                    {formik.touched.retypePassword && formik.errors.retypePassword ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.retypePassword}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="flex mx-auto w-2/3 shadow-md px-5 py-4 text-white bg-sky-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    >
                                    <div className="text-center w-full">
                                        SIGN UP
                                    </div>
                                    {/* LOG IN */}
                                </button>
                                {/* <button 
                                type='submit' 
                                className="mt-5 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                    Create Account
                                </button> */}
                            </div>
                            <div>
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Already have an account?{" "}
                                    <Link
                                        to="/auth/login"
                                        className="font-medium text-themedark underline hover:underline dark:text-primary-500"
                                    >
                                        Login
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}


