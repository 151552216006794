import { useState } from "react"
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

export function Footer() {
    return (
        <footer className="bg-blue-950 text-white">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="/" className="flex items-center">
                            <img src="ment-icon.png" className="h-8 me-3" alt="Logo" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap">Ment</span>
                        </a>
                        <div className="mt-2 text-light text-xs text-gray-500">
                            "Empowering Growth, Guiding Success"
                        </div>
                        <div className="flex mt-2 text-light text-md text-gray-500 space-x-2">
                            <a href="https://www.facebook.com/kivallo">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/kivallo">
                                <FaInstagram />
                            </a>
                            <a href="https://www.linkedin.com/company/kivallo">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>


                    {/* <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-300 uppercase dark:text-white">Resources</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="https://flowbite.com/" className="hover:underline">Ment</a>
                                </li>
                                <li>
                                    <a href="https://tailwindcss.com/" className="hover:underline">Tailwind CSS</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-300 uppercase dark:text-white">Follow us</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="https://github.com/themesberg/flowbite" className="hover:underline ">Github</a>
                                </li>
                                <li>
                                    <a href="https://discord.gg/4eeurUVvTy" className="hover:underline">Discord</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-300 uppercase dark:text-white">Legal</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="#" className="hover:underline">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div> */}


                </div>
            <hr className="my-6 border-gray-600 sm:mx-auto lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="/" className="hover:underline">Kivallo™</a>. All Rights Reserved.
                </span>
            </div>
            </div>
        </footer>

    )
}

