import { sharedCookies } from "../../utils/common/cookies";
import { localStorageKeys } from "../../utils/localStorage/keys";
import { Navigate } from "react-router-dom";

export function SuccessOAuthCallbackPage() {
    const isLoggedIn = !!sharedCookies.get(localStorageKeys.accessToken);

    if (isLoggedIn) {
        return (
            <Navigate to={"/"} />
        )
    }
    else {
        return (
            <Navigate to={"/login"} />
        )
    }
}


