import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./routing/ProtectedRoute";
import LoginPage from "./pages/auth/login";
import SignupPage from "./pages/auth/signup";
import HomePage from "./pages/home";
import { FailureOAuthCallbackPage } from "./pages/auth/failureCallback";
import { SuccessOAuthCallbackPage } from "./pages/auth/successCallback";
import ForgetPasswordPage from "./pages/auth/forgetPassword";
import { RequestsPage } from "./pages/requests";
import { RequestInfoPage } from "./pages/requests/[id]";
import { WalletPage } from "./pages/wallet";
import LandingPage from "./pages/landing";
import AboutUs from "./pages/aboutUs";
import UpcomingSchedulePage from "./pages/schedule";
import MentorsListPage from "./pages/mentors";
import { MentorDetailsPage } from "./pages/myMentorDetails";
import { OtherMentorDetailsPage } from "./pages/otherMentorDetails";
import { RequestDetailsPage } from "./pages/schedule/[id]/index";
import { TopupSuccessPage } from "./pages/wallet/topup/feedback/success";
import { TopupCancelPage } from "./pages/wallet/topup/feedback/cancel";

function Main() {
  // className = "dark"
  return (
    <main className="light">
      <Routes>
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/success" element={<SuccessOAuthCallbackPage />} />
        <Route path="/auth/failure" element={<FailureOAuthCallbackPage />} />
        <Route path="/auth/signup" element={<SignupPage />} />
        {/* <Route element={<ProtectedRoute withNavbar={true} />}>
          <Route path="/dashboard" element={<HomePage />} />
        </Route> */}
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard" element={<HomePage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard/requests" element={<UpcomingSchedulePage />} />
        </Route>

        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard/mentors" element={<MentorsListPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard/wallet" element={<WalletPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/wallet/topup/success" element={<TopupSuccessPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/wallet/topup/cancel" element={<TopupCancelPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard/mentors/me" element={<MentorDetailsPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard/mentors/:mentorId" element={<OtherMentorDetailsPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/dashboard/requests/:requestId" element={<RequestDetailsPage />} />
        </Route>
        
        <Route path="/" element={<LandingPage />} />
        
        <Route path="/about-us" element={<AboutUs />} />
        
        {/* <Route element={<ProtectedRoute withNavbar={true} />}>
          <Route path="/requests" element={<RequestsPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={true} />}>
          <Route path="/wallet" element={<WalletPage />} />
        </Route>
        
        <Route element={<ProtectedRoute withNavbar={false} />}>
          <Route path="/requests/:requestId" element={<RequestInfoPage />} />
        </Route> */}
        
        <Route path="*" element={<Navigate to={"/"} replace={true} />} />
      </Routes>
    </main>
  );
}

export default Main;
