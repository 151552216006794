import moment from "moment";

export interface IExperience {
    company: string;
    title: string;
    description: string;
    fromDate: Date;
    toDate?: Date;
    skills?: string[];
}

export interface IExperienceCellProps {
    experience: IExperience;
    onDeleteButtonClicked: () => void;
}

export function ExperienceCell(props: IExperienceCellProps) {

    return (
        <div className="w-full bg-white text-gray-500 rounded-xl px-4 py-4 shadow-md">
            
            <div className="grid grid-cols-2">
                <div className="col-span-1 my-auto">
                    <p className="text-black font-medium">
                        {props.experience.title}
                    </p>
                    <p className="text-sm">
                        {props.experience.company}
                    </p>
                </div>

                <div className="col-span-1 space-y-2">
                    <p className="mx-auto text-center text-sm">
                        {moment.utc(props.experience.fromDate).format('MMM YYYY')}
                    </p>

                    <div className="mx-auto w-1 h-4 rounded-full bg-gray-200">

                    </div>
                    
                    <p className="mx-auto text-center text-sm">
                        {props.experience.toDate ? moment.utc(props.experience.toDate).format('MMM YYYY') : 'Present'}
                    </p>
                </div>
            </div>

        </div>
    )
}

