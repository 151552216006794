import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { localStorageKeys } from '../../utils/localStorage/keys';
import customHttpClient from '../../utils/httpClient/httpClient';
import { sharedCookies } from '../../utils/common/cookies';
import { ISkillsListResult } from '../types/common.types';


export const getSkillsList = createAsyncThunk('common/skills', async () => {
  const response = await customHttpClient.get<ISkillsListResult[]>('/skills', undefined);
  return response.result;
});

interface CommonDataState {
  skills: ISkillsListResult[];
  loading: boolean;
  success: boolean;
  error: string | null;
}

const initialState: CommonDataState = {
    skills: [],
    loading: false,
    success: false,
    error: null,
};

const commonDataSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSkillsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSkillsList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.skills = action.payload;
      })
      .addCase(getSkillsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
  },
});

export const commonDataActions = commonDataSlice.actions;

export default commonDataSlice.reducer;
