import * as React from "react";

const SvgCalendarIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="current" stroke="current" width={15.75} height={18} viewBox="0 0 15.75 18" {...props}>
    <path
      d="M0 16.313A1.688 1.688 0 0 0 1.688 18h12.375a1.688 1.688 0 0 0 1.688-1.687V6.75H0Zm11.25-6.891A.423.423 0 0 1 11.672 9h1.406a.423.423 0 0 1 .422.422v1.406a.423.423 0 0 1-.422.422h-1.406a.423.423 0 0 1-.422-.422Zm0 4.5a.423.423 0 0 1 .422-.422h1.406a.423.423 0 0 1 .422.422v1.406a.423.423 0 0 1-.422.422h-1.406a.423.423 0 0 1-.422-.422Zm-4.5-4.5A.423.423 0 0 1 7.172 9h1.406A.423.423 0 0 1 9 9.422v1.406a.423.423 0 0 1-.422.422H7.172a.423.423 0 0 1-.422-.422Zm0 4.5a.423.423 0 0 1 .422-.422h1.406a.423.423 0 0 1 .422.422v1.406a.423.423 0 0 1-.422.422H7.172a.423.423 0 0 1-.422-.422Zm-4.5-4.5A.423.423 0 0 1 2.672 9h1.406a.423.423 0 0 1 .422.422v1.406a.423.423 0 0 1-.422.422H2.672a.423.423 0 0 1-.422-.422Zm0 4.5a.423.423 0 0 1 .422-.422h1.406a.423.423 0 0 1 .422.422v1.406a.423.423 0 0 1-.422.422H2.672a.423.423 0 0 1-.422-.422ZM14.063 2.25h-1.688V.563A.564.564 0 0 0 11.813 0h-1.125a.564.564 0 0 0-.562.563V2.25h-4.5V.563A.564.564 0 0 0 5.063 0H3.938a.564.564 0 0 0-.562.563V2.25H1.688A1.688 1.688 0 0 0 0 3.938v1.687h15.75V3.938a1.688 1.688 0 0 0-1.687-1.688Z"
      data-name="Icon awesome-calendar-alt"
    />
  </svg>
);
export default SvgCalendarIcon;

