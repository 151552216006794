import { GuideRequestStatus } from "../../pages/requests/types";
import { Circle } from "../common/circle";

/*
pending
cancelled
approvedByMentor @map("approved_by_mentor")
rejectedByMentor @map("rejected_by_mentor")
stale
completed
*/

export type RequestStatus = 'pending' | 'cancelled' | 'approvedByMentor' | 'rejectedByMentor' | 'stale' | 'completed';

export interface IRequestStatusVisualProps {
    // status: GuideRequestStatus;
    status: RequestStatus,
    width?: number;
    height?: number;
}

export function RequestStatusVisual(props: IRequestStatusVisualProps) {

    let colorClass: string;
    let textColorClass: string;
    let displayStatus: string;
    switch (props.status) {
        case 'pending':
            colorClass = 'bg-gray-400 dark:bg-white';
            textColorClass = 'text-gray-400 dark:text-gray-400';
            displayStatus = 'Pending';
            break;
        case 'approvedByMentor':
            colorClass = 'bg-green-600 dark:bg-green-600';
            textColorClass = 'text-green-600 dark:text-green-600';
            displayStatus = 'Approved';
            break;
        case 'cancelled':
            colorClass = 'bg-orange-500 dark:bg-orange-500';
            textColorClass = 'text-orange-500 dark:text-orange-500';
            displayStatus = 'Cancelled';
            break;
        case 'completed':
            colorClass = 'bg-gray-600 dark:bg-gray-600';
            textColorClass = 'text-gray-600 dark:text-gray-600';
            displayStatus = 'Completed';
            break;
        case 'rejectedByMentor':
            colorClass = 'bg-red-600 dark:bg-red-600';
            textColorClass = 'text-red-600 dark:text-red-600';
            displayStatus = 'Rejected';
            break;
        default:
            colorClass = 'bg-gray-400 dark:bg-white';
            textColorClass = 'text-gray-400 dark:text-gray-400';
            displayStatus = 'Pending';
            break;
    }
    return (
        <div className="flex align-middle">
            {/* <Circle width={10} height={10} classNamePrefix="bg-red-100" /> */}
            <div key={props.status} className={`flex align-middle my-auto rounded-full w-${props.width || 2} h-${props.height || 2} ${colorClass}`}>

            </div>
            <div className={`align-middle flex my-auto ml-2 ${textColorClass}`}>
                { displayStatus }
            </div>
        </div>
    );
}


