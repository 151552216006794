import moment from "moment";
import { Link } from "react-router-dom";
import { CustomInitialsAvatar } from "../../components/common/initialsAvatar/InitialsAvatar";
import { RequestStatusVisual } from "../../components/status/RequestStatusVisual";
import SvgCalendarIcon from "../../svgs/common/CalendarIcon";

export interface IRequestCellProps {
    requestInfo: {
        id: string;
        date: Date;
        dateFormatted: string;
        fromTime: string;
        toTime: string;
        mentorName: string;
        menteeName: string;
        description: string;
        mentorImageUrl?: string | null;
        menteeImageUrl?: string | null;
        mentorPosition?: string | null;
        menteePosition?: string | null;
        status: any;
    },
    type: 'sent' | 'received';
}

export function RequestCell(props: IRequestCellProps) {
    const displayedImageUrl = props.type === 'sent' ? props.requestInfo.mentorImageUrl : props.requestInfo.menteeImageUrl;
    const displayedName = props.type === 'sent' ? props.requestInfo.mentorName : props.requestInfo.menteeName;
    const displayedPosition = props.type === 'sent' ? props.requestInfo.mentorPosition : props.requestInfo.menteePosition;

    return (
        <div className="cell w-full xl:w-4/5 mx-auto my-2">
            <Link to={`/dashboard/requests/${props.requestInfo.id}`}>
                <div className="dark:bg-gray-800 bg-gray-100 px-4 py-6 border-gray-200 rounded-lg shadow-md transition duration-1500 ease-in-out hover:shadow-lg hover:cursor-pointer dark:shadow-gray-700">
                    <div className="mb-6 ml-4 flex">
                        <SvgCalendarIcon className="w-6 h-6 my-auto fill-orange-500 inline-block" />
                        <div className="inline-block text-theme ml-2 text-lg my-auto justify-center">
                            {props.requestInfo.dateFormatted}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-4">
                        <div className="col-span-1 space-x-2">
                            <div className="inline-block mx-1">
                                { !!displayedImageUrl && <img className="h-full w-14 lg:w-16 md:w-16 object-cover rounded-full border-0 border-gray-200" src={displayedImageUrl || ""} alt="Profile" /> }
                                { !displayedImageUrl && 
                                        <CustomInitialsAvatar className='px-8 py-8' name={`${displayedName}`} />
                                }
                            </div>
                            <div className="text-black align-middle my-auto inline-block">
                                <div>
                                    {displayedName}
                                </div>
                                <div className="text-sm text-gray-400 font-light">
                                    {displayedPosition}
                                </div>
                            </div>
                        </div>

                        <div className="col-span-1 text-gray-400 my-auto text-center text-sm">
                            Video Call
                        </div>
                        
                        <div className="grid grid-cols-5 col-span-1 text-gray-600 my-auto text-center text-sm">
                            <div className="col-span-2">
                                {props.requestInfo.fromTime}
                            </div>
                            <hr className="col-span-1 my-auto w-full h-1 bg-gray-300 border-0 rounded dark:bg-gray-700" />
                            <div className="col-span-2">
                                {props.requestInfo.toTime}
                            </div>
                        </div>

                        <div className="flex">
                            <div className="my-auto mx-auto">
                                <RequestStatusVisual status={props.requestInfo.status} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="grid grid-cols-2 col-span-1">
                    <div className="col-span-1 bg-green-100 mx-1">
                        { !!displayedImageUrl && <img className="h-full w-14 lg:w-16 md:w-16 object-cover rounded-full border-0 border-gray-200" src={displayedImageUrl || ""} alt="Profile" /> }
                        { !displayedImageUrl && 
                                <CustomInitialsAvatar name={`${displayedName}`} />
                        }
                    </div>
                    <div className="text-black align-middle my-auto bg-red-200 col-span-1">
                        aa
                    </div>
                </div> */}
            </Link>
        </div>
    )















    // return (
    //     <div className="cell w-full xl:w-4/5 mx-auto my-6">
    //         <Link to={`/requests/${props.requestInfo.id}`}>
    //             <div className="dark:bg-gray-800 bg-gray-100 px-4 py-10 border-gray-200 rounded-lg shadow-lg transition duration-1500 ease-in-out hover:shadow-2xl hover:cursor-pointer dark:shadow-gray-700">
    //                 <div className="flex flex-cols mx-6 h-20 justify-center">
    //                     <div className="flex flex-cols justify-center my-auto">
    //                         <div className="text-white text-center bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-8 md:px-8 lg:px-8 py-3.5 dark:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 duration-200">
    //                             { moment.utc(props.requestInfo.date).format("ddd, MMM DD, YYYY") } 
    //                         </div>
    //                     </div>
    //                     <hr className="my-auto w-24 md:w-48 lg:w-72 h-2 mx-4 md:mx-8 xl:mx-10 bg-gray-100 border-0 rounded dark:bg-gray-700" />
    //                     <div className="flex flex-cols justify-center my-auto">
    //                         <div className="text-white text-center bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-8 md:px-8 lg:px-8 py-3.5 dark:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 duration-200">
    //                             { moment.utc(props.requestInfo.date).format("ddd, MMM DD, YYYY") } 
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="mt-12 md:mt-8 ml-8 space-y-2 flex">
    //                     {/* <div className="dark:text-white flex flex-row mx-4 my-auto">
    //                         { props.type === 'sent' ? '⟶' : '⟵' }
    //                     </div> */}
    //                     <div className="self-center">
    //                         { !!displayedImageUrl && <img className="h-full w-14 lg:w-16 md:w-16 object-cover rounded-full border-0 border-gray-200" src={displayedImageUrl || ""} alt="Profile" /> }
    //                         { !displayedImageUrl && 
    //                                 <CustomInitialsAvatar name={`${displayedName}`} />
    //                         }
    //                     </div>
    //                     <div className="ml-4 flex my-auto flex-row w-4/5 overflow-hidden">
    //                         <h2 className="break-words w-full my-auto text-xl font-medium text-gray-900 dark:text-gray-200">{displayedName}</h2>
    //                         {/* ANd fuirbofebmfpoernceciejmcpexdmsixmdwndmwklcmlmclfemvcmemvpemfovmpmvmvpmmvfuirbofebmfpoernceciejmcpexdmsixmdwndmwklcmlmclfemvcmemvpemfovmpmvmvpmmvfuirbofebmfpoernceciejmcpexdmsixmdwndmwklcmlmclfemvcmemvpemfovmpmvmvpmmvcnwdcnld */}
    //                         {/* <div className="mt-1 text-gray-600 text-sm dark:text-gray-400">
    //                             <p>{user.displayAge}</p>
    //                             <p>{city.name}</p>
    //                         </div> */}
    //                     </div>
    //                     <div className="ml-2 flex origin-right w-1/5 float-right text-right pr-2 justify-end">
    //                         <RequestStatusVisual status={props.requestInfo.status} />
    //                     </div>
    //                 </div>
    //             </div>
    //         </Link>
    //     </div>
    // )
}

