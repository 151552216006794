import * as React from "react";
function SvgPersonalizedMentorIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" {...props}>
      <g id="support_1" transform="translate(-0.999 -1)">
        <path id="Path_27" data-name="Path 27" d="M45.549,40.722l-5.286-5.21a3.8,3.8,0,0,0-4.506-.551l-3.8,2.3L16.478,21.69l2.282-3.8a3.651,3.651,0,0,0-.551-4.487L12.9,8.076a3.689,3.689,0,0,0-5.191,0L5.2,10.586a14.317,14.317,0,0,0,0,20.268L22.771,48.441a14.374,14.374,0,0,0,20.268,0l2.51-2.529a3.651,3.651,0,0,0,0-5.191Zm-5.191,5.02a10.533,10.533,0,0,1-14.887,0L7.884,28.173a10.552,10.552,0,0,1,0-14.906l2.339-2.472,5.286,5.153-1.9,3-.8-.8a1.9,1.9,0,1,0-2.681,2.7l22.7,22.55a1.9,1.9,0,0,0,2.681-2.681l-.818-.818,2.871-1.787,5.267,5.115Z" transform="translate(0 5.416)"/>
        <path id="Path_28" data-name="Path 28" d="M30.112,1A17.112,17.112,0,0,0,13,18.112a16.96,16.96,0,0,0,1.9,7.605,17.112,17.112,0,0,1,7.814-7.8,7.263,7.263,0,0,1-.209-1.711,7.605,7.605,0,0,1,15.211,0,7.263,7.263,0,0,1-.209,1.711,17.112,17.112,0,0,1,7.91,7.8A17.074,17.074,0,0,0,30.112,1Z" transform="translate(10.817)"/>
        <path id="Path_29" data-name="Path 29" d="M33.734,11.68a7.605,7.605,0,0,1-11.218,0,13.309,13.309,0,0,0-7.206,8.214,17,17,0,0,0,25.44,0,13.309,13.309,0,0,0-7.016-8.214Z" transform="translate(12.899 9.626)"/>
        <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.5" cy="3.5" r="3.5" transform="translate(37.52 12.519)"/>
      </g>
    </svg>
  )
};
export default SvgPersonalizedMentorIcon;

