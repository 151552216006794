import SvgExpertGuidanceIcon from "../../../svgs/landing/ExpertGuidanceIcon";
import SvgPersonalizedMentorIcon from "../../../svgs/landing/PersonalizedMentorIcon";

export interface IDescriptionGridCellProps {
    iconElement: (props: any) => JSX.Element;
    title: string;
    body: string;
    isSelected?: boolean;
}

export default function DescriptionGridCell(props: IDescriptionGridCellProps) {
    const Icon = props.iconElement;
    const iconColorClassName = props.isSelected ? `fill-white` : `fill-theme`;
    const iconClassName = `w-12 h-12 mx-auto my-auto ${iconColorClassName}`;

    const textColorClassName = props.isSelected ? `text-white` : `text-theme`;
    const textClassName = `text-center justify-center mt-6 space-y-2 px-8 ${textColorClassName}`;

    const bgColorClassName = props.isSelected ? 'bg-theme' : 'bg-gray-200 shadow-md';
    return (
        <div className={`dark:bg-gray-900 py-10 h-full flex justify-center my-auto ${bgColorClassName}`}>
            <div className="justify-center my-auto space-y-8">
                {/* <svg className="w-12 h-12 mx-auto">
                    <PersonalizedMentorIcon />
                </svg> */}
                {/* <SvgPersonalizedMentorIcon className={iconClassName} /> */}
                {/* <SvgExpertGuidanceIcon className={iconClassName} /> */}
                {/* <div className={iconClassName}>
                    {props.iconElement}
                </div> */}
                <div className="px-0 py-0">
                    <Icon className={iconClassName} />
                </div>
                {/* <img className="w-12 h-12 mx-auto text-red-900 fill-red-900 stroke-red-900" src={props.iconPath} /> */}
                <div className={textClassName}>
                    <p className="font-bold uppercase">{props.title}</p>
                    <p className="font-light text-sm">{props.body}</p>
                </div>
            </div>
            {/* <h1 className="justify-center bg-red-500 my-auto">AAA</h1> */}
            {/* HEREEEE */}
            {/* <div className='flex-row toplanding'>
                <div>
                    <div className="w-full h-full flex mx-auto relative">
                        <img
                            className=" w-full h-full justify-center"
                            src="LandingTopImage.png"
                            alt="landing top"
                        />
                        <div className='bg-black opacity-70 w-full h-full absolute'></div>
                        
                        <div className='absolute top-1/2 left-1/4 transform -translate-y-1/2 mx-auto'>
                            <h1 className='text-white text-5xl md:text-7xl lg:text-9xl font-extrabold opacity-10'>GRO<br/>WTH</h1>
                        </div>
                        
                        <div className='w-1/2 absolute top-2/3 md:top-1/2 left-1/4 transform -translate-y-2/3 md:-translate-y-1/2 mx-auto'>
                            <p className='xl:text-xl lg:text-md md:text-sm text-xs'>Unlock Your Sucess With Expert Guidance.</p>
                            <p className='w-7/8 font-medium xl:text-7xl lg:text-6xl md:text-4xl text-2xl'>Empowering Mentoring for <span className='text-theme font-semibold'>Professional</span> Growth</p>
                            <p className='w-3/4 font-light text-xs md:text-md lg:text-lg mt-1 md:mt-4'>Welcome to our mentoring platform, where transformation begins. We belive that everyone has untapped potential waiting to be unleashed</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
