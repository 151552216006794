// import { useState, ChangeEvent } from 'react';

// type InputState<T> = {
//   value: T;
//   onChange: (event: ChangeEvent<HTMLInputElement>) => void;
//   setValue: (newValue: T) => void;
// };

// function useInputState<T>(initialValue: T): InputState<T> {
//   const [value, setValue] = useState<T>(initialValue);

//   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
//     setValue(event.target.value as T);
//   };

//   const setValueManually = (newValue: T) => {
//     setValue(newValue);
//   };
//   return {
//     value,
//     onChange: handleChange,
//     setValue: setValueManually,
//   };
// };

// export default useInputState;

import { useState, ChangeEvent } from 'react';

type IUseInputStateResult<T> = [T, (newValue: T) => void, (event: ChangeEvent<HTMLInputElement>) => void];
function useInputState<T>(initialValue: T): IUseInputStateResult<T> {
  const [value, setValue] = useState<T>(initialValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as T);
  };

  return [value, setValue, handleChange];
};

export default useInputState;

