import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";

export default function MultiSelect({ options, placeholder, selectedItems, showSelectionLimit = 4, classNames, selectionLimit, emptyMessage = "No remaining options", onChange }: { options: string[], selectedItems: string[], showSelectionLimit?: number, classNames?: string, placeholder: string, emptyMessage?: string, selectionLimit?: number, onChange: (currentItems: string[]) => any }) {
    // const [numSelectedItems, setNumSelectedItems] = useState(0);
    // const [selectedItems, setSelectedItems] = useState([] as string[]);

    return (
        <Multiselect
            selectedValues={selectedItems}
            avoidHighlightFirstOption={true}
            showCheckbox={true}
            hideSelectedList={selectedItems.length > showSelectionLimit}
            selectionLimit={selectionLimit}
            className={`${classNames ? classNames : 'dark:text-gray-700 text-red-500'}`}
            // placeholder={numSelectedItems > showSelectionLimit ? selectedItems.slice(0, showSelectionLimit).join(" - ") + " ......" : placeholder}
            placeholder={selectedItems.length > showSelectionLimit ? selectedItems.slice(0, showSelectionLimit).join(" - ") + " ......" : placeholder}
            isObject={false}
            onKeyPressFn={function noRefCheck(){}}
            onRemove={(selectedList, selectedItem) => { onChange(selectedList); }}
            onSearch={function noRefCheck(){}}
            onSelect={(selectedList, selectedItem) => { onChange(selectedList); }}
            options={options}
            emptyRecordMsg={emptyMessage}
        />
    );
}
