import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../stores';
import { useFormik } from 'formik';
import * as yup from 'yup';
import UserMenuBar from '../../components/common/userMenuBar';
import countriesInfo from '../../assets/countries.json';
import SingleSelect from '../../components/common/single_select_dropdown';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Datepicker from 'tailwind-datepicker-react';
import './index.css';
import { OverlayVariableHeight } from '../../components/common/overlays/overlayVariableHeight';
import { PhoneUpdateForm } from './phoneUpdateForm';
import LoadingScreen from '../../components/common/loading/LoadingScreen';
import customHttpClient from '../../utils/httpClient/httpClient';
import { CustomInitialsAvatar } from '../../components/common/initialsAvatar/InitialsAvatar';
import { defaultErrorToast } from '../../components/common/toasts';
import { logoutUser } from '../../stores/reducers/authSlice';

export interface IUserProfile {
    id: string;
    username: string | null;
    firstName: string;
    lastName: string;
    profileImageUrl: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    country: string;
    // languages: {
    //     userId: string;
    //     language: string;
    //     level: language_level;
    // }[];
    about: string | null;
}

// const countryNames = countriesInfo.map(it => it.name);
const countries = countriesInfo.map(it => ({id: it.name, value: it.name}));

export default function HomePage() {

    function handleProfileUpdate() {
    }

    function onSaveEmailClicked() {
        setIsChangeEmailPopupShown(false);
    }

    async function getUserProfile() {
        return await customHttpClient.get<IUserProfile>("/user/me/profile");
    }

    const aboutEditableRef = useRef<HTMLDivElement>(null);

    function onCancelClicked() {
        formik.setFieldValue('firstName', userInfo?.firstName);
        formik.setFieldValue('lastName', userInfo?.lastName);
        formik.setFieldValue('birthDate', userInfo?.birthDate ? userInfo.birthDate : null);
        // formik.setFieldValue('phone', userInfo.phone);
        formik.setFieldValue('email', userInfo?.email);
        formik.setFieldValue('about', userInfo?.about);
        if (aboutEditableRef.current) {
            aboutEditableRef.current.textContent = userInfo?.about || '';
        }
        formik.setFieldValue('country', null);
        setIsEditing(false);
    }

    async function onDoneClicked() {
        setIsLoading(true);

        try {
            const result = await formik.validateForm();
        }
        catch (error: any) {
            defaultErrorToast("Invalid data " + error.message);
            return;
        }

        const data: any = {
            ...formik.values,
            country: formik.values.country || undefined,
            ...(formik.values.birthDate && {
                birthDate: moment(formik.values.birthDate)?.format("YYYY-MM-DD") || undefined,
            }),
            about: aboutEditableRef.current?.textContent || null,
        };

        delete data['email'];
        delete data['phone'];
        delete data['languages'];

        try {
            // alert(JSON.stringify(data, null, 2));
            // alert(JSON.stringify({
            //     ...userInfo,
            //     ...data,
            // }, null, 2));
            await customHttpClient.patch("/user/me", undefined, data);
            setUserInfo((prev) => {
                return {
                    ...prev,
                    ...data,
                } as any;
            });
            setIsEditing(false);
        }
        catch(error: any) {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }
        finally {
            setIsLoading(false);
        }

    }

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            country: '',
            birthDate: null as Date | null,
            about: "",
            phone: "",
            email: "",
            languages: [] as string[],
            // email: '',
            // password: '',
            // retypePassword: '',
            // phone: '',
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            firstName: yup.string().trim().min(1).label('First name').required(),
            lastName: yup.string().trim().min(4).label('Last name').required(),
            country: yup.string().trim().min(4).label('Country').required(),
            // email: yup.string().trim().email().label('Email').required(),
            // password: yup.string().min(6).label('Password').required(),
            // retypePassword: yup.string().oneOf([yup.ref('password')], "Passwords doesn't match"),
            // phone: yup.string().trim().min(5).required(),
        }),
        onSubmit: handleProfileUpdate,
    })

    const [userInfo, setUserInfo] = useState(null as IUserProfile | null);
    const {loading: authLoading} = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        getUserProfile().then(result => {
            const userProfileInfo = result.result;
            setUserInfo({
                ...userProfileInfo,
                ...(!!userProfileInfo.birthDate && {
                    birthDate: moment.utc(userProfileInfo.birthDate).toDate(),
                }),
            });
            formik.setFieldValue('firstName', userProfileInfo.firstName);
            formik.setFieldValue('lastName', userProfileInfo.lastName);
            formik.setFieldValue('birthDate', userProfileInfo.birthDate ? moment.utc(userProfileInfo.birthDate).toDate() : null);
            formik.setFieldValue('phone', userProfileInfo.phone);
            formik.setFieldValue('email', userProfileInfo.email);
            formik.setFieldValue('about', userProfileInfo.about);
            setTimeout(() => {
                if (aboutEditableRef.current) {
                    aboutEditableRef.current.textContent = userProfileInfo.about;
                }
            }, 200);
            formik.setFieldValue('country', userProfileInfo.country);            
        }).catch((error: any) => {
            if (error.data.code === 401) {
                return dispatch(logoutUser());
            }
            // alert("ERROR AFTER " + JSON.stringify({error, code: error.code, message: error.message}, null, 2))
            defaultErrorToast(error.message);
        })
    }, []);

    const [isDatepickerVisible, setIsDatepickerVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isChangePhonePopupShown, setIsChangePhonePopupShown] = useState(false);
    const [isChangeEmailPopupShown, setIsChangeEmailPopupShown] = useState(false);

    if (!userInfo) {
        return (
            <div className='dark:bg-gray-900 flex bg-gray-100 min-h-screen'>
                {isLoading && <LoadingScreen isTransparent />}
            </div>
        )
    }

    return (
        <div className='dark:bg-gray-900 flex bg-gray-100 min-h-screen'>
            {(isLoading || authLoading) && <LoadingScreen isTransparent />}
            <div className='flex flex-col min-h-screen'>
                <UserMenuBar selectedId='home' />
            </div>
            <div className='flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                <div className='grid grid-cols-2 mt-10 h-16'>
                    <p className='col-span-1 my-auto float-left text-black font-medium text-xl font-poppins'>Welcome, {userInfo?.firstName}</p>
                    <div className='flex-col col-span-1 justify-end right-0 float-right place-items-center my-auto'>
                        {
                            userInfo?.profileImageUrl && (
                                <img
                                    className='rounded-lg shadow-md float-right aspect-square h-14 my-auto'
                                    src={userInfo?.profileImageUrl}
                                    alt=''
                                />
                            )
                        }
                        {
                            !userInfo?.profileImageUrl && (
                                <CustomInitialsAvatar className='px-8 py-8 float-right' name={`${userInfo?.firstName} ${userInfo?.lastName}`} />
                            )
                        }
                    </div>
                </div>

                {/* CONTENT DIV */}
                <div className='mb-10 flex-grow flex-col flex bg-sky-100 py-12 px-6 mt-6 rounded-md'>
                    {/* TOP IMAGE + Edit button DIV */}
                    <div className='grid grid-cols-2 h-24'>
                        <div className='col-span-1 justify-start left-0 float-left my-auto'>
                            <div className='flex'>
                                {
                                    userInfo?.profileImageUrl && (
                                        <img
                                            className='shadow-md h-24 aspect-square my-auto rounded-lg float-right'
                                            src={userInfo.profileImageUrl}
                                            alt=''
                                        />       
                                    )
                                }
                                {
                                    !userInfo?.profileImageUrl && (
                                        <CustomInitialsAvatar className='px-8 py-8 float-right' name={`${userInfo?.firstName} ${userInfo?.lastName}`} />
                                    )
                                }
                                <div className='h-full my-auto ml-4 text-black'>
                                    <p className='font-bold font-poppins'>{userInfo.firstName} {userInfo.lastName}</p>
                                    <p className='text-gray-400 text-sm'>{userInfo.email}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1 my-auto float-right font-poppins w-full text-right'>
                            {!isEditing && 
                                <button onClick={() => setIsEditing(true)} className='py-2.5 px-8 bg-theme text-white text-sm rounded-md'>
                                    Edit
                                </button>
                            }
                            {
                                isEditing && 
                                <div className='space-x-4'>
                                    <button onClick={onCancelClicked} className='duration-200 hover:text-white hover:bg-red-700 border-2 border-red-700 py-2 px-6 text-red-700 text-sm rounded-md'>
                                        Cancel
                                    </button>
                                    <button onClick={onDoneClicked} className='py-2.5 px-8 bg-theme text-white text-sm rounded-md'>
                                        Done
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    
                    
                    {/* 3 COLUMNS FOR EDITING AND ABOUT */}
                    <div className='flex-grow mt-14'>
                        <div className='md:grid-flow-col h-full grid grid-cols-1 md:grid-cols-3 md:gap-x-16 md:space-y-0 space-y-4'>
                            <div className='flex flex-col col-span-1 text-gray-700'>
                                <div className='text-sm'>
                                    About You
                                </div>

                                <div 
                                    ref={aboutEditableRef} 
                                    contentEditable={isEditing} 
                                    className={`inline-block h-max break-words input-box text-sm mt-4 rounded-lg p-4 flex-grow ${isEditing ? 'bg-white': 'bg-sky-50'}`} />

                            </div>
                            <div className='col-span-1 w-full space-y-4'>
                                <div>
                                    <label htmlFor="firstName" className="block mb-2 text-sm text-gray-700 dark:text-white">First Name</label>
                                    <input 
                                        type="text" 
                                        name="firstName" 
                                        id="firstName" 
                                        disabled={!isEditing}
                                        className="disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder={"Mo"} 
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        required={true} /> 
                                    
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="block mb-2 text-sm text-gray-700 dark:text-white">Last Name</label>
                                    <input 
                                        type="text" 
                                        name="lastName" 
                                        id="lastName" 
                                        disabled={!isEditing}
                                        className="disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder={"Adam"} 
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        required={true} /> 
                                    
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className='mt-1 text-red-700 font-regular text-sm'>{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                
                                <div className='overflow-hidden'>
                                    <label htmlFor="country" className="block mb-2 text-sm text-gray-700 dark:text-white">Country</label>

                                    <SingleSelect 
                                        isDisabled={!isEditing} 
                                        classNames="disabled:placeholder-gray-900 disabled:text-gray-400 text-gray-500 placeholder-gray-900 disabled:bg-sky-50 bg-white w-full" 
                                        onChange={(selected) => {formik.setFieldValue('country', selected)}} 
                                        options={countries} 
                                        placeholder='Country' 
                                        initialValue={formik.values.country || 'Country'} 
                                    />
                                </div>
                            </div>
                            <div className={`space-y-4 date-picker-group col-span-1 w-full ${isEditing ? '' : 'pointer-events-none'}`}>
                                <div className='group'>
                                    <label htmlFor="birthDate" className="block mb-2 text-sm text-gray-700 dark:text-white">Birth Date</label>
                                    
                                    <Datepicker onChange={(d) => formik.setFieldValue('birthDate', d)} value={formik.values.birthDate || undefined} options={{defaultDate: userInfo.birthDate, todayBtn: false, clearBtn: false, maxDate: moment.utc().subtract(8, 'years').toDate()}} classNames='date-picker' setShow={(isShow) => setIsDatepickerVisible(isShow)} show={isDatepickerVisible} />
                                    {formik.touched.birthDate && formik.errors.birthDate ? (
                                        <div className='bg-sky-100 mt-1 text-red-700 font-regular text-sm'>{formik.errors.birthDate}</div>
                                    ) : null}
                                </div>
                                
                                <div className='group'>
                                    <label htmlFor="phone" className="block mb-2 text-sm text-gray-700 dark:text-white">Phone</label>
                                    <button onClick={() => setIsChangePhonePopupShown(true)} disabled={!isEditing} className="disabled:cursor-default text-left cursor-pointer disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        {formik.values.phone || 'Phone not provided'}
                                    </button>
                                </div>
                                
                                <div className='group'>
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-700 dark:text-white">Email</label>
                                    <button 
                                        onClick={() => setIsChangeEmailPopupShown(true)} 
                                        // disabled={!isEditing} 
                                        disabled 
                                        className="disabled:cursor-default text-left cursor-pointer disabled:bg-sky-50 bg-white text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        {formik.values.email || 'Email not provided'}
                                    </button>
                                </div>

                                
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className='text-center bg-green-100 w-full'>
                    AA
                </div> */}
            </div>

            {/* <BaseOverlay onCrossIconClicked={() => setIsChangePhonePopupShown(false)} onBackgroundClicked={() => setIsChangePhonePopupShown(false)} show={isChangePhonePopupShown} /> */}
            <OverlayVariableHeight bgClassNames='bg-sky-50' onCrossIconClicked={() => setIsChangePhonePopupShown(false)} onBackgroundClicked={() => setIsChangePhonePopupShown(false)} show={isChangePhonePopupShown}>
                {
                    isChangePhonePopupShown && (
                        <PhoneUpdateForm 
                            initialValue={userInfo.phone || ''} 
                            onLoadingChange={(isLoading) => setIsLoading(isLoading)}
                            onSuccess={(phone) => {
                                setIsChangePhonePopupShown(false);
                                formik.setFieldValue('phone', phone);
                            }}
                        />
                    )
                }
            </OverlayVariableHeight>
            <OverlayVariableHeight bgClassNames='bg-sky-50' onCrossIconClicked={() => setIsChangeEmailPopupShown(false)} onBackgroundClicked={() => setIsChangeEmailPopupShown(false)} show={isChangeEmailPopupShown}>
                <div className='mt-6'>
                    <div className='px-12'>
                        <input 
                            type="text" 
                            name="email" 
                            id="email" 
                            disabled={!isEditing}
                            className="w-full bg-white shadow-md text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block py-4 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder={"email@example.com"} 
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            required={true} />
                    </div>
                    <div className='flex justify-center mt-8'>
                        <button onClick={onSaveEmailClicked} className='mx-auto bg-theme px-24 py-2.5 rounded-lg shadow-md hover:shadow-xl duration-200'>
                            Save
                        </button>
                    </div>
                </div>
            </OverlayVariableHeight>
            {/* <div>
            </div> */}
            {/* <button className='primary bg-gray-100 text-xl' onClick={handleTestOnClick}>TEST HERE</button> */}
        </div>
    )
}
