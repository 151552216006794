
// import { ReactComponent as WalletIcon } from 'wallet-icon.svg';

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ITransaction, ITransactionFilters, IWalletInfo } from "./types";
import customHttpClient from "../../utils/httpClient/httpClient";
import { defaultErrorToast, defaultSuccessToast } from "../../components/common/toasts";
import { Semaphore } from "../../utils/semaphore";
import { Pagination } from "react-pagination-bar";
import UserMenuBar from "../../components/common/userMenuBar";
import SvgPlusIcon from "../../svgs/common/PlusIcon";
import { OverlayVariableHeight } from "../../components/common/overlays/overlayVariableHeight";
import { RootState } from "../../stores";
import LoadingScreen from "../../components/common/loading/LoadingScreen";
import { TransactionCell } from "./transaction/cell";

export interface IWalletPageProps {
    transactionsPerPage?: number;
}

export function WalletPage({ transactionsPerPage = 10 }: IWalletPageProps) {
    const [walletInfo, setWalletInfo] = useState(null as IWalletInfo | null);
    const [transactions, setTransactions] = useState(null as ITransaction[] | null);

    const dispatch = useDispatch();
    const [currentTransactionsPage, setCurrentTransactionsPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [isAddCreditsPopupShown, setIsAddCreditsPopupShown] = useState(false);
    const [creditsToAdd, setCreditsToAdd] = useState(null as number | null);
    const [isWithdrawCreditsPopupShown, setIsWithdrawCreditsPopupShown] = useState(false);
    const [creditsToWithdraw, setCreditsToWithdraw] = useState(null as number | null);
    const [topupUrl, setTopupUrl] = useState(null as string | null);
    const {loading: authLoading} = useSelector((state: RootState) => state.auth);

    const semaphore = new Semaphore(5);

    function onAddCreditsClicked() {
        if (isAddCreditsPopupShown) return;

        setIsAddCreditsPopupShown(true);
    }
    
    function onWithdrawCreditsClicked() {
        if (isWithdrawCreditsPopupShown) return;

        setIsWithdrawCreditsPopupShown(true);
    }

    function hideAddCreditsPopup() {
        setIsAddCreditsPopupShown(false);
        setCreditsToAdd(null);
    }
    
    function hideWithdrawCreditsPopup() {
        setIsWithdrawCreditsPopupShown(false);
        setCreditsToWithdraw(null);
    }

    async function getWalletInfo() {
        setIsLoading(true);
        try {
            const result = await customHttpClient.get<IWalletInfo>(`/user/me/wallet`, undefined);
            setWalletInfo(result.result);

            await semaphore.acquire();
            setIsLoading(false);
            semaphore.release();
        }
        catch (error: any) {
            await semaphore.acquire();
            setIsLoading(false);
            semaphore.release();
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }
    }

    async function getTransactions({
        currentPage,
        filters,
    }: { currentPage?: number, filters?: ITransactionFilters }) {
        setIsLoading(true);
        try {
            const transactions = await customHttpClient.get<ITransaction[]>(`/user/me/transactions`, dispatch, {
                params: {
                    ...filters,
                    page: currentPage || 1,
                    limit: transactionsPerPage,
                },
            });
    
            setTransactions(transactions.result);
            setTotalTransactions((transactions.pagination?.pageSize || 0) * (transactions.pagination?.numPages || 0));

            await semaphore.acquire();
            setIsLoading(false);
            semaphore.release();
        }
        catch (error: any) {
            await semaphore.acquire();
            setIsLoading(false);
            semaphore.release();
            // defaultErrorToast(error?.message);
        }
    }

    async function handlePageChange(pageNumber: number) {
        setCurrentTransactionsPage(pageNumber);
        await getTransactions({currentPage: pageNumber, filters: {}});
    }

    async function onPopupAddCreditsClicked() {
        if (!creditsToAdd) {
            defaultErrorToast("You must specify the amount of credits");
            return;
        }
        if (creditsToAdd < 10) {
            defaultErrorToast("You must add a minimum of $10");
            return;
        }
        if (creditsToAdd > 10_000_000) {
            defaultErrorToast("You can add a maximum of $10M");
            return;
        }

        setIsLoading(true);
        try {
            const result = await customHttpClient.post<string>('/wallet/topup/request', undefined, {
                amount: creditsToAdd,
            });
            setTopupUrl(result.result);
        }
        catch (error: any) {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }
        finally {
            setIsLoading(false);
            setIsAddCreditsPopupShown(false);
        }
    }

    async function onPopupWithdrawCreditsClicked() {
        if (!creditsToWithdraw) {
            defaultErrorToast("You must specify the amount of credits");
            return;
        }
        if (creditsToWithdraw < 10) {
            defaultErrorToast("You must withdraw a minimum of $50");
            return;
        }
        if (creditsToWithdraw > 100_000) {
            defaultErrorToast("You can withdraw a maximum of $100K");
            return;
        }
        
        if (creditsToWithdraw > (walletInfo?.availableAmount || 0)) {
            defaultErrorToast("You have no enough funds to withdraw");
            return;
        }

        setIsLoading(true);
        try {
            await customHttpClient.post<string>('/wallet/withdraw', undefined, {
                amount: creditsToWithdraw,
            });
            defaultSuccessToast('Request sent successfully. Expect a response within 5 business days');
        }
        catch (error: any) {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }
        finally {
            setIsLoading(false);
            setIsWithdrawCreditsPopupShown(false);
        }
    }

    function onAddCreditsAmountChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (isNaN(e.target.value as any)) {
            return;
        }
        setCreditsToAdd(Number(e.target.value));
    }

    function onWithdrawCreditsAmountChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (isNaN(e.target.value as any)) {
            return;
        }
        setCreditsToWithdraw(Number(e.target.value));
    }

    useEffect(() => {
        getTransactions({});
        getWalletInfo().then().catch();
    }, []);

    if (topupUrl) {
        window.open(topupUrl, '_blank');
        setTopupUrl(null);
    }

    return (
        <div className='dark:bg-gray-900 flex bg-gray-100 min-h-screen'>
            {
                (isLoading || authLoading) && (
                    <div className="z-50 [&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen />
                    </div>
                )
            }

            <div className='flex flex-col'>
                <UserMenuBar selectedId='wallet' />
            </div>
            {/* <div className='flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                <div className='grid grid-cols-2 mt-10 h-16'>
                    <p className='col-span-1 my-auto float-left text-black font-medium text-xl font-poppins'>Welcome, {userInfo.firstName}</p>
                    <div className='flex-col col-span-1 justify-end right-0 float-right place-items-center my-auto'>
                        <img
                            className='rounded-lg shadow-md float-right aspect-square h-14 my-auto'
                            src={userInfo.profileImageUrl}
                        />
                    </div>
                </div>
            </div> */}
            <div className="bg-sky-50 text-theme w-full">
                {/* <img src={'wallet-icon.svg'} /> */}
                {/* <WalletIcon /> */}
                <div className="flex items-center justify-center pt-10">
                    <div className="flex flex-row items-center justify-center">
                        <svg className="my-auto" width="32px" viewBox="0 0 120 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>Group</title>
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g className="fill-theme" id="Group" fill="#000000" fillRule="nonzero">
                                    <path d="M72.8596886,49.5000004 C68.1002692,49.5000004 64.2879573,53.3326445 64.2879573,58.1121691 L64.2879573,62.4161551 C64.2879573,67.1956658 68.1002716,71.0283222 72.8596886,71.0283222 L111.424088,71.0283222 C116.183494,71.0283222 120,67.1956658 120,62.4161551 L120,58.1121691 C120,53.3326445 116.183494,49.5000004 111.424088,49.5000004 L72.8596886,49.5000004 Z M74.8310185,53.8081865 C74.8857777,53.8067745 74.9391681,53.8081865 74.9942431,53.8081865 C78.5193857,53.808184 81.4356008,56.7157122 81.4356008,60.2557541 C81.4356008,63.7958017 78.5193857,66.720132 74.9942431,66.720132 C71.4691128,66.720132 68.5738213,63.7957936 68.5738213,60.2557541 C68.5738213,56.7710282 71.3808202,53.8971443 74.8310185,53.8081865 Z M74.8812421,58.1121691 C73.7278251,58.1679008 72.8596805,59.0796941 72.8596886,60.2557541 C72.8596805,61.4697512 73.7853542,62.4161469 74.9942431,62.4161551 C76.2031321,62.4161551 77.145547,61.4697528 77.145547,60.2557541 C77.145547,59.0417545 76.2031321,58.1121683 74.9942431,58.1121691 C74.9564681,58.1121691 74.9184502,58.1103708 74.8812421,58.1121691 Z" id="Shape"></path>
                                    <path d="M0,18.2162694 C2.29167795,20.3312448 5.23042197,21.5283177 8.57172807,21.5283177 L102.852363,21.5283177 C107.611761,21.5283177 111.424088,25.3567544 111.424088,30.136283 L111.424088,45.1960186 L72.8596886,45.1960186 C65.7840204,45.1960186 59.9979076,51.0065998 59.9979076,58.1121691 L59.9979076,62.4161551 C59.9979076,69.5217196 65.7840188,75.3322991 72.8596886,75.3322991 L111.424088,75.3322991 L111.424088,90.3920379 C111.424088,95.171565 107.611761,99 102.852363,99 L8.57172807,99 C3.81231996,99 0,95.171565 0,90.3920379 L0,30.136283 L0,18.2162694 Z" id="Path"></path>
                                    <path d="M8.57172807,0 L85.7214591,0 C90.4808818,0 94.2764509,3.83264007 94.2764509,8.61216792 L94.2764509,17.2201324 L8.57172807,17.2201324 C3.81231996,17.2201324 2.13162821e-14,13.3916958 2.13162821e-14,8.61216792 C2.13162821e-14,3.83264007 3.81231996,0 8.57172807,0 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="ml-6 pt-0.5 text-2xl font-bold">
                        $ {walletInfo?.availableAmount || 0}
                    </div>
                </div>
                
                <div className="flex justify-center mt-4">
                    <div onClick={onAddCreditsClicked} className="cursor-pointer shadow-md hover:shadow-lg duration-200 my-auto bg-theme text-white ml-5 px-6 py-1.5 rounded-md flex">
                        <div className="justify-start flex flex-row space-x-2">
                            {/* <img 
                                src="/common/plusIcon.png"
                                className="inline-block my-auto"
                            /> */}
                            <SvgPlusIcon className="stroke-white stroke-2 w-3 h-3 inline-block my-auto" />
                            <div>
                                Add credits
                            </div>
                            {/* <SvgCrossIcon className="justify-start stroke-green-500 rotate-45 stroke-2 w-3 h-3 my-auto" /> */}
                        </div>
                        
                    </div>
                    <div onClick={onWithdrawCreditsClicked} className="cursor-pointer shadow-md hover:shadow-lg duration-200 my-auto bg-red-600 text-white ml-5 px-6 py-1.5 rounded-md flex">
                        <div className="justify-start flex flex-row space-x-2">
                            {/* <img 
                                src="/common/plusIcon.png"
                                className="inline-block my-auto"
                            /> */}
                            {/* <SvgPlusIcon className="stroke-white stroke-2 w-3 h-3 inline-block my-auto" /> */}
                            <div>
                                &#8212;
                            </div>
                            <div>
                                Withdraw credits
                            </div>
                            {/* <SvgCrossIcon className="justify-start stroke-green-500 rotate-45 stroke-2 w-3 h-3 my-auto" /> */}
                        </div>
                        
                    </div>
                </div>



                <div className="mt-16">
                    <div className="flex justify-center text-2xl font-semibold">
                        Transactions 
                    </div>
                    <div className="flex justify-center mt-2">
                        <hr className="my-auto w-4/5 md:w-3/5 lg:w-2/5 h-1 mx-4 md:mx-8 xl:mx-10 bg-gray-400 border-0 rounded dark:bg-gray-700" />
                    </div>
                    {
                        (transactions?.length || 0) > 0 && 
                        (
                            <div className="flex flex-cols mt-8 justify-center">
                                <div className="rounded-lg w-4/5 md:w-4/5 lg:w-3/5 pt-6 pb-8">
                                    <div className="mx-4">
                                        <ul className="mt-5 space-y-4 list-disc list-inside dark:text-gray-300 text-lg">
                                            {
                                                [...(transactions || [])].map((it, ind) => {
                                                    
                                                    return (
                                                        <TransactionCell key={ind} transaction={it} />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        (totalTransactions > transactionsPerPage && 
                            <div className='pagination-bar flex flex-col justify-center mt-12 space-y-4 text-gray-500'>
                                <Pagination currentPage={currentTransactionsPage} itemsPerPage={transactionsPerPage} onPageChange={handlePageChange} totalItems={totalTransactions} pageNeighbours={1} withGoToInput={false}/>
                            </div>
                        )
                    }

                    {
                        (transactions?.length || 0) === 0 && 
                        (
                            <h3 className="mt-20 text-center text-2xl">
                                No transactions
                            </h3>
                        )
                    }
                </div>

                <OverlayVariableHeight bgClassNames="bg-sky-50" show={isAddCreditsPopupShown} onBackgroundClicked={hideAddCreditsPopup} onCrossIconClicked={hideAddCreditsPopup}>
                    <div className="mt-2">
                        <div className="text-xl font-bold text-theme px-4">
                            Add Credits
                        </div>
                        <div className="flex px-4 mt-6">
                            <label className="inline-block h-fit my-auto" htmlFor="creditsToAdd">USD $</label>
                            <input 
                                type="text" 
                                name="creditsToAdd" 
                                id="creditsToAdd" 
                                className="inline-block ml-2 disabled:bg-sky-50 bg-white text-gray-900 font-poppins rounded-lg focus:ring-primary-600 focus:border-primary-600 flex-grow py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                placeholder={"1000"} 
                                value={creditsToAdd || ''}
                                onChange={onAddCreditsAmountChanged}
                                required={true} /> 
                        </div>
                        <button onClick={onPopupAddCreditsClicked} className="mx-auto bg-theme text-white flex mt-6 px-16 py-2 rounded-md shadow-lg">
                            Add
                        </button>
                    </div>
                </OverlayVariableHeight>
                
                
                <OverlayVariableHeight bgClassNames="bg-sky-50" show={isWithdrawCreditsPopupShown} onBackgroundClicked={hideWithdrawCreditsPopup} onCrossIconClicked={hideWithdrawCreditsPopup}>
                    <div className="mt-2">
                        <div className="text-xl font-bold text-theme px-4">
                            Withdraw Credits
                        </div>
                        <div className="flex px-4 mt-6">
                            <label className="inline-block h-fit my-auto" htmlFor="creditsToWithdraw">USD $</label>
                            <input 
                                type="text" 
                                name="creditsToWithdraw" 
                                id="creditsToWithdraw" 
                                className="inline-block ml-2 disabled:bg-sky-50 bg-white text-gray-900 font-poppins rounded-lg focus:ring-primary-600 focus:border-primary-600 flex-grow py-3 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                placeholder={"1000"} 
                                value={creditsToWithdraw || ''}
                                onChange={onWithdrawCreditsAmountChanged}
                                required={true} /> 
                        </div>
                        <div className="text-xs text-gray-400 px-4 mt-2">
                            We might contact you shortly after your request for more details
                        </div>
                        <button onClick={onPopupWithdrawCreditsClicked} className="mx-auto bg-red-600 text-white flex mt-8 px-16 py-2 rounded-md shadow-lg">
                            Withdraw
                        </button>
                    </div>
                </OverlayVariableHeight>

            </div>
        </div>
    )
}

