import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ILoginUser, ILoginUserGoogle, ILoginUserResult, IRegisterUser, IRegisterUserResult } from '../types/auth.types';
import { localStorageKeys } from '../../utils/localStorage/keys';
import customHttpClient, { FormattedResponse } from '../../utils/httpClient/httpClient';
import { sharedCookies } from '../../utils/common/cookies';


export const registerUser = createAsyncThunk('auth/registerUser', async ({ userData }: { userData: IRegisterUser }) => {
  const response = await customHttpClient.post<IRegisterUserResult>('/user/register', undefined, userData);
  return response.result;
});

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { getState }) => {
  try {
    await customHttpClient.post('/user/logout');
    return null;
  }
  catch (error) {
    return null;
  }
});

export const loginUser = createAsyncThunk('auth/loginUser', async (loginData: (ILoginUser | ILoginUserGoogle) & { provider?: 'native' | 'google' }) => {
  let response: FormattedResponse<ILoginUserResult>;
  let rememberMe = !!loginData.rememberMe;

  if (loginData.provider === 'google') {
    response = await customHttpClient.post<ILoginUserResult>('/social/auth/google', undefined, {
      code: (loginData as ILoginUserGoogle).code,
    });
  }
  else {
    response = await customHttpClient.post<ILoginUserResult>('/user/login', undefined, {
      email: (loginData as ILoginUser).email,
      password: (loginData as ILoginUser).password,
    });
  }
  return {
    result: response.result,
    rememberMe,
  }
});

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  userInfo: {
    firstName: string | null;
    lastName: string | null;
    profileImageUrl: string;
  } | null;
  error: string | null;
  logoutError: string | null;
  loading: boolean;
  success: boolean;
}

const initialState: AuthState = {
  accessToken: sharedCookies.get(localStorageKeys.accessToken) || sessionStorage.getItem(localStorageKeys.accessToken) || null,
  refreshToken: sharedCookies.get(localStorageKeys.refreshToken) || sessionStorage.getItem(localStorageKeys.refreshToken) || null,
  userInfo: sharedCookies.get(localStorageKeys.userInfo) || JSON.parse(sessionStorage.getItem(localStorageKeys.userInfo) || null as any),
  error: null,
  logoutError: null,
  loading: false,
  success: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
        sharedCookies.remove(localStorageKeys.accessToken, { path: '/' }) // delete token from storage
        sharedCookies.remove(localStorageKeys.refreshToken, { path: '/' }) // delete token from storage
        sharedCookies.remove(localStorageKeys.userInfo, { path: '/' }) // delete user from storage
        
        sessionStorage.removeItem(localStorageKeys.accessToken) // delete token from storage
        sessionStorage.removeItem(localStorageKeys.refreshToken) // delete token from storage
        sessionStorage.removeItem(localStorageKeys.userInfo) // delete user from storage
        state.loading = false
        state.userInfo = null
        state.accessToken = null
        state.refreshToken = null
        state.error = null
      },
      setCredentials: (state, { payload }) => {
        state.userInfo = payload
      },
  },
  extraReducers: (builder) => {
    builder
    .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true; // registration successful
        state.accessToken = action.payload.accessToken as string;
        state.userInfo = action.payload.userInfo;

        sharedCookies.set(localStorageKeys.accessToken, action.payload.accessToken, { path: '/' });
        sharedCookies.set(localStorageKeys.refreshToken, action.payload.refreshToken, { path: '/' });
        sharedCookies.set(localStorageKeys.userInfo, action.payload.userInfo, { path: '/' });
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        const { result, rememberMe } = action.payload;
        state.accessToken = result.accessToken as string;
        // state.firstName = result.firstName as string;
        // state.lastName = result.lastName as string;
        state.userInfo = result.userInfo;
        state.error = null;
        state.success = true; // login successful

        // Store the access token in cookies
        if (rememberMe) {
          // Cookies.set('accessToken', result.accessToken);
          // Cookies.set('refreshToken', result.refreshToken);

          sharedCookies.set(localStorageKeys.accessToken, result.accessToken, { path: '/' });
          sharedCookies.set(localStorageKeys.refreshToken, result.refreshToken, { path: '/' });
          sharedCookies.set(localStorageKeys.userInfo, result.userInfo, { path: '/' });
        }
        else {
          sessionStorage.setItem(localStorageKeys.accessToken, result.accessToken);
          sessionStorage.setItem(localStorageKeys.refreshToken, result.refreshToken);
          sessionStorage.setItem(localStorageKeys.userInfo, JSON.stringify(result.userInfo));
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
      .addCase(logoutUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.logoutError = action.error.message as string;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        sharedCookies.remove(localStorageKeys.accessToken, { path: '/' }) // delete token from storage
        sharedCookies.remove(localStorageKeys.refreshToken, { path: '/' }) // delete token from storage
        sharedCookies.remove(localStorageKeys.userInfo, { path: '/' }) // delete user from storage
        sharedCookies.remove('connect.sid', { httpOnly: true, path: '/' }) // delete user from storage
        
        sessionStorage.clear();

        sessionStorage.removeItem(localStorageKeys.accessToken);
        sessionStorage.removeItem(localStorageKeys.refreshToken);
        sessionStorage.removeItem(localStorageKeys.userInfo);
        sessionStorage.removeItem('connect.sid');
        // sessionStorage.removeItem(localStorageKeys.accessToken) // delete token from storage
        // sessionStorage.removeItem(localStorageKeys.refreshToken) // delete token from storage
        // sessionStorage.removeItem(localStorageKeys.userInfo) // delete user from storage
        state.loading = false
        state.userInfo = null
        state.accessToken = null
        state.refreshToken = null
        state.error = null

        window.location.href = '/dashboard';
      });
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
