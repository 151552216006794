import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { Link } from "react-router-dom";

export type ILandingHeaderSectionId = 'home' | 'about us';

export interface ILandingHeaderProps {
    selectedSection: ILandingHeaderSectionId;
}

const sections: { id: ILandingHeaderSectionId, title: string, href: string }[] = [
    {
        id: 'home',
        title: 'Home',
        href: '/',
    },
    {
        id: 'about us',
        title: 'About Us',
        href: '/about-us',
    },
]

export default function LandingHeader(props: ILandingHeaderProps) {
    const { accessToken } = useSelector((state: RootState) => state.auth);
    return (
        <div className="grid grid-cols-6">
            {/* <div className="col-span-1 uppercase font-medium">
                <img className="w-8 h-8 mr-2" src="/ment-icon.png" alt="logo" width={200} height={200} />
                Ment
            </div> */}
            <Link to="/" className="col-span-1 flex items-center mb-6 text-2xl font-semibold text-gray-200 dark:text-white">
                <img className="w-8 mr-2" src="/ment-icon.svg" alt="logo" width={200} height={200} />
                Ment
            </Link>
            
            <div className="col-span-4 mx-auto space-x-12 text-gray-300">
                { sections.map((it, ind) => {
                    const isSelected = props.selectedSection === it.id;
                    return (
                        <a href={it.href} className={`${isSelected ? 'text-theme' : ''}`}>
                            {it.title}
                        </a>
                    )
                }) }
            </div>
            
            <div className="col-span-1">
                <button onClick={() => window.location.href = '/dashboard'} className='border-2 border-theme text-white py-2 md:px-8 px-2 text-sm rounded-md font-normal hover:bg-themedark'>
                    {!!accessToken ? 'Dashboard' : 'Get Started'}
                </button>
            </div>
        </div>
    )
}

