import * as React from "react";
const SvgPlusIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width={18} height={18} {...props}>
    <g fill="none" fillRule="evenodd" stroke="current">
      <path d="M16.657 8.657h-16M8.657.657v16" />
    </g>
  </svg>
);
export default SvgPlusIcon;

