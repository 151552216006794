import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../stores';
import UserMenuBar from '../../components/common/userMenuBar';
import { useEffect, useState } from 'react';
import { MentorCell } from './mentorCell';
import { Pagination } from 'react-pagination-bar';
import { getUserProfile } from '../../stores/reducers/userSlice';
import { CustomInitialsAvatar } from '../../components/common/initialsAvatar/InitialsAvatar';
import customHttpClient from '../../utils/httpClient/httpClient';
import { defaultErrorToast } from '../../components/common/toasts';
import LoadingScreen from '../../components/common/loading/LoadingScreen';

export interface IMentorSkill {
    skillName: string;
    yearsExperience: number;
}

export interface IMentorBasicInfo {
    id: string;
    title: string;
    currentCompany: string;
    yearsExperience: number;
    pitch: string;
    skills: IMentorSkill[];
    user: {
        firstName: string;
        lastName: string;
        birthDate?: string;
        profileImageUrl?: string;
        city?: string;
        country?: string;
    }
}

export interface IMentorsListProps {
    itemsPerPage?: number;
}

export default function MentorsListPage({itemsPerPage = 10}: IMentorsListProps) {


    const dispatch = useDispatch<AppDispatch>();
    // const { userInfo } = useSelector((state: RootState) => state.auth);
    const {userInfo} = useSelector((state: RootState) => state.user);
    const {loading: authLoading} = useSelector((state: RootState) => state.auth);

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [mentorsList, setMentorsList] = useState([] as IMentorBasicInfo[]);

    async function handlePageChange(page: number) {
        const oldPage = currentPage;
        setCurrentPage(page);

        setIsLoading(true);
        try {
            await getMentors(page);
        }
        catch (error) {
            defaultErrorToast("Error retrieving mentors");
            setCurrentPage(oldPage);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getMentors(page: number) {
        const result = await customHttpClient.get<IMentorBasicInfo[]>("/mentors", undefined, {
            params: {
                page,
                limit: itemsPerPage,
            }
        });
        setTotalItems(result.pagination?.numPages || 0);
        setMentorsList(result.result);
    }

    useEffect(() => {
        if (!userInfo) {
            alert("NOT USER INFO");
            dispatch(getUserProfile());
        }

        setIsLoading(true);
        getMentors(1).then(res => {
            setIsLoading(false);
        }).catch(error => defaultErrorToast("Error retrieving mentors"));

    }, []);

    return (
        <div className='dark:bg-gray-900 flex bg-gray-100 min-h-screen'>
            {(isLoading || authLoading) && <LoadingScreen isTransparent />}
            <div className='flex flex-col'>
                <UserMenuBar selectedId='mentors' />
            </div>
            <div className='flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                <div className='grid grid-cols-2 mt-10 h-16'>
                    <p className='col-span-1 my-auto float-left text-black font-medium text-xl font-poppins'>Welcome, {userInfo?.firstName || 'user'}</p>
                    <div className='flex-col col-span-1 justify-end right-0 float-right place-items-center my-auto'>
                        { !!userInfo?.profileImageUrl && <img className="h-full w-14 lg:w-16 md:w-16 object-cover rounded-full border-0 border-gray-200" src={userInfo.profileImageUrl || ""} alt="Profile" /> }
                        { !userInfo?.profileImageUrl && 
                                <CustomInitialsAvatar name={`${userInfo?.firstName} ${userInfo?.lastName}`} />
                        }
                    </div>
                </div>


                {/* CONTENT DIV */}
                <div className='mb-10 flex-grow flex-col flex bg-sky-100 py-8 px-6 mt-6 rounded-md'>
                    <div className='text-3xl font-bold text-theme mb-4'>
                        Explore Mentors
                    </div>
                    {
                        mentorsList.map(it => {
                            return (
                                <MentorCell
                                    id={it.id}
                                    name={`${it.user.firstName} ${it.user.lastName}`}
                                    position={it.title}
                                    company={it.currentCompany}
                                    city={it.user.city || 'Unknown'}
                                    country={it.user.country || 'Unknown'}
                                    skills={it.skills}
                                    yearsExperience={it.yearsExperience}
                                 />
                            )
                        })
                    }
                    
                    
                    <div className='pagination-bar flex flex-col justify-center mt-12 space-y-4 text-gray-500'>
                        <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} totalItems={totalItems} pageNeighbours={1} withGoToInput={false}/>
                    </div>
                </div>

                {/* <div className='text-center bg-green-100 w-full'>
                    AA
                </div> */}
            </div>
        </div>
    );
}
