import { useSelector } from 'react-redux'
import { NavLink, Navigate, Outlet } from 'react-router-dom'
import { RootState } from '../stores'
import { Navbar } from '../components/common/navbar';
import { useState } from 'react';

const ProtectedRoute = ({ withNavbar = false }: { withNavbar: boolean }) => {
  const { userInfo, accessToken } = useSelector((state: RootState) => state.auth);
  const [isProfileHidden, setIsProfileHidden] = useState(true);

  function onProfileHiddenUpdate(isProfileHidden: boolean) {
    setIsProfileHidden(isProfileHidden);
  }
  function onOutsideClickHandler(e: React.FormEvent) {
    if (!isProfileHidden) {
      setIsProfileHidden(true);
    }
  }

  // show unauthorized screen if no user is found in redux store
  if (!accessToken) {
    return (
      <Navigate to={'/auth/login'} replace={true} />
    )
  }

  return (
    <div onClick={onOutsideClickHandler}>
      { withNavbar && <Navbar isProfileHidden={isProfileHidden} onProfileHiddenUpdate={onProfileHiddenUpdate} /> }
      <Outlet />
    </div>
  )
}

export default ProtectedRoute;