import SvgExpertGuidanceIcon from "../../../svgs/landing/ExpertGuidanceIcon";
import SvgLearningResourcesIcon from "../../../svgs/landing/LearningResourcesIcon";
import SvgPersonalizedMentorIcon from "../../../svgs/landing/PersonalizedMentorIcon";
import SvgProgressTrackingIcon from "../../../svgs/landing/ProgressTrackingIcon";
import DescriptionGridCell, { IDescriptionGridCellProps } from "./cell";

const cells: IDescriptionGridCellProps[] = [
    {
        iconElement: SvgPersonalizedMentorIcon,
        title: "Personalized Mentor",
        body: "Discover the mentor who ignites your potential",
    },
    {
        iconElement: SvgExpertGuidanceIcon,
        title: "Expert Guidance",
        body: "Unlock your success with expert guidance",
    },
    {
        iconElement: SvgLearningResourcesIcon,
        title: "Learning Resources",
        body: "Get ready for an interactive learning revolution",
    },
    {
        iconElement: SvgProgressTrackingIcon,
        title: "Progress Tracking",
        body: "Track your progress and reach new insights",
    },
]

export default function DescriptionGrid() {
    return (
        <div className="flex mx-auto justify-center">
            <div className="lg:w-3/4 w-full">
                <div className='sm:px-6 mx-auto justify-center dark:bg-transparent bg-transparent grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-1'>
                    {cells.map((it, index) => {
                        return (
                            <div className="mx-auto w-3/4 sm:w-full">
                                <DescriptionGridCell isSelected={index % 2 == 1} body={it.body} iconElement={it.iconElement} title={it.title} key={index} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
