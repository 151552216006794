import moment from "moment";
import { ITransaction } from "../types";
import { ArrowIcon } from "../../../components/svgIcons/ArrowIcon";
import { TransactionStatusVisual } from "../../../components/status/TransactionStatusVisual";

export interface ITransactionCellProps {
    transaction: ITransaction;
}

export function TransactionCell({transaction}: ITransactionCellProps) {
    const type = transaction.type;
    const colorClass = 'bg-sky-100';
    const arrowColorClass = type === 'received' ? 'fill-green-500' : 'fill-red-500';
    const arrowType = type === 'received' ? 'up' : 'down';

    return (
        <div className="bg-sky-100 rounded-lg">
            <div key={transaction.id} className={`${colorClass} text-left z-100 px-4 w-full py-4 rounded-md cursor-pointer space-y-2`}>
                <div>
                    { moment.utc(transaction.createdAt).format("MMM DD, YYYY [-] h:mm A") } <span className="dark:text-gray-400"> GMT+0 </span>
                </div>
                <div>
                    <ArrowIcon colorClass={arrowColorClass} arrowType={arrowType} />
                    <div>{transaction.description}</div>
                </div>
                <div className="flex">
                    <div className="w-4/5 text-2xl font-semibold dark:text-white">
                        $ { transaction.amount }
                    </div>
                    <div className="ml-2 flex origin-right w-1/5 float-right text-right pr-2 justify-end">
                        <TransactionStatusVisual status={transaction.status} />
                    </div>
                </div>
            </div>
        </div>
    )
}

