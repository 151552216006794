import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { localStorageKeys } from '../../utils/localStorage/keys';
import customHttpClient from '../../utils/httpClient/httpClient';
import { sharedCookies } from '../../utils/common/cookies';
import { IUserProfileResult } from '../types/user.types';


export const getUserProfile = createAsyncThunk('user/profile', async (payload, { rejectWithValue }) => {
  try {
    const response = await customHttpClient.get<IUserProfileResult>('/user/me/profile', undefined);
    return response.result;
  }
  catch (error) {
    return rejectWithValue(error);
  }
});

interface UserProfileState {
  userInfo: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    profileImageUrl?: string | null;
  } | null;
  loading: boolean;
  success: boolean;
  error: string | null;
}

const initialState: UserProfileState = {
    userInfo: sharedCookies.get(localStorageKeys.userInfo) || JSON.parse(sessionStorage.getItem(localStorageKeys.userInfo) || null as any),
    loading: false,
    success: false,
    error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true; // registration successful
        state.userInfo = action.payload;

        sharedCookies.set(localStorageKeys.userInfo, action.payload, { path: '/' });
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
        customHttpClient.post('/user/logout').then().catch();
        
        sharedCookies.remove(localStorageKeys.accessToken, { path: '/' }) // delete token from storage
        sharedCookies.remove(localStorageKeys.refreshToken, { path: '/' }) // delete token from storage
        sharedCookies.remove(localStorageKeys.userInfo, { path: '/' }) // delete user from storage
        sharedCookies.remove('connect.sid', { httpOnly: true, path: '/' }) // delete user from storage
        
        sessionStorage.clear();

        sessionStorage.removeItem(localStorageKeys.accessToken);
        sessionStorage.removeItem(localStorageKeys.refreshToken);
        sessionStorage.removeItem(localStorageKeys.userInfo);
        sessionStorage.removeItem('connect.sid');
        // sessionStorage.removeItem(localStorageKeys.accessToken) // delete token from storage
        // sessionStorage.removeItem(localStorageKeys.refreshToken) // delete token from storage
        // sessionStorage.removeItem(localStorageKeys.userInfo) // delete user from storage
        state.loading = false
        state.userInfo = null
        state.error = null

        window.location.href = '/';
      })
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
