import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { uniqueId } from "lodash";
import { RequestStatus } from "../status/RequestStatusVisual";

export interface IRequestStatusProgressBarProps {
    status: RequestStatus;
}
// "pending" | "cancelled" | "approvedByGuide" | "rejectedByGuide" | "finished"

export function RequestStatusProgressBar(props: IRequestStatusProgressBarProps) {
    const statusToPercent = {
        'stale': 5,
        "pending": 20,
        'approvedByMentor': 40,
        'rejectedByMentor': 60,
        'cancelled': 80,
        'completed': 100,
    }
    let colorCode: string;
    if (props.status === 'pending') {
        colorCode = '#ff4747';
    }
    else if (props.status === 'cancelled') {
        colorCode = '#d97000';
    }
    else if (props.status === 'approvedByMentor') {
        colorCode = '#d2d900';
    }
    else if (props.status === 'rejectedByMentor') {
        colorCode = '#d2d900';
    }
    else if (props.status === 'stale') {
        colorCode = '#d2d900';
    }
    else { // completed
        colorCode = '#00a30e';
    }

    return (
        <div className="z-0 [&_.RSBprogression]:z-0">
            <ProgressBar
                percent={statusToPercent[props.status]}
                filledBackground={colorCode}
                key={uniqueId()}
                // filledBackground="linear-gradient(to right, #ff4747, #d97000, #d2d900, #00a30e, #00a30e)"
            >
                <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <div>
                            
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <div className="w-1 h-3 rounded-full dark:bg-gray-900">
                            {/* <hr className="w-0 sm:w-96 h-1 mx-16 my-8 sm:my-4 bg-gray-100 border-0 rounded dark:bg-gray-700" /> */}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <div className="w-1 h-3 rounded-full dark:bg-gray-900">
                            {/* <hr className="w-0 sm:w-96 h-1 mx-16 my-8 sm:my-4 bg-gray-100 border-0 rounded dark:bg-gray-700" /> */}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <div className="w-1 h-3 rounded-full dark:bg-gray-900">
                            {/* <hr className="w-0 sm:w-96 h-1 mx-16 my-8 sm:my-4 bg-gray-100 border-0 rounded dark:bg-gray-700" /> */}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <div className="pt-8 w-1 h-3 rounded-full dark:bg-gray-900">
                            {/* <hr className="w-0 sm:w-96 h-1 mx-16 my-8 sm:my-4 bg-gray-100 border-0 rounded dark:bg-gray-700" /> */}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <div>
                            {/* <hr className="w-0 sm:w-96 h-1 mx-16 my-8 sm:my-4 bg-gray-100 border-0 rounded dark:bg-gray-700" /> */}
                        </div>
                    )}
                </Step>
                {/* <Step>
                    {({ accomplished }: { accomplished: boolean }) => (
                        <img
                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                        width="30"
                        src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/9d/Pichu.png/revision/latest?cb=20170407222851"
                        />
                    )}
                </Step> */}
            </ProgressBar>
            <div className="flex mt-1 text-right text-sm text-gray-500">
                <div className="text-left justify-start">
                    Stale
                </div>
                <div className="w-1/5">
                    Pending
                </div>
                <div className="w-1/5">
                    Approved
                </div>
                <div className="w-1/5">
                    Rejected
                </div>
                <div className="w-1/5">
                    Cancelled
                </div>
                <div className="w-1/5">
                    Finished
                </div>
            </div>
        </div>
    )
}