
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "react-pagination-bar";
import { FaCheck, FaCross, FaCrosshairs, FaPlusCircle, FaStopCircle, FaXing } from "react-icons/fa";
import { Link } from "react-router-dom";

export interface IWalletPageProps {
    transactionsPerPage?: number;
}

export function TopupCancelPage() {

    return (
        <div className='dark:bg-gray-900 flex bg-gray-100 min-h-screen'>
            <div className="w-full">
                <div className="mt-10">
                    <div className="ml-10">
                        <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img className="w-8 flex my-auto" src="/ment-icon.png" alt="logo" width={200} height={200} />
                            <div className="ml-2 my-auto flex">
                                Ment
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="flex mx-auto justify-center mt-10">
                    <div>
                        <FaPlusCircle className="transform rotate-45 fill-red-700 w-12 h-12" />
                    </div>
                </div>
                <div className="flex mt-4">
                    <div className="mx-auto font-medium text-lg px-10">
                        We are sad you weren't able to topup your wallet 😢
                    </div>
                </div>
            </div>
        </div>
    )
}

