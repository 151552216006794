import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../stores';
import UserMenuBar from '../../components/common/userMenuBar';
import { useEffect, useState } from 'react';
import { RequestCell } from './requestCell';
import { Pagination } from 'react-pagination-bar';
import { getUserProfile } from '../../stores/reducers/userSlice';
import customHttpClient from '../../utils/httpClient/httpClient';
import { LanguageLevel } from '../../components/progressBar/LanguageProgressBar';
import { RequestStatus } from '../../components/status/RequestStatusVisual';
import { RequestPaymentStatus } from '../../components/progressBar/RequestPaymentStatusProgressBar';
import LoadingScreen from '../../components/common/loading/LoadingScreen';
import { CustomInitialsAvatar } from '../../components/common/initialsAvatar/InitialsAvatar';
import { defaultErrorToast } from '../../components/common/toasts';
import moment from 'moment';

const requestTypes = ['received', 'sent'] as const;
type RequestType = typeof requestTypes[number];

export interface IRequestInfo {
    id: string;
    status: RequestStatus;
    paymentStatus: RequestPaymentStatus;
    from: string;
    to: string;
    hours: number;
    description: string;
    meetingLink: string | null;
    price: number;
    senderId: string;
    mentorId: string;
    createdAt: string;
    sender: {
        id: string;
        fullname: string | null;
        profileImageUrl: string | null;
        languages: {
            userId: string;
            language: string;
            level: LanguageLevel;
        }[];
        city: string | null;
    };
    mentor: {
        title: string,
        user: {
            id: string;
            fullname: string | null;
            profileImageUrl: string | null;
            languages: {
                userId: string;
                language: string;
                level: LanguageLevel;
            }[];
        };
    };
}

export interface IUpcomingSchedulePageProps {
    itemsPerPage?: number;
}

export default function UpcomingSchedulePage({itemsPerPage = 10}: IUpcomingSchedulePageProps) {

    const dispatch = useDispatch<AppDispatch>();
    // const { userInfo } = useSelector((state: RootState) => state.auth);
    const {userInfo, loading: userLoading, error: userError} = useSelector((state: RootState) => state.user);
    const {loading: authLoading} = useSelector((state: RootState) => state.auth);

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(10);
    const [requests, setRequests] = useState([] as IRequestInfo[]);
    const [requestsError, setRequestsError] = useState(null as string | null);
    const [requestsType, setRequestsType] = useState( (!!sessionStorage.getItem('requests-cached-type-tab') && requestTypes.includes(sessionStorage.getItem('requests-cached-type-tab') as any) ? sessionStorage.getItem('requests-cached-type-tab') : 'received') as RequestType);

    function onRequestTypeChange(requestType: RequestType) {
        setRequestsType(requestType);
        sessionStorage.setItem('requests-cached-type-tab', requestType);
        setCurrentPage(1);
        getRequests({ page: 1, requestType });
    }
    
    function handlePageChange(page: number) {
        setCurrentPage(page);
        getRequests({ page, requestType: requestsType })
    }

    async function getRequests({ page, requestType }: { page: number, requestType: 'received' | 'sent' }) {
        setIsLoading(true);

        try {
            const result = await customHttpClient.get<IRequestInfo[]>(`/mentors/requests/${requestType}`, undefined, {
                params: {
                    page,
                    limit: itemsPerPage,
                }
            });
            setRequestsError(null);
            setRequests(result.result);
            setTotalItems((result.pagination?.numPages || 1) * itemsPerPage);
        }
        catch(error: any) {
            if (error?.data?.code === 403 && requestType === 'received') {
                // setRequestsError("You are not a mentor yet 😢");
                setRequests([]);
                setRequestsError("You cannot receive requests at the moment 😢");
            }
            else {
                defaultErrorToast(error?.data?.message || 'Unknown error occured');
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        dispatch(getUserProfile());
    }, []);
    
    useEffect(() => {
        getRequests({ page: 1, requestType: requestsType });
    }, []);

    return (
        <div className='dark:bg-gray-900 flex bg-gray-100 min-h-screen'>
            {
                (isLoading || userLoading) && (
                    <LoadingScreen isTransparent />
                )
            }
            <div className='flex flex-col'>
                <UserMenuBar selectedId='requests' />
            </div>
            <div className='flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                <div className='grid grid-cols-2 mt-10 h-16'>
                    <p className='col-span-1 my-auto float-left text-black font-medium text-xl font-poppins'>Welcome, {userInfo?.firstName || 'user'}</p>
                    <div className='flex-col col-span-1 justify-end right-0 float-right place-items-center my-auto'>
                        {
                            userInfo?.profileImageUrl && (
                                <img
                                    className='shadow-md h-24 aspect-square my-auto rounded-lg float-right'
                                    src={userInfo.profileImageUrl}
                                    alt=''
                                />
                            )
                        }
                        {
                            !userInfo?.profileImageUrl && (
                                <CustomInitialsAvatar className='px-8 py-8 float-right' name={`${userInfo?.firstName} ${userInfo?.lastName}`} />
                            )
                        }
                    </div>
                </div>


                {/* CONTENT DIV */}
                <div className='mb-10 flex-grow flex-col flex bg-sky-100 py-12 px-6 mt-6 rounded-md'>
                    <div className='gap-x-4 grid grid-cols-2 mx-auto mb-4'>
                        {
                            requestTypes.map(it => {
                                const isSelected = it === requestsType;
                                const colorClasses = isSelected ? 'bg-theme text-white' : 'bg-none text-gray-400'
                                return (
                                    <button onClick={() => onRequestTypeChange(it)} className={`duration-200 col-span-1 px-6 w-full py-1.5 rounded-md ${colorClasses}`}>
                                        {it[0].toUpperCase() + it.substring(1)}
                                    </button>
                                )
                            })
                        }
                        {/* <button className={`col-span-1 px-6 w-full py-1.5 rounded-md`}>
                            Received
                        </button>
                        <button className={`col-span-1 px-6 w-full py-1.5 rounded-md`}>
                            Sent
                        </button>
                        <button className={`col-span-1 px-6 w-full py-1.5 rounded-md`}>
                            History
                        </button> */}
                    </div>

                    {
                        requestsError && (
                            <div className='text-black mx-auto flex my-auto'>
                                {requestsError}
                            </div>
                        )
                    }
                    
                    {
                        !requestsError && !requests.length && (
                            <div className='text-black mx-auto flex my-auto'>
                                No requests available
                            </div>
                        )
                    }

                    {
                        requests.map(it => {
                            return (
                                <RequestCell
                                    requestInfo={
                                        {
                                            date: moment(it.from).toDate(),
                                            dateFormatted: moment(it.from).format("DD MMM"),
                                            description: it.description,
                                            id: it.id,
                                            menteeImageUrl: it.sender.profileImageUrl,
                                            menteeName: it.sender.fullname || 'User',
                                            mentorImageUrl: it.mentor.user.profileImageUrl,
                                            mentorName: it.mentor.user.fullname || 'Mentor',
                                            status: it.status,
                                            // title: it.,
                                            mentorPosition: it.mentor.title,
                                            // menteePosition: 'UI Designer',
                                            fromTime: moment(it.from).format("HH:mm Z"),
                                            toTime: moment(it.to).format("HH:mm Z"),
                                        }
                                    }
                                    type={requestsType}
                                 />
                            )
                        })
                    }
                </div>
                <div className='pagination-bar flex flex-col justify-center mt-12 space-y-4 text-gray-500'>
                    <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} totalItems={totalItems} pageNeighbours={1} withGoToInput={false}/>
                </div>
                {/* <div className='text-center bg-green-100 w-full'>
                    AA
                </div> */}
            </div>
        </div>
    );
}
