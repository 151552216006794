import * as React from "react";

const SvgListCheckmarkIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <g id="icon" transform="translate(-1031 -2880)">
      <g
        id="border"
        transform="translate(1031 2880)"
        fill="none"
        stroke="#4f80bf"
        stroke-width="2"
      >
        <circle cx="25" cy="25" r="25" stroke="none" />
        <circle cx="25" cy="25" r="24" fill="none" />
      </g>
      <path
        id="Icon_awesome-check"
        data-name="Icon awesome-check"
        d="M6.434,18.426.277,12.269a.947.947,0,0,1,0-1.339L1.617,9.59a.947.947,0,0,1,1.339,0L7.1,13.738l8.883-8.883a.947.947,0,0,1,1.339,0l1.339,1.339a.947.947,0,0,1,0,1.339L7.773,18.426A.947.947,0,0,1,6.434,18.426Z"
        transform="translate(1046.529 2893.36)"
        fill="#ed5f40"
      />
    </g>
  </svg>
);
export default SvgListCheckmarkIcon;
