import FaqsCell, { IFaqCellProps } from "./cell";

const cells: IFaqCellProps[] = [
    {
        question: "How to apply as a mentor?",
        answer: "After signing up, you will find the option to apply as a mentor. After which you fill the required details and our team will review and contact you for the next steps.",
    },
    {
        question: "Is there a maximum number of sessions per day?",
        answer: "You can have as many sessions per day as you like, depending on the available timings.",
    },
    {
        question: "What is the price of a single session per hour?",
        answer: "It depends on the mentor and seniority. You can check our wide range of carefully selected mentors and view the pricing accordingly",
    },
]

export default function Faqs() {
    return (
        <div className="flex mx-auto justify-center pb-24">
            <div className="lg:w-3/4 w-full px-6">
                <div className="grid grid-cols-2 py-10">
                    <div className="col-span-1 my-auto">
                        <div className="text-orange-600 font-bold text-sm">
                            <span className="font-extrabold mr-3">&#11834;</span> FAQs
                        </div>
                        <div className="text-3xl font-bold text-themedark xl:w-1/2">
                            Frequently Asked Questions
                        </div>
                    </div>
                    <div className="col-span-1 my-auto">
                        <div className="text-theme font-extralight text-xs">
                        We understand that you may have questions about how our mentoring program works and what to expect. That's why we've compiled a list of frequently asked questions to provide you with the information you need.
                        </div>
                    </div>
                </div>
                <div className='mx-auto justify-center dark:bg-transparent bg-transparent'>
                    {cells.map((it, index) => {
                        return (
                            <div className="mx-auto w-full">
                                <FaqsCell question={it.question} answer={it.answer} key={index} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
