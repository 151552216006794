import { useEffect, useState } from "react";
import LoadingScreen from "../../components/common/loading/LoadingScreen";
import { ApplyAsMentorForm } from "./applyAsMentor";
import UserMenuBar from "../../components/common/userMenuBar";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { CustomInitialsAvatar } from "../../components/common/initialsAvatar/InitialsAvatar";
import customHttpClient, { IErrorResponse } from "../../utils/httpClient/httpClient";
import { LanguageLevel } from "../../components/progressBar/LanguageProgressBar";

export type MentorState = 'applied' | 'rejected' | 'approved' | 'na';

export interface IMentorInfo {
    id: string;
    userId: string;
    hourlyPrice: number;
    workTimings: {
        day: number;
        fromTime: Date;
        toTime: Date;
    }[];
    user: {
        id: string;
        firstName: string;
        lastName: string;
        fullname: string | null;
        birthDate: Date | null;
        profileImageUrl: string | null;
        languages: {
            language: string;
            level: LanguageLevel;
        }[];
        city: string | null;
        country: string | null;
    };
    attachments: {
        id: string;
        description: string | null;
        name: string;
        claimDate: Date | null;
        expiryDate: Date | null;
        // type: mentor_attachment_type;
        type: string;
        url: string;
    }[];
    experiences: {
        id: string;
        mentorId: string;
        title: string;
        description: string;
        fromDate: Date;
        toDate: Date | null;
    }[];
    yearsExperience: number;
    title: string;
    currentCompany: string | null;
    settings: {
        mentorId: string;
        minHoursBeforeRequest: number;
        maxHoursBeforeRequest: number;
        maxHoursPerSession: number;
    } | null;
    skills: {
        skillName: string;
        yearsExperience: number;
        mentorId: string;
    }[];
    isAvailable: boolean;
    pitch: string;
    createdAt: Date;
    updatedAt: Date;
    approvalStatus: MentorApprovalStatus;
};

export type MentorApprovalStatus = 'pending' | 'approved' | 'rejected' | 'cancelled';

export interface IMentorProfileResponse {
    mentorInfo: IMentorInfo;
    approvalStatus: MentorApprovalStatus;
}

export function MentorDetailsPage() {
    const [mentorInfo, setMentorInfo] = useState(null as IMentorInfo | null);
    const [mentorState, setMentorState] = useState(null as {
        status: MentorApprovalStatus | 'na',
        reason?: string;
    } | null);
    const [canApply, setCanApply] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {userInfo, loading: authLoading} = useSelector((state: RootState) => state.auth);
    const [applyButtonClicked, setApplyButtonClicked] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        customHttpClient.get<IMentorProfileResponse>("/mentors/me/profile").then(result => {
            setMentorInfo(result.result.mentorInfo);
            setMentorState({
                status: result.result.approvalStatus,
            });
            setCanApply(false);
        }).catch((error: any) => {
            if (error?.data?.code === 404) {
                setMentorInfo(null);
                setMentorState({
                    status: 'na',
                });
                setCanApply(true);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const shouldShowApplyMentorForm = mentorState?.status === 'na' && canApply && applyButtonClicked && !isLoading;

    // if (mentorState?.status == 'na' && canApply && applyButtonClicked && !isLoading) {
    //     return (
    //         <div onClick={(e) => e.stopPropagation()} className="z-50">
    //             <button onClick={() => setApplyButtonClicked(false)} className="fixed left-6 top-6 bg-gray-300 text-gray-800 px-4 py-2 rounded-md">
    //                 &larr; Cancel
    //             </button>
    //             <ApplyAsMentorForm/>
    //         </div>
    //     )
    // }

    return (
        <div className="min-h-screen bg-white">
            {
                (isLoading || authLoading) && (
                    <div className="[&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen />
                    </div>
                )
            }
            {/* {
                (!mentorState || mentorState?.status == 'na') && (
                    (
                        canApply ? 
                            (
                                <ApplyAsMentorForm />
                            )
                            :
                            (
                                <div>
                                    <div>
                                        You are not a mentor yet 😢
                                    </div>
                                </div>
                            )
                    )
                )
            } */}

            <div onClick={(e) => e.stopPropagation()} className={`duration-[400ms] z-50 absolute inset-0 flex flex-col transform w-full h-full top-0 bottom-0 ${shouldShowApplyMentorForm ? '' : 'translate-x-full'}`}>
                <button onClick={() => setApplyButtonClicked(false)} className="fixed left-6 top-6 bg-gray-300 text-gray-800 px-4 py-2 rounded-md">
                    &larr; Cancel
                </button>
                {
                    shouldShowApplyMentorForm && 
                        <ApplyAsMentorForm 
                            // onLoadingChange={(isLoading) => setIsLoading(isLoading)} 
                            onSuccess={() => {
                                setApplyButtonClicked(false);
                                setMentorState({
                                    status: 'pending',
                                });
                                setCanApply(false);
                            }}
                        />
                }
            </div>

            {
                !applyButtonClicked && 
                <div className="flex bg-gray-100 min-h-screen">
                    <div className='flex flex-col'>
                        <UserMenuBar selectedId='mentors-me' />
                    </div>
                    <div className='text-black flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                        <div className='grid grid-cols-2 mt-10 h-16'>
                            <p className='col-span-1 my-auto float-left text-black font-medium text-xl font-poppins'>Welcome, {userInfo?.firstName || 'user'}</p>
                            <div className='flex-col col-span-1 justify-end right-0 float-right place-items-center my-auto'>
                                {
                                    userInfo?.profileImageUrl && (
                                        <img
                                            className='shadow-md h-24 aspect-square my-auto rounded-lg float-right'
                                            src={userInfo.profileImageUrl}
                                            alt=''
                                        />
                                    )
                                }
                                {
                                    !userInfo?.profileImageUrl && (
                                        <CustomInitialsAvatar className='px-8 py-8 float-right' name={`${userInfo?.firstName} ${userInfo?.lastName}`} />
                                    )
                                }
                            </div>
                        </div>

                        <div className="bg-sky-100 h-full my-6 rounded-xl py-8 px-8">
                            {
                                !mentorInfo && mentorState?.status !== 'approved' && (
                                    <div className="w-full px-8 text-center">
                                        <div className="font-poppins">
                                            {
                                                mentorState?.status === 'na' ? 
                                                    'You are not a mentor yet 😢' : mentorState?.status === 'pending' ?
                                                    'We have received your application. Our folks are reviewing your request and will get back to you soon, with good news hopefully 🤓' : mentorState?.status === 'rejected' ?
                                                    'Unfortunately, your application has been rejected 😔. Cheer up 😉, you still have got another chance. We will contact you as soon as we can get you in.' : ''
                                            }
                                        </div>

                                        {
                                            mentorState?.status === 'rejected' && mentorState.reason && (
                                                <div className="mt-4 text-gray-500">
                                                    {mentorState.reason}
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            mentorState?.status === 'na' && canApply && (
                                                <div className="mt-4">
                                                    <button className="bg-theme text-white px-5 py-2 rounded-lg shadow-md" onClick={() => {setApplyButtonClicked(true); }}>
                                                        Apply as a mentor!
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                mentorInfo && mentorState?.status === 'approved' && (
                                    <div>
                                        MENT
                                        <pre>
                                            {JSON.stringify(mentorInfo, null, 2)}
                                        </pre>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


