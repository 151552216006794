import { TransactionStatus } from "../../pages/wallet/types";

export interface ITransactionsVisualProps {
    status: TransactionStatus;
    width?: number;
    height?: number;
}

export function TransactionStatusVisual(props: ITransactionsVisualProps) {

    let colorClass: string;
    let textColorClass: string;
    let displayStatus: string;
    switch (props.status) {
        case 'pending':
            colorClass = 'dark:bg-white';
            textColorClass = 'dark:text-white';
            displayStatus = 'Pending';
            break;
        case 'cancelled':
            colorClass = 'dark:bg-orange-500';
            textColorClass = 'dark:text-orange-500';
            displayStatus = 'Cancelled';
            break;
        case 'completed':
            colorClass = 'dark:bg-gray-600';
            textColorClass = 'dark:text-gray-600';
            displayStatus = 'Completed';
            break;
    }
    return (
        <div className="flex align-middle">
            {/* <Circle width={10} height={10} classNamePrefix="bg-red-100" /> */}
            <div key={props.status} className={`flex align-middle my-auto rounded-full w-${props.width || 2} h-${props.height || 2} ${colorClass}`}>

            </div>
            <div className={`align-middle flex my-auto ml-2 ${textColorClass}`}>
                { displayStatus }
            </div>
        </div>
    );
}


