import moment from "moment";

interface IWorkTimingCellProps {
    day: number;
    fromTime: Date;
    toTime: Date;
}

export function WorkTimingCell(props: IWorkTimingCellProps) {
    return (
        <div className="">
            {/* {moment().day(props.day).format('dddd')} - {moment.utc(props.fromTime).format('HH:mm z')} - {moment.utc(props.toTime).format('HH:mm z')} */}

            <div className="grid grid-cols-2 bg-gradient-to-r from-orange-400 to-orange-500 w-fit rounded-md shadow-lg">
                <div className="col-span-1 text-white py-2 px-2">
                    <div className="font-medium text-lg">
                        {moment.utc().day(props.day).format('dddd')} 
                    </div>
                </div>
                <div className="col-span-1 text-white py-2 px-2">
                    <div>
                    {moment.utc(props.fromTime).format('HH:mm z')} - {moment.utc(props.toTime).format('HH:mm z')} 
                    </div>
                </div>
            </div>
        </div>
    )
}

