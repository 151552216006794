
export interface IArrowProps {
    colorClass: string;
    arrowType: 'up' | 'down';
}

export function ArrowIcon(props: IArrowProps) {
    const rotationClass = props.arrowType === 'down' ? '' : 'rotate-180';
    return (
        <div className="flex justify-start">
            <svg className={`self-center align-middle text-center snap-center my-auto ${rotationClass}`} width="30px" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 32 40" x="0px" y="0px">
                <path className={props.colorClass} d="M16,2C8.268,2,2,8.268,2,16s6.268,14,14,14,14-6.268,14-14S23.732,2,16,2Zm6.172,16.522l-4.764,3.339c-.845,.593-1.971,.593-2.817,0l-4.764-3.339c-.452-.317-.562-.941-.245-1.393h0c.317-.453,.941-.563,1.393-.246l4.024,2.821V10.695c0-.552,.448-1,1-1s1,.448,1,1v9.009l4.024-2.821c.452-.317,1.076-.207,1.393,.245h0c.317,.453,.207,1.077-.245,1.394Z"/>
                <path d="M16,31c-8.271,0-15-6.729-15-15S7.729,1,16,1s15,6.729,15,15-6.729,15-15,15Zm0-28c-7.168,0-13,5.832-13,13s5.832,13,13,13,13-5.832,13-13S23.168,3,16,3Z"/>
                <text x="0" y="47" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">Created by Phoenix Dungeon</text>
                <text x="0" y="52" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">from the Noun Project</text>
            </svg>
        </div>
    )
}

