import React from 'react';
import Spinner from './Spinner';

const LoadingScreen = ({ isTransparent = false }: { isTransparent?: boolean }) => {
    // if (isTransparent) {
    //     return (
    //         <div>
    //             <div className="fixed inset-0 flex items-center justify-center z-50 h-screen bg-gray-500 dark:bg-gray-900 opacity-50">
    //                 <Spinner />
    //             </div>
    //             <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
    //         </div>
    //     );
    // }
    
    return (
        <div className='z-50 loading-screen-container fixed inset-0' onClick={(e) => e.stopPropagation()}>
            <div className={`loading-screen-bg fixed inset-0 flex items-center justify-center z-50 h-screen bg-gray-900 dark:bg-gray-900 ${isTransparent ? 'opacity-50' : ''}`}>
                <Spinner />
            </div>
            <div className="loading-spinner fixed inset-0 z-40 opacity-50"></div>
        </div>
    );
};

export default LoadingScreen;
