import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import LoadingScreen from "../../../components/common/loading/LoadingScreen";
import UserMenuBar from "../../../components/common/userMenuBar";
import moment from "moment";
import { RequestStatus } from "../../../components/status/RequestStatusVisual";
import { CustomInitialsAvatar } from "../../../components/common/initialsAvatar/InitialsAvatar";
import { RequestStatusProgressBar } from "../../../components/progressBar/RequestStatusProgressBar";
import { RatingStars } from "../../../components/common/ratingStars";
import { FaCalendarDay } from "react-icons/fa";
import { OverlayVariableHeight } from "../../../components/common/overlays/overlayVariableHeight";
import { ReviewSessionForm } from "./ReviewSessionForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import customHttpClient from "../../../utils/httpClient/httpClient";
import { defaultErrorToast } from "../../../components/common/toasts";
import { RequestResponseForm } from "./RequestResponseForm";

type RequestPaymentStatus = 'none' | 'paid' | 'receivedPayment';
type MeetingStatus = 'unknown' | 'beforeMeeting' | 'meetingInProgress' | 'afterMeeting';

interface IRequestInfo {
    id: string;
    from: Date;
    to: Date;
    hours: number;
    status: RequestStatus;
    paymentStatus: RequestPaymentStatus;
    meetingLink?: string;
    mentor: {
        user: {
            id: string;
            firstName: string;
            lastName: string;
            fullname: string;
            profileImageUrl?: string;
        }
    };
    sender: {
        id: string;
        firstName: string;
        lastName: string;
        fullname: string;
        profileImageUrl?: string;
    };
    review?: {
        id: string;
        rating: number;
        details?: string;
    };
    description: string;
    skills: {
        skillName: string,
    }[];
}

export function RequestDetailsPage() {
    const params = useParams();
    const navigator = useNavigate();
    const requestId = params.requestId;

    const [requestInfo, setRequestInfo] = useState(null as IRequestInfo | null);
    const [isResponseFormShown, setIsResponseFormShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);
    // const [didSubmitReview, setDidSubmitReview] = useState(false);
    const { userInfo, loading: userLoading } = useSelector((state: RootState) => state.user);
    const {loading: authLoading} = useSelector((state: RootState) => state.auth);

    const onReviewSubmitted = async (rating: number, details?: string | null) => {
        setRequestInfo((prev) => {
            return {
                ...prev,
                review: {
                    rating,
                    details,
                }
            } as any;
        });
        setIsReviewPopupOpen(false);
    }

    async function cancelRequest() {
        setIsLoading(true);

        try {
            await customHttpClient.post(`/mentors/requests/${requestId}/cancel`, undefined);
            navigator(-1);
        }
        catch (error: any) {
            defaultErrorToast(error?.data?.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    function getMeetingStatus(requestInfo?: IRequestInfo | null): MeetingStatus {
        if (!requestInfo) return 'unknown';
        const meetingStartMoment = moment.utc(requestInfo.from);
        const meetingEndMoment = moment.utc(requestInfo.from).add(requestInfo.hours, 'hours');

        if (moment.utc().isSameOrBefore(meetingStartMoment)) {
            return 'beforeMeeting';
        }
        else if (moment.utc().isSameOrBefore(meetingEndMoment.add(20, 'minutes'))) {
            return 'meetingInProgress';
        }

        return 'afterMeeting';
    }
    
    const meetingStatus = getMeetingStatus(requestInfo);

    useEffect(() => {
        setIsLoading(true);

        customHttpClient.get<IRequestInfo>(`/mentors/requests/${requestId}`)
        .then(result => {
            setRequestInfo(result.result);
        }).catch(error => {
            defaultErrorToast(error?.data?.message);
            navigator(-1);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    
    const isCurrentUserMentor = !!userInfo?.id && requestInfo?.mentor.user.id === userInfo.id;

    return (
        <div className="min-h-screen bg-white">
            <OverlayVariableHeight onCrossIconClicked={() => setIsReviewPopupOpen(false)} onBackgroundClicked={() => setIsReviewPopupOpen(false)} bgClassNames="bg-sky-100" show={isReviewPopupOpen}>
                {
                    requestId && requestInfo && isReviewPopupOpen && (
                        <div className="px-4">
                            <ReviewSessionForm 
                                requestId={requestId}
                                onSubmit={onReviewSubmitted}
                            />
                        </div>
                    )
                }
            </OverlayVariableHeight>

            {
                (isLoading || userLoading || authLoading) && (
                    <div className="z-50 [&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen />
                    </div>
                )
            }

            <div className="flex bg-gray-100 min-h-screen">
                <div className='flex flex-col'>
                    <UserMenuBar selectedId='requests' />
                </div>
                <div className='text-black flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                    <div className='grid grid-cols-2 mt-10 h-16'>
                        <p className='col-span-1 my-auto float-left text-black font-medium text-xl font-poppins'>Welcome, {userInfo?.firstName || 'User'}</p>
                        <div className='flex-col col-span-1 justify-end right-0 float-right place-items-center my-auto'>
                            {
                                userInfo?.profileImageUrl && (
                                    <img
                                        className='shadow-md h-24 aspect-square my-auto rounded-lg float-right'
                                        src={userInfo.profileImageUrl}
                                        alt=''
                                    />
                                )
                            }
                            {
                                !userInfo?.profileImageUrl && (
                                    <CustomInitialsAvatar className='px-8 py-8 float-right' name={`${userInfo?.firstName} ${userInfo?.lastName}`} />
                                )
                            }
                        </div>
                    </div>


                    {
                        requestInfo && (
                            <div className="flex flex-col bg-sky-100 h-full my-6 rounded-xl py-8 px-8">
                                
                                <div className="grid grid-cols-2">
                                    <div className="flex ml-2 col-span-1">
                                        <FaCalendarDay className="w-8 h-8 my-auto fill-theme" />
                                        <div className="ml-3 text-theme font-medium">
                                            <p>
                                                { moment.utc(requestInfo.from).format("DD MMM, YYYY") }
                                            </p>
                                            <p className="text-sm text-gray-400">
                                                { moment.utc(requestInfo.from).format("HH:mm") } GMT - { requestInfo.hours } hour{ requestInfo.hours > 1 && 's' }
                                            </p>
                                        </div>
                                    </div>

                                    {
                                        requestInfo.status === 'pending' && !isCurrentUserMentor && (
                                            <div className="col-span-1 flex justify-end">
                                                <button onClick={() => cancelRequest()} className="bg-red-700 text-white px-5 py-2 rounded-lg h-fit">
                                                    Cancel
                                                </button>
                                            </div>
                                        )
                                    }

                                    {
                                        requestInfo.status === 'pending' && isCurrentUserMentor && (
                                            <div>
                                                <div className="flex justify-end pr-8 pt-6">
                                                    <button onClick={() => setIsResponseFormShown(true)} className="bg-theme rounded-lg px-7 py-2 text-white">
                                                        Respond
                                                    </button>
                                                </div>
                                                <OverlayVariableHeight show={isResponseFormShown} onCrossIconClicked={() => setIsResponseFormShown(false)} onBackgroundClicked={() => setIsResponseFormShown(false)} bgClassNames="bg-white pb-0"> 
                                                    <RequestResponseForm 
                                                        requestId={requestInfo.id}
                                                        onSuccess={(status) => { setRequestInfo((prev) => ({...prev, status,} as any)) }}
                                                    />
                                                </OverlayVariableHeight>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-y-3">
                                    <div className='col-span-1 justify-start left-0 float-left my-auto'>
                                        <div className='flex'>
                                            {
                                                isCurrentUserMentor ? (
                                                    <div className="flex justify-center">
                                                        {
                                                            requestInfo.sender.profileImageUrl && 
                                                                <img 
                                                                    src={requestInfo.sender.profileImageUrl}
                                                                    alt="profile"
                                                                />
                                                        }
                                                        {
                                                            !requestInfo.sender.profileImageUrl && 
                                                                <CustomInitialsAvatar className="px-8 py-8" name={`${requestInfo.sender.firstName} ${requestInfo.sender.lastName}`} />
                                                        }
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {
                                                            requestInfo.mentor.user.profileImageUrl && 
                                                                <img 
                                                                    src={requestInfo.mentor.user.profileImageUrl}
                                                                    alt="mentor-profile"
                                                                />
                                                        }
                                                        {
                                                            !requestInfo.mentor.user.profileImageUrl && 
                                                                <CustomInitialsAvatar className="px-8 py-8" name={`${requestInfo.mentor.user.firstName} ${requestInfo.mentor.user.lastName}`} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            <div className='h-full my-auto ml-4 text-black space-y-1'>
                                                <p className='font-bold text-lg font-poppins'>
                                                    {
                                                        isCurrentUserMentor ? (
                                                            <span className="text-lg">
                                                                <span className="text-sm font-normal">
                                                                    You are mentoring 
                                                                </span>
                                                                &nbsp; {requestInfo.sender.firstName} {requestInfo.sender.lastName}
                                                            </span>
                                                        ) : (
                                                            <span className="text-lg">
                                                                {requestInfo.mentor.user.firstName} {requestInfo.mentor.user.lastName} &nbsp;
                                                                <span className="text-sm font-normal">
                                                                    is mentoring you
                                                                </span>
                                                            </span>
                                                        )
                                                    }
                                                </p>
                                                {/* <p className='font-medium text-sm text-gray-400 font-poppins'>Title</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center md:justify-end my-auto">
                                        <div>
                                            {
                                                (requestInfo.status === 'completed') && (
                                                    requestInfo.review ? (
                                                        <div className="[&_.rating-stars-container]:justify-center mr-10 mx-auto w-full">
                                                            <RatingStars numStars={5} rating={requestInfo?.review?.rating || 0} />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <button onClick={(e) => setIsReviewPopupOpen(true)} className="bg-theme text-white px-5 py-2 rounded-lg">
                                                                Review
                                                            </button>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full justify-center h-8 mt-10">
                                    <div className="w-3/4 mx-auto">
                                        <RequestStatusProgressBar status={requestInfo.status} />
                                    </div>
                                </div>

                                <div className="mt-8">
                                    {
                                        requestInfo.paymentStatus === 'none' && (
                                            <div className="px-4 py-1.5 rounded-md w-fit bg-red-300 text-red-600">
                                                Not Paid
                                            </div>
                                        )
                                    }
                                    {
                                        (requestInfo.paymentStatus === 'paid' || (requestInfo.paymentStatus === 'receivedPayment' && !isCurrentUserMentor)) && (
                                            <div className="px-4 py-1.5 rounded-md w-fit bg-yellow-300 text-yellow-600 font-medium">
                                                Paid
                                            </div>
                                        )
                                    }
                                    {
                                        requestInfo.paymentStatus === 'receivedPayment' && isCurrentUserMentor && (
                                            <div className="px-4 py-1.5 rounded-md w-fit bg-green-300 text-green-600">
                                                Payment received
                                            </div>
                                        )
                                    }
                                </div>


                                <div className="mt-4">
                                    <div className="space-y-2 overflow-hidden">
                                        {
                                            requestInfo?.skills?.slice(0, 20)?.map((skill) => {
                                                return (
                                                    <div className="inline-block mr-2">
                                                        <div className="bg-orange-200 text-orange-600 px-2 py-1 w-fit rounded-lg">
                                                            {skill.skillName}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="mt-4 bg-sky-50 rounded-lg flex-grow px-4 py-4">
                                    {requestInfo.description}
                                </div>

                                {
                                    requestInfo.meetingLink && (
                                        <div className="flex justify-center mt-10">
                                            {
                                                (meetingStatus === 'beforeMeeting' || meetingStatus === 'meetingInProgress') && (
                                                    <a target="_blank" rel="noreferrer" href={requestInfo.meetingLink}>
                                                        <button disabled={meetingStatus !== 'beforeMeeting' && meetingStatus !== 'meetingInProgress'} className="disabled:bg-gray-500 bg-theme text-white px-8 py-2 rounded-lg">
                                                            Meeting Link
                                                        </button>
                                                    </a>
                                                )
                                            }
                                            <p className="my-auto ml-4 text-sm text-gray-500">
                                                {
                                                    meetingStatus === 'beforeMeeting' ? 
                                                    `Meeting starting in ${moment.utc(requestInfo.from).diff(moment.utc(), 'minutes') > 60 ? `${+(moment.utc(requestInfo.from).diff(moment.utc(), 'minutes') / 60).toFixed(1)} hours` : `${moment.utc(requestInfo.from).diff(moment.utc(), 'minutes')} minutes`}` : (
                                                        meetingStatus === 'meetingInProgress' ? 
                                                        `Meeting in progress` : (
                                                            meetingStatus === 'afterMeeting' ? 'Session ended' : ''
                                                        )
                                                    )
                                                }
                                            </p>
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }
                    
                </div>
            </div>
        </div>
    )
}