
export interface ISelectOption {
    value: string;
    id: string;
}

export default function SingleSelect({ options, placeholder, initialValue, classNames = "", isDisabled = false, emptyMessage = "No remaining options", onChange }: { options: ISelectOption[], placeholder: string, emptyMessage?: string, initialValue?: string | null, isDisabled?: boolean, classNames?: string | null, onChange: (item: string) => any }) {
    const classes = `${classNames} border-none appearance-none disabled:hover:cursor-default hover:cursor-pointer border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`;
    return (
        <div>
            <select style={{WebkitAppearance: 'none'}} disabled={isDisabled} id="select-option-parent" onChange={(e) => onChange(e.target.value)} defaultValue={!initialValue ? placeholder : initialValue} className={classes}>
                <option disabled key={-1} selected={!initialValue || initialValue === placeholder}>{ placeholder }</option>
                { options.map((val, ind) => {
                    return <option selected={val.value === initialValue} key={ind} onSelect={(e) => onChange(val.id)} value={ val.id }>{ val.value }</option>;
                }) }
            </select>
        </div>
    );
}
