import SvgExpertGuidanceIcon from "../../../svgs/landing/ExpertGuidanceIcon";
import SvgPersonalizedMentorIcon from "../../../svgs/landing/PersonalizedMentorIcon";

export interface IReviewCellProps {
    imagePath: string;
    reviewerName: string;
    position: string;
    content: string;
}

export default function ReviewCell(props: IReviewCellProps) {
    // const Icon = props.iconElement;
    // const iconColorClassName = props.isSelected ? `fill-white` : `fill-theme`;
    // const iconClassName = `w-12 h-12 mx-auto my-auto ${iconColorClassName}`;

    // const textColorClassName = props.isSelected ? `text-white` : `text-theme`;
    // const textClassName = `text-center justify-center mt-6 space-y-2 px-8 ${textColorClassName}`;

    // const bgColorClassName = props.isSelected ? 'bg-theme' : 'bg-gray-200 shadow-md';
    return (
        <div className="dark:bg-gray-900 py-10 h-full justify-center m-auto">
            <img 
                src={props.imagePath}
                className="rounded-full w-28 mx-auto"
            />

            <div className="text-lg">
                <span className="mr-1 inline-block transform translate-y-2 font-nunito-sans text-5xl text-theme">“</span> 
                <span>{props.content}</span> 
                <span className="absolute ml-1 inline-block transform rotate-180 -translate-y-2 font-nunito-sans text-5xl text-theme">“</span> 
                {/* <span className="font-nunito-sans text-5xl text-theme font-medium h-full bg-green-100">”</span> */}
            </div>

            <div className="mt-6 text-md">
                {props.reviewerName}
            </div>
            
            <div className="mt-1 text-xs font-medium text-theme">
                {props.position}
            </div>
        </div>
    );
}
