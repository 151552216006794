import LoginCard from "../../../components/auth/login_card";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { Link, Navigate } from "react-router-dom";
import useInputState from "../../../hooks/useInputState";
import { useRef, useState } from "react";
import * as yup from 'yup';
import LoadingScreen from "../../../components/common/loading/LoadingScreen";
import { toast } from "react-toastify";
import customHttpClient from "../../../utils/httpClient/httpClient";
import { defaultErrorToast } from "../../../components/common/toasts";

export default function ForgetPasswordPage() {
    const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [email, _, onEmailChange] = useInputState("");
  const [didSubmit, setDidSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  async function handleSubmit() {
    submitButtonRef.current?.blur();
    let validatedEmail: string;
    try {
        validatedEmail = yup.string().trim().email().required().validateSync(email);
    }
    catch (error) {
        defaultErrorToast('Please input a valid email');
        return;
    }
    setIsLoading(true);
    
    try {
        await customHttpClient.post('/user/forget-password', undefined, {
            email: validatedEmail,
        });
        setIsLoading(false);
        setDidSubmit(true);
    }
    catch (error: any) {
      defaultErrorToast(error?.data?.message || 'Error sending forget password email. Please retry again later');
        setIsLoading(false);
        return;
    }
  }

  const { accessToken } = useSelector((state: RootState) => state.auth);
  if (accessToken) {
    return <Navigate to={"/"} replace={true} />;
  }


  const DidSubmitComponent = (
    <div className="flex flex-col items-center justify-center text-center">
            <svg
            className="w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 xl:w-32 xl:h-32 mb-4 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            >
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
            </svg>
        <p className="text-md font-bold text-green-500 sm:text-base lg:text-lg xl:text-2xl">
            If {email.trim()} matches an email of a user, you will receive an email with a link to reset your password
        </p>
    </div>
  );

  const FormComponent = (
    <div className="space-y-4 md:space-y-6">
        <h1 className="text-xl sm:text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Forget Password
        </h1>
        <div>
            <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
            Email
            </label>
            <input
            type="email"
            name="email"
            id="email"
            className="bg-gray-50 shadow-sm text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@example.com"
            required={true}
            value={email}
            onChange={onEmailChange}
            />
        </div>

        <button
            ref={submitButtonRef}
            onClick={handleSubmit}
            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
            Submit
        </button>
    </div>
  );

  return (
    <div>
      {isLoading && <LoadingScreen isTransparent={true} />}

      <div>
        <section className="bg-sky-50 dark:bg-gray-900">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
            <Link
              to="/"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img
                className="w-8 h-8 mr-2"
                src="/ment-icon.png"
                alt="logo"
                width={200}
                height={200}
              />
              Ment
            </Link>
            <div className="w-full bg-sky-100 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

                { didSubmit && DidSubmitComponent }
                { !didSubmit && FormComponent }
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
