import { useState } from "react";
import ReviewCell, { IReviewCellProps } from "./cell";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// const cells: IDescriptionGridCellProps[] = [
//     {
//         iconElement: SvgPersonalizedMentorIcon,
//         title: "Personalized Mentor",
//         body: "Discover the mentor who ignites your potential",
//     },
//     {
//         iconElement: SvgExpertGuidanceIcon,
//         title: "Expert Guidance",
//         body: "Unlock your success with expert guidance",
//     },
//     {
//         iconElement: SvgLearningResourcesIcon,
//         title: "Learning Resources",
//         body: "Get ready for an interactive learning revolution",
//     },
//     {
//         iconElement: SvgProgressTrackingIcon,
//         title: "Progress Tracking",
//         body: "Track your progress and reach new insights",
//     },
// ]

const cells: IReviewCellProps[] = [
    {
        imagePath: "testimonials-images/mahmoud-samir.jpeg",
        content: "I wanted to share my feedback on your mentoring website. I have found the experience to be highly beneficial and would like to express my gratitude for the valuable support and guidance provided by your mentors.",
        reviewerName: "Mahmoud Samir",
        position: "Junior HR",
    },
    {
        imagePath: "testimonials-images/abdo-essam.png",
        content: "As an entrepeneur, I was looking for more experienced entrepreneurs to provide feedback and give me some hints/insights on what to do next. I can't express how thankful I am to find this website and be able to get clear guidelines on where to head next with my project and build connections!",
        reviewerName: "Abdelrahman Essam",
        position: "Senior Software Engineer",
    },
    {
        imagePath: "testimonials-images/loay-khalil.png",
        // content: "As an entrepeneur, I was looking for more experienced entrepreneurs to provide feedback and give me some hints/insights on what to do next. I can't express how thankful I am to find this website and be able to get clear guidelines on where to head next with my project and build connections!",
        content: "As a mentor, I was help to help younger generation to get a step ahead in reaching their goals",
        reviewerName: "Loay Khalil",
        position: "Entrepreneur & Co-Founder of Vidukation",
    }
    // {
    //     imagePath: "testimonials-images/abdo-essam.png",
    //     content: "As a mentor, I was help to help younger generation to get a step ahead in reaching their goals, and I was able to even have a side project where I can increase my income",
    //     reviewerName: "Abdelrahman Essam",
    //     position: "Senior Software Engineer",
    // },
    // {
    //     imagePath: "testimonials-images/loay-khalil.png",
    //     content: "As an entrepeneur, I was looking for more experienced entrepreneurs to provide feedback and give me some hints/insights on what to do next. I can't express how thankful I am to find this website and be able to get clear guidelines on where to head next with my project and build connections!",
    //     reviewerName: "Loay Khalil",
    //     position: "Entrepreneur & Co-Founder of Vidukation",
    // }
];

export default function MenteeReviews() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    // const [currentCell, setCurrentCell] = useState(cells[0]);

    const navigateNext = () => navigateReview('next');
    const navigatePrevious = () => navigateReview('previous');
    const navigateReview = (mode: 'previous' | 'next') => {
        if (isTransitioning) return;
        const addedAmount = mode === 'next' ? 1 : -1;
        const nextInd = currentIndex + addedAmount;
        if (nextInd < 0 || nextInd >= cells.length) {
            return;
        }
        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 300);

        setTimeout(() => {
            setCurrentIndex(nextInd);
        }, 200);
    }

    const reviewDivOpacityClass = isTransitioning ? 'opacity-10' : 'opacity-100'

    return (
        <div className="text-center mx-auto justify-center py-10">
            <div className="mx-auto justify-center w-1/3">
                <p className="text-orange-600 font-bold text-sm">&mdash; Meet Our Mentees &mdash;</p>
                <p className="text-2xl font-bold text-themedark">Take a look what our mentees had to say</p>
            </div>
            <div className="w-full mx-auto">
                <div className='sm:px-6 mx-auto justify-center dark:bg-transparent'>
                    <div className="mx-auto px-8 lg:px-0 lg:w-2/3 grid grid-cols-8 space-x-4 lg:space-x-4">
                        <div className="col-span-1 my-auto">
                            <button onClick={navigatePrevious} disabled={currentIndex <= 0} className="py-4 px-2 border-theme rounded-md border-solid border-2 w-fit hover:bg-theme hover:cursor-pointer group disabled:border-gray-400 hover:disabled:cursor-not-allowed hover:disabled:bg-transparent">
                                <FaChevronLeft className="text-theme group-hover:text-white group-disabled:text-gray-400" />
                            </button>
                        </div>
                        <div className={`delay-0 col-span-6 transition-all duration-200 ease-in ${reviewDivOpacityClass}`}>
                            <ReviewCell content={cells[currentIndex].content} imagePath={cells[currentIndex].imagePath} position={cells[currentIndex].position} reviewerName={cells[currentIndex].reviewerName} />
                        </div>
                        <div className="col-span-1 my-auto">
                            <button onClick={navigateNext} disabled={currentIndex >= (cells.length - 1)} className="py-4 px-2 border-theme rounded-md border-solid border-2 w-fit hover:bg-theme hover:cursor-pointer group disabled:border-gray-400 hover:disabled:cursor-not-allowed hover:disabled:bg-transparent">
                                <FaChevronRight className="text-theme group-hover:text-white group-disabled:text-gray-400" />
                            </button>
                            {/* <div className="py-4 px-2 disabled:border-gray-400 rounded-md border-solid border-2 w-fit hover:cursor-not-allowed group">
                                <FaChevronRight className="text-gray-400" />
                            </div> */}
                        </div>
                    </div>
                    {/* {cells.map((it, index) => {
                        return (
                            <div className="mx-auto w-1/2">
                                <ReviewCell content={it.content} imagePath={it.imagePath} position={it.position} reviewerName={it.reviewerName} key={index} />
                            </div>
                        )
                    })} */}
                </div>
            </div>
        </div>
    );
}
