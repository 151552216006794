import * as React from "react";
const SvgExpertGuidanceIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57.211}
    height={57.211}
    viewBox="0 0 57.211 57.211"
    {...props}
  >
    <g>
      <path
        d="M43.862 0H1.907A1.907 1.907 0 0 0 0 1.907v31.466a1.907 1.907 0 0 0 1.907 1.907h12.51v8.582a1.915 1.915 0 0 0 3.108 1.487L30.112 35.28h13.75a1.907 1.907 0 0 0 1.907-1.907V1.907A1.907 1.907 0 0 0 43.862 0Zm-1.907 31.466h-12.51a1.907 1.907 0 0 0-1.2.42L18.231 39.9v-6.527a1.907 1.907 0 0 0-1.907-1.907H3.814V3.814h38.141Z"
        data-name="Path 23"
      />
      <path
        d="M17.163 16.4a1.907 1.907 0 0 0 1.907-1.907v-1.907a3.814 3.814 0 1 1 7.628 0v.286a3.814 3.814 0 0 1-2.117 3.395l-.648.343a1.907 1.907 0 0 0-1.049 1.7v1.278a1.907 1.907 0 0 0 3.814 0v-.114a7.628 7.628 0 0 0 3.814-6.6v-.286a7.628 7.628 0 0 0-15.256-.002v1.907a1.907 1.907 0 0 0 1.907 1.907Z"
        data-name="Path 24"
      />
      <path
        d="M24.791 23.075a1.907 1.907 0 0 0-1.907 1.907v.954a1.907 1.907 0 1 0 3.814 0v-.954a1.907 1.907 0 0 0-1.907-1.907Z"
        data-name="Path 25"
      />
      <path
        d="M55.304 22.884h-5.72v10.489a5.721 5.721 0 0 1-5.721 5.721H31.447l-8.563 6.846v1.735a1.907 1.907 0 0 0 1.907 1.909h9.821l9.02 7.209a1.907 1.907 0 0 0 1.182.42 1.735 1.735 0 0 0 .82-.191 1.907 1.907 0 0 0 1.087-1.716v-5.722h8.583a1.907 1.907 0 0 0 1.907-1.907V24.791a1.907 1.907 0 0 0-1.907-1.907ZM36.71 46.245h-.954a1.907 1.907 0 0 1-1.907-1.907 1.907 1.907 0 0 1 1.907-1.907h.954a1.907 1.907 0 0 1 1.907 1.907 1.907 1.907 0 0 1-1.907 1.907Zm7.628 0h-.954a1.907 1.907 0 0 1-1.907-1.907 1.907 1.907 0 0 1 1.907-1.907h.954a1.907 1.907 0 0 1 1.907 1.907 1.907 1.907 0 0 1-1.907 1.907Zm7.628 0h-.954a1.907 1.907 0 0 1-1.907-1.907 1.907 1.907 0 0 1 1.907-1.907h.954a1.907 1.907 0 0 1 1.907 1.907 1.907 1.907 0 0 1-1.907 1.907Z"
        data-name="Path 26"
      />
    </g>
  </svg>
);
export default SvgExpertGuidanceIcon;

