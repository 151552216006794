import * as React from "react";
const SvgCrossIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width={13} height={13} {...props}>
    <g fill="none" fillRule="evenodd" stroke="current" strokeLinecap="round">
      <path d="M12.157.843.843 12.157M.843.843l11.314 11.314" />
    </g>
  </svg>
);
export default SvgCrossIcon;

