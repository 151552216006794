"use client";
import { AppDispatch, RootState } from "../../stores";
import { loginUser } from "../../stores/reducers/authSlice";
import { ILoginUser } from "../../stores/types/auth.types";
import { FormEvent, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useInputState from "../../hooks/useInputState";
import LoadingScreen from "../common/loading/LoadingScreen";
import { GoogleAuthButton } from "./google_auth_button";
import { defaultErrorToast } from "../common/toasts";

export default function LoginCard() {
  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [email, _, onEmailChange] = useInputState('');
  const [password, __, onPasswordChange] = useInputState('');
  const [rememberMe, ___, onRememberMeChange] = useInputState(false);
  const { accessToken, loading, error } = useSelector(
    (state: RootState) => state.auth
  );

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    loginButtonRef.current?.blur();
    if (loading) {
      return;
    }
    const userData: ILoginUser = {
        email,
        password,
        rememberMe,
    } as ILoginUser;
    dispatch(loginUser(userData));
  }

  function submitGoogleLogin(code: string) {
    if (loading) {
      return;
    }

    dispatch(loginUser({
      code,
      provider: 'google',
      // rememberMe,
      rememberMe: true,
    }));
  }

  // useEffect(() => {
  //     if (userInfo) {
  //         navigate('/');
  //     }
  // }, [navigate, userInfo]);

  useEffect(() => {
    if (error) {
      defaultErrorToast(error);
    }
  }, [error]);

  if (!!accessToken) {
    return (
      <Navigate to={"/dashboard"} replace={true} />
    )
  }
  return (
    <div>
      {loading && <LoadingScreen isTransparent={true} />}
      {!loading && (
        <div>
          <section className="bg-sky-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
              <Link
                to="/"
                className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
              >
                <img
                  className="w-8 mr-2"
                  src="/ment-icon.png"
                  alt="logo"
                  width={200}
                  height={200}
                />
                Ment
              </Link>
              <div className="w-full rounded-lg shadow-md dark:border md:mt-0 max-w-md lg:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="rounded-md p-8 space-y-4 md:space-y-6 bg-sky-100 shadow-lg">
                  <h1 className="text-xl font-extrabold leading-tight tracking-tight text-themedark md:text-3xl dark:text-white">
                    LOG IN
                  </h1>
                  <form
                    onSubmit={handleSubmit}
                    className="space-y-4 md:space-y-6"
                    action="#"
                  >
                    <div>
                      {/* <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-theme dark:text-white"
                      >
                        Email
                      </label> */}


                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-white text-gray-900 sm:text-sm rounded-lg shadow-sm px-4 py-3 focus:ring-primary-600 focus:border-primary-600 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="name@example.com"
                        required={true}
                        value={email}
                        onChange={onEmailChange}
                      />
{/* 
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full px-8 py-3 focus:ring-primary-600 focus:border-primary-600 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="name@example.com"
                        required={true}
                        value={email}
                        onChange={onEmailChange}
                      /> */}


                    </div>
                    <div>
                      {/* <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Password
                      </label> */}
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        className="bg-white text-gray-900 sm:text-sm rounded-lg shadow-sm px-4 py-3 focus:ring-primary-600 focus:border-primary-600 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required={true}
                        value={password}
                        onChange={onPasswordChange}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="remember"
                            aria-describedby="remember"
                            type="checkbox"
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                            required={false}
                            onChange={onRememberMeChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="remember"
                            className="text-gray-500 dark:text-gray-300"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <a
                        href="/forget-password"
                        className="text-sm font-medium text-themedark underline hover:underline dark:text-primary-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                    <button
                      ref={loginButtonRef}
                      type="submit"
                      className="flex mx-auto w-2/3 shadow-md px-5 py-4 text-white bg-sky-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      <div className="text-center w-full font-medium text-md">
                        LOGIN
                      </div>
                      {/* LOG IN */}
                    </button>
                    <div className="flex items-center mb-2">
                      <hr className="flex-grow border-gray-300 dark:border-gray-500" />
                      <span className="text-gray-500 dark:text-gray-200 mx-2">OR</span>
                      <hr className="flex-grow border-gray-300 dark:border-gray-500" />
                    </div>
                    <div className="flex justify-center mt-4">
                      {/* <GoogleLoginButton onClick={responseGoogle} className="mb-2">
                          Sign In with Google
                      </GoogleLoginButton> */}
                      <GoogleAuthButton
                        // onSuccess={(resp) => {
                        //   alert("RESP " + JSON.stringify(resp))
                        // }}
                        onSuccess={(resp) => {
                          submitGoogleLogin(resp.code);
                        }}
                       />
                    </div>
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet?{" "}
                      <Link
                        to="/auth/signup"
                        className="font-medium text-themedark underline hover:underline dark:text-primary-500"
                      >
                        Sign up
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
