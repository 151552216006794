import { CodeResponse, TokenResponse, useGoogleLogin } from "@react-oauth/google";
import GoogleLoginButton from "react-google-button";
import { defaultErrorToast } from "../common/toasts";

export interface IGoogleAuthProps {
    scope?: string;
    // onSuccess?: (response: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => void;
    onSuccess?: ((codeResponse: Omit<CodeResponse, "error" | "error_description" | "error_uri">) => void) | undefined;
    onError?: (errorResponse: Pick<TokenResponse, "error" | "error_description" | "error_uri">) => void;
}

export function GoogleAuthButton(props: IGoogleAuthProps) {
    // TODO: Modify url below to use env vars
    const defaultOnErrorCallback = (error: Pick<TokenResponse, "error" | "error_description" | "error_uri">) => {
        defaultErrorToast(error.error_description || 'Unknown error occured');
    }

    const loginGoogle = useGoogleLogin({
        // onSuccess: props.onSuccess,
        onSuccess: props.onSuccess,
        flow: 'auth-code',
        // scope: props.scope,
        onError: props.onError || defaultOnErrorCallback,
    })
    return (
        <GoogleLoginButton onClick={() => loginGoogle()} label="Login with Google" className="">
            
        </GoogleLoginButton>
        // <GoogleLogin
        //     onSuccess={(response) => {alert("RESP " + JSON.stringify(response))}}
        //     />

        // <button type="button" onClick={() => loginGoogle}  className="bg-red-500 text-white px-8 py-2 rounded-lg">
        //     AAa google
        // </button>

        // <Link
        //     to="http://localhost:5000/auth/google"
        //     className="rounded-md overflow-hidden font-medium text-primary-600 hover:underline dark:text-primary-500"
        //     >
        //     <GoogleLoginButton label="Login with Google" className="">
                
        //     </GoogleLoginButton>
        // </Link>
    )

}