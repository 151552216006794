
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import './style.css';

export interface ICustomInitialsAvatarProps {
    className?: string;
    name: string;
}

export function CustomInitialsAvatar(props: ICustomInitialsAvatarProps) {
    return <InitialsAvatar name={`${props.name}`} className={`${props.className || ''} initials-avatar`} />
}


