import { Link } from "react-router-dom";
import { CustomInitialsAvatar } from "../../components/common/initialsAvatar/InitialsAvatar";
import { IMentorSkill } from ".";

export interface IMentorCellProps {
    id: string;
    imageUrl?: string;
    name: string;
    position: string;
    company: string;
    country?: string;
    city?: string;
    yearsExperience?: number;
    skills?: IMentorSkill[];
}

export function MentorCell(props: IMentorCellProps) {
    return (
        <div className="cell w-full xl:w-4/5 mx-auto my-2">
            <Link to={`/dashboard/mentors/${props.id}`}>
                <div className="dark:bg-gray-800 bg-gray-100 px-4 py-6 border-gray-200 rounded-lg shadow-md transition duration-1500 ease-in-out hover:shadow-lg hover:cursor-pointer dark:shadow-gray-700">
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-y-5">
                        <div className="col-span-1 space-x-2 flex my-auto">
                            <div className="inline-block mx-1">
                                { !!props.imageUrl && <img className="h-full w-14 lg:w-16 md:w-16 object-cover rounded-full border-0 border-gray-200" src={props.imageUrl || ""} alt="Profile" /> }
                                { !props.imageUrl && 
                                        <CustomInitialsAvatar name={`${props.name}`} />
                                }
                            </div>
                            <div className="text-black align-middle my-auto inline-block">
                                <div>
                                    {props.name}
                                </div>
                                <div className="text-sm text-gray-400 font-light break-words">
                                    {props.position} @ {(props.company || 'Unknown').slice(0, 30)}
                                </div>
                            </div>
                        </div>

                        <div className="col-span-1 flex justify-center">
                            <div className="text-gray-400 my-auto text-center text-sm">
                                {props.yearsExperience || '-'} years
                            </div>
                        </div>
                        
                        <div className="mx-auto col-span-1 text-gray-400 my-auto text-center text-sm">
                            {/* {props.skills?.slice(0, 4).join(', ') || '-'} */}
                            <div className="space-y-2">
                                {
                                    props?.skills?.slice(0, 14)?.map((skill) => {
                                        return (
                                            <div className="inline-block ml-2">
                                                <div className="bg-orange-200 text-orange-600 px-2 py-1 w-fit rounded-lg">
                                                    {skill.skillName} - {skill.yearsExperience}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* <div className="grid grid-cols-2 col-span-1">
                    <div className="col-span-1 bg-green-100 mx-1">
                        { !!displayedImageUrl && <img className="h-full w-14 lg:w-16 md:w-16 object-cover rounded-full border-0 border-gray-200" src={displayedImageUrl || ""} alt="Profile" /> }
                        { !displayedImageUrl && 
                                <CustomInitialsAvatar name={`${displayedName}`} />
                        }
                    </div>
                    <div className="text-black align-middle my-auto bg-red-200 col-span-1">
                        aa
                    </div>
                </div> */}
            </Link>
        </div>
    )
}