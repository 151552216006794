import DescriptionGrid from './DescriptionGrid';
import MenteeReviews from './menteeReview';
import Faqs from './Faqs';
import { Footer } from '../../components/common/footer';
import LandingHeader from './Header';

export default function LandingPage() {
    return (
        <div className='dark:bg-gray-900 bg-gray-100 min-h-screen'>
            <div className='absolute py-4 px-4 lg:py-10 lg:px-10 z-50 w-full'>
                <LandingHeader selectedSection='home' />
            </div>
            <div className='flex-row toplanding pb-10'>
                <div>
                    <div className="w-full h-full flex mx-auto relative">
                        <img
                            className=" w-full h-full justify-center"
                            src="LandingTopImage.png"
                            alt="landing top"
                        />
                        <div className='bg-black opacity-70 w-full h-full absolute'></div>
                        
                        <div className='absolute top-1/2 left-1/4 transform -translate-y-1/2 mx-auto'>
                            <h1 className='text-white text-5xl md:text-7xl lg:text-9xl font-extrabold opacity-10'>GRO<br/>WTH</h1>
                        </div>
                        
                        {/* <div className='absolute top-1/3 left-1/3 transform -translate-x-1/3 -translate-y-1/3 mx-auto'> */}
                        <div className='text-white w-1/2 absolute top-2/3 md:top-1/2 left-1/4 transform -translate-y-2/3 md:-translate-y-1/2 mx-auto'>
                            <p className='xl:text-xl lg:text-md md:text-sm text-xs'>Unlock Your Sucess With Expert Guidance.</p>
                            <p className='w-7/8 font-medium xl:text-7xl lg:text-6xl md:text-4xl text-2xl'>Empowering Mentoring for <span className='text-theme font-semibold'>Professional</span> Growth</p>
                            <p className='w-3/4 font-light text-xs md:text-md lg:text-lg mt-1 md:mt-4'>Welcome to our mentoring platform, where transformation begins. We believe that everyone has untapped potential waiting to be unleashed</p>
                            {/* <h1 className='text-white text-9xl font-extrabold'>Empowering Mentoring for Professional Growth</h1> */}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <DescriptionGrid />
            </div>

            <div className="py-20 lg:py-32 bg-auto bg-[url('../public/landing/CirclesMiddleBg.png')]">
                <div className='flex flex-row'>
                    {/* <img
                        className=" w-full h-full justify-center"
                        src="landing/CirclesMiddleBg.png"
                        alt=""
                    /> */}

                    <div className='text-black mx-auto w-3/4'>
                        <div className='grid grid-cols-1 md:grid-cols-3 w-full'>
                            <div className='col-span-1 shadow-md bg-gray-50 rounded-md shadow-gray-200 mx-auto px-6 lg:px-14 py-6 space-y-2'>
                                <p className='font-bold text-themedark uppercase text-2xl'>Our mission</p>
                                <p className='font-light text-themedark text-sm'>To empower individuals to achieve their full potential and excel in their professional lives. We are committed to providing exceptional mentorship and guidance to help you navigate the challenges of your career journey.</p>
                            </div>
                            
                            <div className='col-span-1 md:col-span-2 mx-auto px-6 lg:px-14 py-6 space-y-2'>
                                <p className='text-center font-bold text-themedark uppercase text-2xl'>We’re Ready To Grow Your Profession With Us</p>
                                <p className='font-light text-themedark text-sm'>Discover the endless possibilities of professional growth with us. Our mentoring programs are designed to empower you with the knowledge, skills, and guidance needed to thrive in your chosen field. Whether you're just starting your career or looking to advance to the next level, our experienced mentors are here to support and inspire you every step of the way.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-black'>
                <div className="absolute overflow-hidden right-0">
                    <img
                        className="w-64 h-32 justify-center transform translate-x-1/2"
                        src="landing/quarterCircleIcon.png"
                        alt=""
                    />
                </div>
                <div>
                    <MenteeReviews />
                </div>
                
                <div className="grid grid-cols-2 px-4 md:px-16 py-20 space-x-4">
                    <div className="flex col-span-1 my-auto">
                        <img 
                            src='landing/BrokenWomanMan.png'
                            className='max-h-[32rem] aspect-square'
                            alt=''
                        />
                    </div>
                    <div className="col-span-1 my-auto">
                        <p className='font-bold text-4xl text-themedark lg:w-3/4'>Take the first step towards <span className='text-3xl text-orange-600 font-light'>Personal Growth</span></p>
                        <p className='mt-2 text-md font-light'>We believe in the power of mentoring to unlock your full potential. Our platform connects you with experienced mentors who are passionate about guiding and empowering individuals like you.</p>
                        <button onClick={() => window.location.href = '/dashboard'} className='mt-4 border-2 border-theme text-theme py-2 px-8 text-sm rounded-md font-medium hover:bg-themedark hover:text-white'>Get Started</button>
                    </div>
                </div>

                <div>
                    <Faqs />
                </div>
            </div>

            <Footer />
        </div>
    );
}
