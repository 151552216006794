import { useState } from "react";
import customHttpClient from "../../../utils/httpClient/httpClient";
import { RequestStatus } from "../../../components/status/RequestStatusVisual";
import { defaultErrorToast } from "../../../components/common/toasts";
import LoadingScreen from "../../../components/common/loading/LoadingScreen";

export interface IRequestResponseFormProps {
    requestId: string;
    onSuccess: (status: RequestStatus) => void;
}

export function RequestResponseForm(props: IRequestResponseFormProps) {

    const [isLoading, setIsLoading] = useState(false);

    async function onSubmitButtonClicked(response: 'approvedByMentor' | 'rejectedByMentor') {

        setIsLoading(true);
        try {
            await customHttpClient.post(`/mentors/requests/${props.requestId}/respond`, undefined, {
                status: response, 
            });
            props.onSuccess(response);
        }
        catch (error: any) {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="pt-4 pb-8">
            {
                isLoading && (
                    <div className="z-50 [&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen isTransparent />
                    </div>
                )
            }
            <p className="w-full text-center text-gray-800 font-medium text-lg ml-2">
                Respond to request
            </p>

            <div className="p-4 md:p-5 text-center grid grid-cols-2 w-1/2 mx-auto">
                <button 
                    onClick={() => onSubmitButtonClicked('approvedByMentor')} 
                    className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                        <div className="w-full text-center">
                            Approve
                        </div>
                </button>
                
                <button 
                    onClick={() => onSubmitButtonClicked('rejectedByMentor')} 
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                        <div className="w-full text-center">
                            Reject
                        </div>
                </button>

            </div>

        </div>
    )
}


