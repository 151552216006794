import { useRef, useState } from "react";
import SingleSelect from "../../../components/common/single_select_dropdown";
import { defaultErrorToast } from "../../../components/common/toasts";
import LoadingScreen from "../../../components/common/loading/LoadingScreen";
import customHttpClient from "../../../utils/httpClient/httpClient";

interface IReviewSessionFormProps {
    requestId: string;
    onSubmit: (rating: number, details?: string | null) => void;
}

export function ReviewSessionForm(props: IReviewSessionFormProps) {

    const [currentRating, setCurrentRating] = useState(null as number | null);
    // const [details, setDetails] = useState(null as string | null);
    const [isLoading, setIsLoading] = useState(false);
    const aboutEditableRef = useRef<HTMLDivElement>(null);

    const onSubmitButtonClicked = async () => {
        if (!currentRating || currentRating < 1 || currentRating > 5) {
            defaultErrorToast('You must select a rating');
            return;
        }

        const data = {
            rating: currentRating,
            details: aboutEditableRef.current?.textContent,
        };

        setIsLoading(true);
        try {
            await customHttpClient.post(`/mentors/requests/${props.requestId}/review`, undefined, data);
        }
        catch (error: any) {
            defaultErrorToast(error?.data?.message || 'Unknown error occured');
        }
        finally {
            setIsLoading(false);
        }
        props.onSubmit(currentRating, aboutEditableRef.current?.textContent);
    }

    return (
        <div className="pt-4 pb-8">
            {
                isLoading && (
                    <div className="z-50 [&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen isTransparent />
                    </div>
                )
            }
            <p className="w-full text-center text-gray-800 font-medium text-lg ml-2">
                Rate the session
            </p>
            <div className="mt-6">
                <SingleSelect 
                    options={Array.from(Array(5)).map((it, ind) => {
                        return {
                            id: `${ind + 1}`,
                            value: `${ind + 1} star${ind == 0 ? '' : 's'}`,
                        }
                    })}
                    onChange={(item) => {setCurrentRating(Number(item))}}
                    placeholder="Select Rating"
                    initialValue={`${currentRating || 'Select Rating'}`}
                    classNames={'w-fit px-16 py-2 text-black'}
                />
            </div>
            <div className="mt-6">
                <p className="text-gray-800">
                    Details
                </p>
                <div 
                    ref={aboutEditableRef} 
                    contentEditable={true} 
                    className={`inline-block w-full max-h-64 overflow-y-scroll h-max break-words input-box text-black text-sm mt-2 rounded-lg p-4 flex-grow bg-white`} />
            </div>
            
            <div className="mt-8 flex justify-center">
                <button onClick={onSubmitButtonClicked} className="bg-theme px-8 py-2 rounded-lg text-white">
                    Submit
                </button>
            </div>
        </div>
    )
}

