import SignupCard from "../../../components/auth/signup_card";

export default function SignupPage() {
    return (
        <div>
            <SignupCard />
        </div>
    )
}


