import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { FaChalkboardTeacher, FaHome, FaPaperPlane, FaSignOutAlt, FaUser, FaWallet } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../stores";
import { logoutUser } from "../../../stores/reducers/authSlice";

export type MenuBarItemId = 'home' | 'profile' | 'requests' | 'wallet' | 'mentors' | 'mentors-me' | 'notifications' | 'reviews';

interface IMenuBarItem {
    icon: (props: any) => JSX.Element;
    label?: string;
    href: string;
    id: MenuBarItemId;
}

const menuItems: IMenuBarItem[] = [
    {
        // icon: SvgRandomSquaresIcon,
        icon: FaHome,
        href: '/dashboard',
        id: 'home',
    },
    {
        icon: FaPaperPlane,
        href: '/dashboard/requests',
        id: 'requests',
    },
    {
        // icon: SvgMedalIcon,
        icon: FaWallet,
        href: '/dashboard/wallet',
        id: 'wallet',
    },
    {
        icon: FaChalkboardTeacher,
        href: '/dashboard/mentors',
        id: 'mentors',
    },
    {
        icon: FaUser,
        href: '/dashboard/mentors/me',
        id: 'mentors-me',
    },
    // {
    //     icon: SvgReviewsIcon,
    //     href: '/dashboard/reviews',
    //     id: 'reviews',
    // },
];

export interface IUserMenuBarProps {
    selectedId: MenuBarItemId;
}

export default function UserMenuBar(props: IUserMenuBarProps) {
    const [currentLocation, setCurrentLocation] = useState(window.location.pathname);
    const [isLogoutAlertOpen, setIsLogoutAlertOpen] = useState(null as boolean | null);

    const dispatch = useDispatch<AppDispatch>();

    function onIconClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, href: string) {
        e.stopPropagation();

        // alert(`${href} --- ${currentLocation} --- ${href == currentLocation}`)
        if (href === currentLocation) {
            return;
        }
        setCurrentLocation(href);
    }

    function onLogoutClicked() {
        setIsLogoutAlertOpen(false);
        dispatch(logoutUser());
    }

    if (currentLocation !== window.location.pathname) {
        return (
            <Navigate to={currentLocation} />
        )
    }

    return (
        <div className="flex-grow bg-sky-100 min-h-screen py-10 left-0 top-0 w-16">
            <div className="text-white flex bg-theme py-5 justify-center">
                Ment
            </div>
            <div className="mt-12 space-y-12">
                {
                    menuItems.map((it, index) => {
                        const Icon = it.icon;
                        return (
                            <div onClick={(e) => onIconClick(e, it.href)} className="cursor-pointer z-50 mx-auto justify-center items-center flex">
                                {/* <Icon className={`overflow-visible ${(props.selectedId === it.id) ? 'fill-orange-600' : 'fill-transparent stroke-gray-400'}`} /> */}
                                <Icon className={`w-6 h-6 overflow-visible ${(props.selectedId === it.id) ? 'fill-orange-600' : 'fill-gray-400 stroke-gray-400'}`} />
                                {/* <img 
                                    src={it.icon}
                                    alt="icon"
                                    className='text-orange-600'
                                    // className={`${(props.selectedId == it.id ? 'fill-orange-600' : '')}`}
                                /> */}
                            </div>
                        )
                    })
                }
            </div>

            <div className="flex mt-16">
                <button onClick={() => setIsLogoutAlertOpen(true)} className="mx-auto">
                    <FaSignOutAlt className="w-6 h-6 fill-red-600" />
                </button>
            </div>

            <div onTransitionEnd={(e) => e.currentTarget.classList.contains('opacity-0') ? e.currentTarget.classList.add('scale-0') : null} id="popup-modal" tabIndex={-1} className={`${isLogoutAlertOpen === false ? 'opacity-0' : 'opacity-100'} ${isLogoutAlertOpen === null ? 'scale-0' : ''} transition-opacity bg-black bg-opacity-50 duration-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 min-h-screen h-[calc(100%-1rem)] max-h-full`}>
                <div className={`relative p-4 w-full max-w-md max-h-full mx-auto duration-200 ${isLogoutAlertOpen === true ? 'scale-100' : 'scale-0'}`}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={() => setIsLogoutAlertOpen(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to logout?</h3>
                            <button onClick={() => onLogoutClicked()} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                                Logout
                            </button>
                            <button onClick={() => setIsLogoutAlertOpen(false)} data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="popup-modal" tabIndex={-1} className={`${!isLogoutAlertOpen ? 'scale-0' : ''} bg-black bg-opacity-50 duration-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 min-h-screen h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={() => setIsLogoutAlertOpen(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to logout?</h3>
                            <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                                Logout
                            </button>
                            <button onClick={() => setIsLogoutAlertOpen(false)} data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

