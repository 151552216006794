
export function RatingStars({ numStars, rating }: { numStars: number, rating: number }) {
    /*
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 fill-current" viewBox="0 0 20 20">
        <path d="M10 0l3.09 6.31 6.91.99-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.99z"/>
    </svg>
    */

    // const FullStar = (
    //     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-500 fill-current mr-0.5" viewBox="0 0 20 20">
    //         <path d="M10 0l3.09 6.31 6.91.99-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.99z"/>
    //     </svg>
    // );

    // const EmptyStar = (
    //     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 fill-current" viewBox="0 0 20 20">
    //         <path d="M10 0l3.09 6.31 6.91.99-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.99z"/>
    //     </svg>
    // );

    const CustomFilledStar = (percentFilled: number, key: string, addMarginRight: boolean = false) => {
        if (!addMarginRight) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" key={key} className="h-5 w-5 text-yellow-500 fill-current" viewBox="0 0 20 20">
                    <defs>
                        <linearGradient id="half-yellow-half-gray" x1="0" y1="0" x2="1" y2="0">
                            <stop offset={`${Math.round(percentFilled)}%`} stopColor="#FBBF24" />
                            <stop offset={`${Math.round(percentFilled)}%`} stopColor="#A1A5AB" />
                        </linearGradient>
                    </defs>
                    <path d="M10 0l3.09 6.31 6.91.99-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.99" style={{ fill: 'url(#half-yellow-half-gray)' }} />
                </svg>
            )
        }
        return (
            <svg xmlns="http://www.w3.org/2000/svg" key={key} className="h-5 w-5 text-yellow-500 fill-current mr-0.5" viewBox="0 0 20 20">
                <defs>
                    <linearGradient id="half-yellow-half-gray" x1="0" y1="0" x2="1" y2="0">
                        <stop offset={`${Math.round(percentFilled)}%`} stopColor="#FBBF24" />
                        <stop offset={`${Math.round(percentFilled)}%`} stopColor="#A1A5AB" />
                    </linearGradient>
                </defs>
                <path d="M10 0l3.09 6.31 6.91.99-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.99" style={{ fill: 'url(#half-yellow-half-gray)' }} />
            </svg>
        )
    };

    // const numFullStars = Math.floor(rating);
    // const numEmptyStars = numStars - numFullStars;

    return (
        <div className="rating-stars-container flex items-center">
            {[...Array(numStars).keys()].map((val, ind) => {
                let percentFilled = 0;
                if ((ind + 1) <= rating) percentFilled = 100;
                else if ((ind + 1) > rating && ind < rating) {
                    percentFilled = (rating % 1) * 100;
                }
                else {
                    // return EmptyStar;
                    percentFilled = 0;
                }
                return CustomFilledStar(percentFilled, `${ind}`, (ind + 1) < numStars);
            })}
        </div>
    )
}


