import { useEffect, useState } from "react";
import LoadingScreen from "../../components/common/loading/LoadingScreen";
import UserMenuBar from "../../components/common/userMenuBar";
import { useNavigate, useParams } from "react-router-dom";
import { RatingStars } from "../../components/common/ratingStars";
import { WorkTimingCell } from "./workTimingCell";
import { OverlayVariableHeight } from "../../components/common/overlays/overlayVariableHeight";
import { IMentorProfile } from "./types";
import { ScheduleWithMentorForm } from "./scheduleRequest/form";
import customHttpClient from "../../utils/httpClient/httpClient";
import { defaultErrorToast } from "../../components/common/toasts";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";

export type MentorState = 'applied' | 'rejected' | 'approved' | 'na';

export function OtherMentorDetailsPage() {
    const navigator = useNavigate();
    const [mentorInfo, setMentorInfo] = useState(null as IMentorProfile | null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSchedulePopupOpen, setIsSchedulePopupOpen] = useState(false);
    const {loading: authLoading} = useSelector((state: RootState) => state.auth);
    const params = useParams();
    
    // const [selectedDate, setSelectedDate] = useState(null as Moment | null);
    // const [selectedTime, setSelectedTime] = useState(null as string | null);
    
    // const [isTimepickerOpen, setIsTimepickerOpen] = useState(false);
    // const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);

    useEffect(() => {
        // alert("PARAMS " + JSON.stringify(params, null, 2));
        
        setIsLoading(true);

        customHttpClient.get<{approvalStatus: string; mentorInfo: IMentorProfile}>(`/mentors/${params.mentorId}/profile`)
        .then(result => {
            console.log("RESULTT ", result.result)
            setMentorInfo(result.result.mentorInfo);
        }).catch(error => {
            defaultErrorToast(error?.data?.message);
            navigator(-1);
        }).finally(() => {
            setIsLoading(false);
        });

        // setTimeout(() => { // TODO: Make API Request
        //     // setCanApply(true);
        //     // setMentorInfo({
        //     //     id: 'ancnc',
        //     //     name: 'avmf',
        //     //     avgRating: 3.5,
        //     // })
        //     setIsLoading(false);
        //     setMentorInfo({
        //         "id": "6fcef2c8-8584-4d30-a8ae-6f7922d45e78",
        //         "userId": "cb1fa7f6-3979-4637-91bc-b880cdaa8a70",
        //         "hourlyPrice": 40,
        //         "workTimings": [
        //             {
        //                 "day": 0,
        //                 "fromTime": moment.utc("1970-01-01T12:00:00.000Z").toDate(),
        //                 "toTime": moment.utc("1970-01-01T17:00:00.000Z").toDate()
        //             },
        //             {
        //                 "day": 1,
        //                 "fromTime": moment.utc("1970-01-01T14:00:00.000Z").toDate(),
        //                 "toTime": moment.utc("1970-01-01T19:00:00.000Z").toDate()
        //             },
        //             {
        //                 "day": 2,
        //                 "fromTime": moment.utc("1970-01-01T09:00:00.000Z").toDate(),
        //                 "toTime": moment.utc("1970-01-01T11:00:00.000Z").toDate()
        //             },
        //             {
        //                 "day": 3,
        //                 "fromTime": moment.utc("1970-01-01T18:00:00.000Z").toDate(),
        //                 "toTime": moment.utc("1970-01-01T00:00:00.000Z").toDate()
        //             }
        //         ],
        //         "user": {
        //             "id": "cb1fa7f6-3979-4637-91bc-b880cdaa8a70",
        //             "firstName": "Mo",
        //             "lastName": "Testing",
        //             "fullname": "Mo Testing",
        //             "birthDate": null,
        //             "profileImageUrl": null,
        //             "languages": [],
        //             "city": null,
        //             "country": null,
        //             "displayAge": "-"
        //         },
        //         "attachments": [],
        //         "experiences": [],
        //         "yearsExperience": 5,
        //         "title": "Software Engineer",
        //         "currentCompany": "Micro",
        //         "settings": null,
        //         "skills": [
        //             {
        //                 "skillName": "Node.js",
        //                 "yearsExperience": 2,
        //                 "mentorId": "6fcef2c8-8584-4d30-a8ae-6f7922d45e78"
        //             },
        //             {
        //                 "skillName": "C#",
        //                 "yearsExperience": 3,
        //                 "mentorId": "6fcef2c8-8584-4d30-a8ae-6f7922d45e78"
        //             }
        //         ],
        //         "isAvailable": true,
        //         "pitch": "Ex Microsoft Engineer",
        //         "createdAt": moment.utc("2023-07-01T15:07:08.238Z").toDate(),
        //         "updatedAt": moment.utc("2023-07-01T15:07:08.238Z").toDate(),
        //         "avgRating": 0
        //     });
        //     // setMentorState({
        //     //     status: 'na',
        //     //     reason: 'Not sure why'
        //     // });
        // }, 1200);
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <OverlayVariableHeight onCrossIconClicked={() => setIsSchedulePopupOpen(false)} onBackgroundClicked={() => setIsSchedulePopupOpen(false)} bgClassNames="bg-sky-100" show={isSchedulePopupOpen}>
                {
                    mentorInfo && 
                    <ScheduleWithMentorForm
                        mentorInfo={mentorInfo as any}
                        onSuccess={() => setIsSchedulePopupOpen(false)}
                    />
                }
            </OverlayVariableHeight>
            {
                (isLoading || authLoading) && (
                    <div className="[&_.loading-screen-bg]:bg-black [&_.loading-screen-bg]:backdrop:blur-lg [&_.loading-screen-bg]:opacity-50">
                        <LoadingScreen />
                    </div>
                )
            }

            {
                <div className="flex bg-gray-100 min-h-screen">
                    <div className='flex flex-col'>
                        <UserMenuBar selectedId='mentors' />
                    </div>
                    <div className='text-black flex flex-col bg-gray-50 w-full min-h-screen px-10'>
                        <div className='mt-10'>
                            <button onClick={() => navigator(-1)} className="inline-block bg-gray-300 text-theme px-4 py-2 rounded-md">
                                &larr; Back
                            </button>
                            <div className="flex justify-center mx-auto flex-grow my-auto text-center font-poppins text-2xl font-medium">
                                Mentor Details
                            </div>
                        </div>

                        { /* ----------- CONTENT DIV ------------ */ }
                        <div className="flex flex-col bg-sky-100 h-full my-6 rounded-xl py-8 px-8">
                            {/* {mentorInfo?.user.firstName} - {mentorInfo?.pitch} */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3">
                                <div className='col-span-1 justify-start left-0 float-left my-auto'>
                                    <div className='flex'>
                                        <img
                                            className='bg-gray-100 shadow-md h-24 aspect-square my-auto rounded-lg'
                                            src={mentorInfo?.user.profileImageUrl || '/noun-profile.svg'}
                                            alt=""
                                        />
                                        <div className='h-full my-auto ml-4 text-black space-y-1'>
                                            <p className='font-bold text-lg font-poppins'>{mentorInfo?.user.firstName} {mentorInfo?.user.lastName}</p>
                                            <p className='font-medium text-sm text-gray-400 font-poppins'>{mentorInfo?.title} @ {mentorInfo?.currentCompany || 'Unknown'}</p>
                                            {/* <p className='text-gray-400 text-sm'>{userInfo.email}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center md:justify-end my-auto">
                                    <div>
                                        <div className="flex justify-center mb-4 mt-4">
                                            <button onClick={() => setIsSchedulePopupOpen(true)} className="bg-theme text-white px-8 py-2 rounded-lg shadow-lg">
                                                Schedule
                                            </button>
                                        </div>
                                        <div className="[&_.rating-stars-container]:justify-center mr-10 mx-auto w-full">
                                            <RatingStars numStars={5} rating={mentorInfo?.avgRating || 0} />
                                        </div>
                                        <div className="mx-auto mt-2 text-center text-gray-400">
                                            {
                                                mentorInfo?.avgRating || '-'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 grid gap-y-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-6">
                                <div className="h-fit my-auto bg-orange-200 text-orange-600 px-6 py-2 w-fit rounded-lg">
                                    {mentorInfo?.yearsExperience} years
                                </div>
                                
                                <div className="h-fit my-auto bg-orange-200 text-orange-600 px-6 py-2 w-fit rounded-lg">
                                    ${mentorInfo?.hourlyPrice} / hour
                                </div>

                                <div className="col-span-2 space-x-2 space-y-2 overflow-hidden">
                                    {
                                        mentorInfo?.skills?.slice(0, 10)?.map((skill) => {
                                            return (
                                                <div className="inline-block">
                                                    <div className="bg-orange-200 text-orange-600 px-2 py-1 w-fit rounded-lg">
                                                        {skill.skillName} - {skill.yearsExperience}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="mt-6 space-y-2">
                                {mentorInfo?.workTimings.map((it) => {
                                    return (
                                        <WorkTimingCell day={it.day} fromTime={it.fromTime} toTime={it.toTime} />
                                        // <div>
                                        //     {moment().day(it.day).format('dddd')} - {moment.utc(it.fromTime).format('HH:mm z')} - {moment.utc(it.toTime).format('HH:mm z')}
                                        // </div>
                                    )
                                })}
                            </div>
                            
                            <div className={`flex h-max break-words input-box text-sm mt-4 rounded-lg p-4 flex-grow bg-sky-50`}>
                                {mentorInfo?.pitch}
                            </div>
                            {/* <div className="mt-6">
                                aa
                            </div> */}

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


