import * as React from "react";
const SvgProgressTrackingIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57.195}
    height={57.195}
    viewBox="0 0 57.195 57.195"
    {...props}
  >
    <g>
      <path
        d="M39.083 38.129a4.766 4.766 0 0 0-3.813-7.626H30.5v-7.626a5.72 5.72 0 1 0-11.439 0v7.626h-7.622v-1.906a1.906 1.906 0 0 0-1.906-1.906H1.906A1.906 1.906 0 0 0 0 28.597v26.691a1.906 1.906 0 0 0 1.906 1.906h7.626a1.906 1.906 0 0 0 1.906-1.906v-1.907h1.125l3.241 3.26a1.907 1.907 0 0 0 1.354.553H35.27a4.766 4.766 0 0 0 3.813-7.626 4.766 4.766 0 0 0 0-5.719 4.766 4.766 0 0 0 0-5.719ZM7.626 53.381H3.813V30.503h3.813ZM35.27 40.036a.953.953 0 1 1 0 1.906 1.907 1.907 0 0 0 0 3.813.953.953 0 1 1 0 1.906 1.907 1.907 0 0 0 0 3.813.953.953 0 1 1 0 1.906H17.94l-3.24-3.259a1.906 1.906 0 0 0-1.354-.553h-1.907V34.316h9.532a1.906 1.906 0 0 0 1.906-1.906v-9.533a1.907 1.907 0 1 1 3.813 0v9.532a1.906 1.906 0 0 0 1.91 1.907h6.67a.953.953 0 0 1 0 1.906 1.907 1.907 0 0 0 0 3.813Z"
        data-name="Path 34"
      />
      <path
        d="M43.849 0a13.345 13.345 0 1 0 13.346 13.345A13.345 13.345 0 0 0 43.849 0Zm5.167 16.93a7.321 7.321 0 0 1-10.333 0 1.914 1.914 0 1 1 2.707-2.707 3.451 3.451 0 0 0 4.919 0 1.914 1.914 0 1 1 2.707 2.707Z"
        data-name="Path 35"
      />
    </g>
  </svg>
);
export default SvgProgressTrackingIcon;

