import * as React from "react";
const SvgLearningResourcesIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57.882}
    height={57.882}
    viewBox="0 0 57.882 57.882"
    {...props}
  >
    <g>
      <path
        d="M57.323 12.136 45.746.56a1.929 1.929 0 0 0-1.37-.56H21.223a1.929 1.929 0 0 0-1.929 1.929V6.29a1.93 1.93 0 1 0 3.859 0V3.859h19.294v9.647a1.929 1.929 0 0 0 1.929 1.929h9.647v38.588h-30.87v-2.431a1.93 1.93 0 0 0-3.859 0v4.36a1.929 1.929 0 0 0 1.929 1.929h34.73a1.929 1.929 0 0 0 1.929-1.929V13.506a1.929 1.929 0 0 0-.56-1.37ZM46.306 6.579l5 5h-5Z"
        data-name="Path 30"
      />
      <path
        d="M39.553 50.165a1.93 1.93 0 1 0 0-3.859h-.965a1.93 1.93 0 0 0 0 3.859Z"
        data-name="Path 31"
      />
      <path
        d="M47.27 50.165a1.93 1.93 0 1 0 0-3.859h-.965a1.93 1.93 0 0 0 0 3.859Z"
        data-name="Path 32"
      />
      <path
        d="M17.365 11.827a17.365 17.365 0 1 0 17.364 17.365 17.365 17.365 0 0 0-17.364-17.365Zm-2.894 9.4a1.93 1.93 0 0 1 3.859 0v.193a1.93 1.93 0 0 1-3.859 0Zm4.823 17.94h-3.859a1.93 1.93 0 0 1 0-3.859V29.52a1.93 1.93 0 0 1 0-3.859h1.929a1.929 1.929 0 0 1 1.929 1.929v7.718a1.93 1.93 0 0 1 0 3.859Z"
        data-name="Path 33"
      />
    </g>
  </svg>
);
export default SvgLearningResourcesIcon;

